import throttle from 'lodash/throttle';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { Button, CheckBoxElement } from '../../../../../common';
import { useMemberInfo } from '../../../../../server';
import { ManagerDropDown } from './ManagerDropDown';
import { getClassesId } from '../../../../base/classes';
const SettingStyled = styled.div `
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	flex: 1;

	.dropdown-list {
		top: 50px !important;
	}

	.test-setting {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h5-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 24px);
		font-weight: var(--h5-font-weight, 700);
		position: relative;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.setting-checkbox {
		flex-direction: column;
		gap: 12px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
	}

	.divider {
		background: var(--color-silver, #e6e8ed);
		flex-shrink: 0;
		height: 1px;
		position: relative;
		width: 100%;
	}

	.setting-container {
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.live-add {
		display: flex;
		flex-direction: column;
		gap: 13px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		width: 100%;
		position: relative;
	}

	.name {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	gap: 12px;
	padding-bottom: 24px;

	.setting-container {
		width: 100%;

		.mobile-xs {
			p {
				line-height: var(--b-l-small-line-height, 16px);
			}
		}
	}
`;
const desktopStyles = css `
	gap: 12px;

	.setting-container {
		border-radius: 10px;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 1px;
		padding: 24px;
	}
`;
export const SettingTable = React.memo(({ initLiveCheck, defaultLiveMember, initLiveMember, setInitLiveCheck, setLiveMember, }) => {
    const { t } = useTranslation();
    const classesId = getClassesId();
    const { isMobile, isTablet } = useGlobalState();
    const [isLiveCheck, setIsLiveCheck] = React.useState(false);
    const [list, setList] = React.useState([]);
    const [dropdownList, setDropdownList] = React.useState([]);
    const [noMember, setNoMember] = React.useState(false);
    const { data, refetch } = useMemberInfo({ class_id: classesId, level: ['02'] });
    const refetchList = React.useCallback(throttle(refetch, 1000, { leading: true, trailing: false }), [refetch]);
    useEffect(() => {
        if (initLiveCheck) {
            setIsLiveCheck(initLiveCheck);
        }
    }, [initLiveCheck]);
    useEffect(() => {
        if (initLiveMember && initLiveMember.length > 0) {
            setList(initLiveMember.map(member => ({
                index: member,
                manager_id: member,
            })));
        }
    }, [initLiveMember]);
    useEffect(() => {
        refetchList();
    }, []);
    useEffect(() => {
        let drop_list = [{ id: 'select', title: 'common.select' }];
        if (data) {
            const { total_count, items } = data;
            if (total_count > 0) {
                drop_list = drop_list.concat(items.map((item) => ({
                    id: item.id,
                    title: item.nickname,
                })));
            }
        }
        setDropdownList(drop_list);
    }, [data]);
    useEffect(() => {
        if (initLiveMember.length === dropdownList.length - 1) {
            setNoMember(true);
        }
        else {
            setNoMember(false);
        }
    }, [dropdownList, initLiveMember]);
    useEffect(() => {
        if (defaultLiveMember && defaultLiveMember.length > 0) {
            setIsLiveCheck(true);
            setList(defaultLiveMember.map(member => ({
                index: member.id,
                manager_id: member.id,
            })));
        }
    }, [defaultLiveMember]);
    useEffect(() => {
        if (initLiveMember.length > 0)
            return;
        if (isLiveCheck && (!defaultLiveMember || defaultLiveMember.length === 0)) {
            setList([{ index: moment().unix().toString(), manager_id: 'select' }]);
        }
    }, [isLiveCheck, initLiveMember]);
    useEffect(() => {
        if (list.length <= 0) {
            setIsLiveCheck(false);
        }
        if (list.some(i => i.manager_id === 'select'))
            return;
        if (list.length === initLiveMember.length && list[0]?.manager_id === initLiveMember[0])
            return;
        const updatedLiveMembers = list
            .map(item => {
            if (item.manager_id !== 'select')
                return item.manager_id;
            return null;
        })
            .filter(Boolean);
        if (updatedLiveMembers === initLiveMember)
            return;
        if (updatedLiveMembers.length === 0) {
            setInitLiveCheck(false);
        }
        else {
            setInitLiveCheck(true);
        }
        setLiveMember(updatedLiveMembers);
    }, [list, initLiveMember]);
    const handlerCheck = (id, checked) => {
        if (checked) {
            setIsLiveCheck(true);
            setInitLiveCheck(true);
        }
        else {
            setIsLiveCheck(false);
            setInitLiveCheck(false);
            setList([]);
            setLiveMember([]);
        }
    };
    return (React.createElement(SettingStyled, { "$isMobile": isMobile, "$isTablet": isTablet, className: "setting" },
        !isMobile && React.createElement("div", { className: "test-setting" }, t('conference.setting')),
        React.createElement("div", { className: "setting-container", style: { width: !isMobile ? '430px' : '100%' } },
            React.createElement("div", { className: "live-add" },
                React.createElement(CheckBoxElement, { id: "liveQAndA", className: "setting-checkbox", checked: isLiveCheck, title: "conference.liveQAndA", style: { flexDirection: 'row' }, handlerCheck: (id, checked) => handlerCheck(id, checked) }),
                isLiveCheck &&
                    list.map((item, index) => (React.createElement("div", { className: "field-global-001", key: item.index },
                        React.createElement(ManagerDropDown, { dropdownList: dropdownList, excludeList: list.map(i => i.manager_id), defaultValue: {
                                id: item.manager_id,
                                title: dropdownList.find(dropItem => dropItem.id === item.manager_id)?.title || 'common.select',
                            }, handlerUpdateManager: value => {
                                if (!noMember && list[index].manager_id !== value) {
                                    setList(prevList => {
                                        const newList = [...prevList];
                                        newList[index].manager_id = value;
                                        return newList;
                                    });
                                }
                            }, isDisable: noMember }),
                        React.createElement(Button, { className: `btn_white ${isMobile ? 'mobile-xs' : 'xmd'}`, text: "conference.delete", styles: {
                                buttonWrap: {
                                    maxWidth: isMobile ? 60 : 80,
                                    maxHeight: isMobile && 38,
                                    boxShadow: 'unset',
                                },
                            }, onClick: () => setList(prevList => prevList.filter(prevItem => prevItem.index !== item.index)) }),
                        index === list.length - 1 && !noMember && (React.createElement(Button, { key: index, className: `btn_white ${isMobile ? 'mobile-xs' : 'xmd'}`, text: "common.add", styles: {
                                buttonWrap: {
                                    maxWidth: isMobile ? 60 : 80,
                                    maxHeight: isMobile && 38,
                                },
                            }, onClick: () => {
                                if (list.some(i => i.manager_id === 'select'))
                                    return;
                                setList(prevList => [
                                    ...prevList,
                                    {
                                        index: moment().unix().toString(),
                                        manager_id: 'select',
                                    },
                                ]);
                            } })))))))));
});
