import React from 'react';
import { SearchBar } from '../../../../../common';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIsTeacher } from '../../../../base/account';
import { useGlobalState } from '../../../../../GlobalStateProvider';
const Container001Styled = styled.div `
	&.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		flex-wrap: wrap;

		${props => props.$isMobile
    ? `
				display: flex;
				flex-direction: column;
				gap: 10px;
				align-items: flex-start;
				justify-content: center;
				align-self: stretch;
				flex-shrink: 0;
				position: relative;`
    : `
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				align-self: stretch;
				flex-shrink: 0;
				position: relative;
				flex-wrap: wrap;
		`}

		.active-bar {
			display: flex;
			flex-direction: row;
			gap: 14px;
			align-items: center;
			justify-content: flex-end;
			flex-shrink: 0;
			position: relative;
			flex-wrap: wrap;
			flex: 1;
		}

		.class-reservation {
			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--h3-font-family, 'Noto Sans KR', sans-serif);

			font-weight: var(--h3-font-weight, 700);
			position: relative;
			display: flex;

			${props => props.$isMobile
    ? `font-size: var(--h3-font-size, 16px);
			line-height: 150%`
    : props.$isTablet
        ? `font-size: var(--h3-font-size, 18px);
			line-height: 150%`
        : `font-size: var(--h3-font-size, 24px);
			line-height: var(--h3-line-height, 36px);`}

			${props => props.$isTablet
    ? `
					flex: 1;
					align-items: flex-start;
			justify-content: flex-start;`
    : `
			align-items: center;
			justify-content: center;`}
		}
	}
`;
export const ReservationTitle = React.memo(({ isPrevious, handlerFilterList, previousSearchContent, }) => {
    const { t } = useTranslation();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    return (React.createElement(Container001Styled, { "$isMobile": isMobile, "$isTablet": isTablet, className: "header" },
        (!isTeacher || (isTeacher && isDesktop)) && (React.createElement("div", { className: "class-reservation" }, !isTeacher
            ? t('conference.nextReseration')
            : t(`conference.${isPrevious ? 'lastClass' : 'reservation'}`))),
        React.createElement("div", { className: "active-bar", style: { gap: 14 } },
            React.createElement(SearchBar, { className: `${!isMobile ? 'search-bar-qna' : 'search-bar-mobile'}`, filterData: "title", handlerFilterList: handlerFilterList, defaultSearchContent: previousSearchContent }))));
});
