import React from 'react';
import { RegistButton } from '../RegistButton';
import { AssignmentTable } from './component/DSTL001_List_Child';
export const DSTL001_List = React.memo(({ isTeacher, isTest, isBackOffice, }) => {
    const handlerButtonClick = () => {
        if (isBackOffice) {
            APP.eventManager.publish('CHANGE_MENU', {
                showDepth: 'regist',
                props: {},
            });
        }
        else {
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'regist',
                id: undefined,
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(AssignmentTable, { isTeacher: isTeacher, isTest: isTest, isBackOffice: isBackOffice }),
        isTeacher && React.createElement(RegistButton, { handlerButtonClick: handlerButtonClick })));
});
