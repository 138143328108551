import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../GlobalStateProvider';
import { Button } from '../../../common';
import { ReviewClassList } from '../../DSS/DSST001/DSST001_List_Child';
import { HomeAssignment } from '../../DST/DSTL001';
import { AssignmentTable } from '../../DST/DSTL001/component/DSTL001_List_Child';
import { Profile } from './HomeLayout_Child';
import { OpenTable } from './OpenTable';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenu } from '../../menu/action';
import { QnAListTable } from '../../DST/DSTS004/DTST004_List_Child';
import { getHiddenMenus } from '../classes';
const HomeLayoutStyled = styled.div `
	${props => (props.$isMobile ? mobileStyles : desktopStyles)}

	.fext-field {
		flex: 1;
		align-items: flex-start !important;
	}

	.assignment {
		.fext-field {
			height: 100%;
			justify-content: center;

			.assignment-table {
				height: 100%;
				.table {
					height: 100%;

					.table-item-wrap {
						height: 100%;
					}
				}
			}
		}
	}

	.date,
	.creation_time {
		color: var(--color-grey, #666666);
		text-align: right;
	}
`;
const mobileStyles = css `
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: var(--color-sliver, #f3f4f8);

	.line {
		background: var(--color-silver, #f3f4f8);
		flex-shrink: 0;
		width: 1px;
		position: relative;
	}
	.header-label {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.header-title {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 20px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 700;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.fext-field {
		height: 100%;
		overflow: hidden;
	}

	.main-container {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.wrap-002 {
		padding: 0 20px 24px 20px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		position: relative;

		.container-001 {
			display: flex;
			flex-direction: row;
			gap: 26px;
			align-items: flex-start;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			width: 100%;
		}

		.home-box {
			&.conferenceReview {
				min-height: 358px;
			}
			&:not(.conferenceReview) {
				min-height: 320px;
			}
			.task-label {
				min-height: 35px;
				justify-content: center;
			}
			.fext-field > * {
				width: 100%;
			}
			flex: 1;
			background: var(--color-white, #ffffff);
			border-radius: 16px;
			padding: 20px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			overflow: hidden;
			gap: 4px;
		}
	}

	.home {
		.box-wrap {
			background-color: transparent;
			.box-wrap-item {
				background: var(--color-silver, #f3f4f8);
			}
		}
	}

	.more_detail {
		color: var(--color-grey, #666666);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 16px;
		line-height: 32px;
		letter-spacing: -0.02em;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.overText {
			min-width: unset;
		}
	}
`;
const desktopStyles = css `
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: var(--color-sliver, #f3f4f8);

	.line {
		background: var(--color-silver, #f3f4f8);
		flex-shrink: 0;
		width: 1px;
		position: relative;
	}

	.header-label {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.header-title {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--h3-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--h3-font-size, 24px);
			line-height: var(--h3-line-height, 36px);
			font-weight: var(--h3-font-weight, 700);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.header-more {
			color: var(--color-grey, #666666);
			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 16px;
			line-height: 32px;
			font-weight: 400;
			text-decoration: underline;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.main-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.wrap-002 {
		padding: 10px 80px 70px 80px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		position: relative;

		.container-001 {
			display: flex;
			flex-direction: row;
			gap: 26px;
			align-items: flex-start;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			width: 100%;
		}

		.home-box {
			&.conferenceReview {
				min-height: 350px;
			}
			&:not(.conferenceReview) {
				min-height: 358px;
			}
			.task-label {
				min-height: 35px;
				justify-content: center;
			}
			.fext-field > * {
				width: 100%;
			}
			flex: 1;
			background: var(--color-white, #ffffff);
			border-radius: 16px;
			padding: 24px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			overflow: hidden;
		}
	}

	.home {
		.box-wrap {
			background-color: transparent;
			.box-wrap-item {
				background: var(--color-silver, #f3f4f8);
			}
		}
	}

	.more_detail {
		color: var(--color-grey, #666666);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 16px;
		line-height: 32px;
		letter-spacing: -0.02em;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.overText {
			min-width: 50px;
		}
	}
`;
export const HomeLayout = React.memo(({ isTeacher, setIsScrolled }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const hiddenMenus = useSelector((state) => getHiddenMenus(state));
    const dispatch = useDispatch();
    const commonItems = [
        {
            title: 'notice',
            width: undefined,
            option: {
                title: 'learningSupportRoom_menu',
                data: { menu: 'notice', detail: 'list' },
                menu: 'learningSupportRoom',
            },
            children: React.createElement(OpenTable, { isResource: false, isHome: true }),
        },
        {
            title: 'resource',
            width: undefined,
            option: {
                title: 'learningSupportRoom_menu',
                data: { menu: 'resource', detail: 'list' },
                menu: 'learningSupportRoom',
            },
            children: React.createElement(OpenTable, { isResource: true, isHome: true }),
        },
        {
            title: 'test',
            width: undefined,
            option: {
                title: isTeacher ? 'learningManagement_menu' : 'myRoom_menu',
                data: { menu: 'test', detail: 'list' },
                menu: isTeacher ? 'learningManagement' : 'myRoom',
            },
            children: React.createElement(HomeAssignment, { isTeacher: isTeacher, isTest: true }),
        },
        {
            title: 'assignment',
            width: undefined,
            option: {
                title: isTeacher ? 'learningManagement_menu' : 'myRoom_menu',
                data: { menu: 'assignment', detail: 'list' },
                menu: isTeacher ? 'learningManagement' : 'myRoom',
            },
            children: React.createElement(AssignmentTable, { isTeacher: isTeacher, isTest: false, isHome: true }),
        },
        {
            title: isTeacher ? 'qnaWaiting' : 'myQnA',
            width: 750,
            option: {
                title: 'assignment_menu',
                data: { menu: 'qna', detail: 'list' },
                menu: 'learningSupportRoom',
            },
            children: React.createElement(QnAListTable, { isTeacher: isTeacher, isHome: true }),
        },
        {
            title: 'conferenceReview',
            width: undefined,
            option: {
                title: 'reservation_menu',
                data: { menu: 'reply', detail: 'list' },
                menu: isTeacher ? 'liveRoom' : 'myRoom',
            },
            children: React.createElement(ReviewClassList, { isHome: true }),
        },
    ];
    const generateTableList = deviceType => {
        const indexes = {
            notice: 0,
            resource: 1,
            qna: 4,
            test: 2,
            assignment: 3,
            reply: 5,
        };
        // commonItems를 classesMenu에 있는 항목으로 필터링
        const filteredItems = Object.entries(indexes)
            .filter(([key]) => !hiddenMenus.includes(key))
            .reduce((acc, [key, index]) => {
            acc[index] = commonItems[index];
            return acc;
        }, {});
        // deviceType에 따라 테이블 리스트 생성
        switch (deviceType) {
            case 'desktop':
                return [
                    [filteredItems[indexes.notice], filteredItems[indexes.resource]].filter(Boolean),
                    [
                        filteredItems[indexes.test],
                        filteredItems[indexes.assignment],
                        filteredItems[indexes.qna],
                    ].filter(Boolean),
                    // isTeacher && [filteredItems[indexes.reply]].filter(Boolean),
                ].filter(Boolean);
            case 'tablet':
                return [
                    [filteredItems[indexes.notice], filteredItems[indexes.resource]].filter(Boolean),
                    [filteredItems[indexes.test]].filter(Boolean),
                    [filteredItems[indexes.assignment]].filter(Boolean),
                    [filteredItems[indexes.qna]].filter(Boolean),
                    // isTeacher && [filteredItems[indexes.reply]].filter(Boolean),
                ].filter(Boolean);
            default: // mobile
                return [
                    [filteredItems[indexes.notice]].filter(Boolean),
                    [filteredItems[indexes.resource]].filter(Boolean),
                    [filteredItems[indexes.test]].filter(Boolean),
                    [filteredItems[indexes.assignment]].filter(Boolean),
                    [filteredItems[indexes.qna]].filter(Boolean),
                    // isTeacher && [filteredItems[indexes.reply]].filter(Boolean),
                ].filter(Boolean);
        }
    };
    const table_list = useMemo(() => {
        const deviceType = isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile';
        return generateTableList(deviceType);
    }, [isDesktop, isTablet, hiddenMenus]);
    const contentRef = useRef(null);
    const onScroll = () => {
        if (!contentRef.current)
            return;
        const top = contentRef.current?.getBoundingClientRect().top;
        if (top < 0) {
            setIsScrolled(true);
        }
        else {
            setIsScrolled(false);
        }
    };
    return (React.createElement(HomeLayoutStyled, { "$isMobile": isMobile, onScroll: onScroll },
        React.createElement("div", { className: "main-container" },
            React.createElement(Profile, null)),
        React.createElement("div", { ref: contentRef, className: "wrap-002" }, table_list.map((items, index) => (React.createElement("div", { className: 'container-001', key: index }, items.map(item => (React.createElement(HomeBox, { key: item.title, title: item.title, style: { minWidth: item.width ? `${item.width}` : undefined }, onMore: () => {
                if (!item.option)
                    return;
                dispatch(updateMenu(item.option.menu, item.option.data, undefined));
            }, isReply: item.title === 'conferenceReview' ? true : false }, item.children)))))))));
});
const HomeBox = React.memo(({ title, style, onMore, children, isReply, }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: `home-box field-global-001 ${title}`, style: style },
        React.createElement("div", { className: "header-label" },
            React.createElement("div", { className: "header-title" }, t(`menu.${title}`)),
            !isReply && React.createElement(Button, { className: "more_detail", text: "\uB354\uBCF4\uAE30", onClick: onMore })),
        React.createElement("div", { className: "fext-field" }, children)));
});
