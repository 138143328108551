import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { SearchBar, Table } from '../../../common';
import { EmptyTable } from '../../../common/option';
import { useGlobalState, useHistoryStore } from '../../../GlobalStateProvider';
import { useNoticeList } from '../../../server/posts';
import { REFRESH_POSTS_LIST } from '../../DST/DSTSL001/constant';
import { DeleteResourceItem } from '../../DST/DSTSL001/DSTSL001_List_Child/DeleteResourceItem';
import { updateMenu } from '../../menu/action';
import { OpenItem } from './OpenItem';
const OpenTableStyled = styled.div `
	height: 100%;
	${props => props.$isTablet && !props.$isHome && `padding: 20px;`}
	.body-item {
		p {
			${props => props.$isHome &&
    `
				overflow: hidden;
				text-overflow: ellipsis;
				word-break: keep-all;
				overflow-wrap: break-word;
				white-space: nowrap;
				-webkit-line-clamp: 1;`}
			${props => props.$isDesktop
    ? `
						font-size: var(--b3-font-size, 16px);
						line-height: var(--b3-line-height, 32px);
						letter-spacing: var(--b3-letter-spacing, -0.02em);
						font-weight: var(--b3-font-weight, 400);		
				`
    : props.$isTablet
        ? `
						font-size: var(--b4-font-size, 14px);
						line-height: var(--b4-line-height, 28px);
						letter-spacing: var(--b4-letter-spacing, -0.02em);
						font-weight: var(--b4-font-weight, 400);`
        : `
						font-size: var(--b4-font-size, 12px);
						line-height: var(--b4-line-height, 24px);
						letter-spacing: var(--b4-letter-spacing, -0.02em);
						font-weight: var(--b4-font-weight, 400);`}
		}
	}

	${props => props.$isHome &&
    props.$isMobile &&
    `
			.mobile_item {
				flex-direction: row !important;
			    justify-content: space-between !important;
			}
			.body-item {
				overflow: hidden;
    			flex: unset !important;

				&.creation_time {
					min-width: 100px;
					p {
						text-align: right;
					}
				}
			}
		`}

	${props => props.$isHome &&
    `
				.body-item {
					&.creation_time {
						width: 100px!important;
						flex: unset !important;

						p {
							width: 100%;
							text-align: right;
						}
					}
				}
			`}

	.home-title {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		.must-read {
			color: var(--color-pink, #f46790);
			text-align: left;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-weight: var(--h5-font-weight, 700);

			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.name {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			position: relative;

			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.file-value {
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}

	.file {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
	}
	.item {
		.pink {
			color: var(--color-pink, #f46790);
			text-align: center;
			font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
		}

		.purple {
			color: var(--pur-d, #8268d9);
			text-align: center;
			font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
		}
	}

	.resource-content {
		width: 100%;
	}

	.notice-list-001 {
		width: 100%;
	}

	.home-list-003 {
		height: 100%;
	}

	${props => (props.$isDesktop ? desktopStyles : props.$isTablet ? tabletStyles : mobileStyles)}
`;
const mobileStyles = css `
	display: flex;
	width: 100%;
	flex-direction: column;
	/* height: 100%; */
	&.home {
		height: 100%;
	}

	.body-checkbox {
		padding-bottom: 4px;
	}

	.importance {
		min-width: fit-content;
		top: 9px;
		position: absolute;
		left: 30px;
		font-size: 12px !important;
		font-weight: 500 !important;
		font-family: 'Noto Sans KR' !important;
	}

	.notice-list-001 {
		display: flex;
		flex-direction: column;
		gap: ${props => (props.$isTeacher ? '12px' : '0px')};
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
		padding: 0 20px;
		overflow-x: hidden;

		.table-header {
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
				font-size: var(--b3-font-size, 16px);
				line-height: var(--b3-line-height, 32px);
				font-weight: var(--b3-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: center;
			align-self: stretch;
			flex: 1;
			position: relative;
			box-sizing: border-box;
		}

		.item {
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: ${props => (props.$isTeacher ? '1px 0px 0px 0px' : '0px 0px 1px 0px')};
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			// gap: 4px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			width: 100%;

			.file {
				text-align: left;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;
			}
		}
	}

	.home-list-003 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			&:nth-last-child(1) {
				border-width: 0px 0px 0px 0px;
			}
		}
	}

	.notice-info-wrap {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.file-value {
		P {
			color: var(--color-grey-w, #b6bac0);
		}
	}

	.date {
		color: var(--color-grey-l, #83868a);
	}

	.view-wrap {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		p {
			color: var(--color-grey-l, #83868a);
		}
	}

	.resource-content {
		width: 100%;
	}

	.mobile_item {
		border-top: 1px solid var(--color-grey-c, #e6e8ed);
	}

	.check-btn {
		padding: 12px 0;
	}

	.title {
		p {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 600;
			position: relative;
			flex: 1;
			display: inline-block;
			width: 100%;
			white-space: break-spaces;
		}
	}

	.header {
		padding: 20px 20px 0 20px;
	}
`;
const tabletStyles = css `
	display: flex;
	width: 100%;
	flex-direction: column;

	&.home {
		height: 100%;
	}

	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: ${props => (props.$isTeacher ? 'space-between' : 'flex-end')};
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.empty_data {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.home-list-003 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
				font-size: var(--b3-font-size, 14px);
				line-height: 150%;
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			flex: 1;
			position: relative;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b3-font-size, 14px);
			line-height: 150%;
			letter-spacing: var(--b3-letter-spacing, -0.02em);
			font-weight: var(--b3-font-weight, 400);

			&:nth-last-child(1) {
				border-width: 0px 0px 0px 0px;
			}
		}

		.body-item {
			overflow: hidden;
		}
	}

	.notice-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
		height: unset;

		.body-item {
			overflow: hidden;
		}

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 12px 20px 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: center;
				font-family: 'Noto Sans KR', sans-serif;
				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			align-self: stretch;
			flex: 1;
			position: relative;
			box-sizing: border-box;
			height: unset;
		}

		.title p {
			-webkit-line-clamp: unset;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 20px;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			width: 100%;

			color: var(--color-black, #101010);
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;

			.file {
				text-align: left;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;

				p {
					min-width: auto;
					color: var(--color-grey-w, #b6bac0);
					font-family: var(--h5-font-family, 'Noto Sans KR', sans-serif);
					font-size: var(--h5-font-size, 14px);
					line-height: 150%;
					font-weight: var(--h5-font-weight, 700);
				}
			}
		}
	}

	.resource-content {
		width: 100%;
	}
`;
const desktopStyles = css `
	display: flex;
	width: 100%;
	flex-direction: column;
	height: 100%;

	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: ${props => (props.$isTeacher ? 'space-between' : 'flex-end')};
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.home-list-003 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 6px 28px 6px 28px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
				font-size: var(--b3-font-size, 16px);
				line-height: var(--b3-line-height, 32px);
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			flex: 1;
			position: relative;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 10px 6px 10px 6px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			letter-spacing: var(--b3-letter-spacing, -0.02em);
			font-weight: var(--b3-font-weight, 400);

			&:nth-last-child(1) {
				border-width: 0px 0px 0px 0px;
			}
		}

		.body-item {
			overflow: hidden;
		}
	}

	.notice-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
		height: unset;

		.title p {
			-webkit-line-clamp: unset;
		}

		.body-item {
			overflow: hidden;
		}

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 6px 28px 6px 28px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			flex: 1;
			position: relative;
			/* overflow: auto; */
			box-sizing: border-box;
			align-self: stretch;
			height: unset;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 20px 28px 20px 28px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.file {
				text-align: left;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;

				p {
					min-width: auto;
					color: var(--color-grey-w, #b6bac0);
					font-family: var(--h5-font-family, 'Noto Sans KR', sans-serif);
					font-size: var(--h5-font-size, 16px);
					line-height: var(--h5-line-height, 24px);
					font-weight: var(--h5-font-weight, 700);
				}
			}

			color: var(--color-black, #101010);
			font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
		}
	}

	.body-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
`;
const addUniqueNumbers = (arr, startNumber = 0, important_count = 0) => {
    let index = 0;
    return arr.map(item => {
        if (item.importance)
            return { ...item, number: -1 };
        else {
            index += 1;
            return {
                ...item,
                number: startNumber + index,
            };
        }
    });
};
export const OpenTable = React.memo(({ isResource = false, isTeacher = false, isHome = false, defaultOpen, }) => {
    const historyStore = useHistoryStore();
    const dispatch = useDispatch();
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const [initLoad, setInitLoad] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [count, setCount] = useState(isHome ? 5 : 10);
    const [checkedList, setCheckedList] = useState([]);
    const [searchOption, setSearchOption] = useState({});
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = isMobile && isTeacher
            ? []
            : [
                ...(isHome
                    ? []
                    : [
                        { title: 'no', space: 1, maxWidth: 45 },
                        { title: 'content', space: 4, sort: false },
                        ...(isMobile
                            ? []
                            : [
                                { title: 'attachFile', space: 1 },
                                { title: 'views', space: 1 },
                                ...(isTeacher
                                    ? [{ title: 'author_name', space: 1 }]
                                    : []),
                            ]),
                        { title: 'creation_time', space: 1 },
                    ]),
            ];
        const tableReadDataKeys = [
            ...(isHome
                ? [
                    {
                        title: 'importantAndtitleAndFiles',
                        space: 2,
                        sort: false,
                    },
                    { title: 'creation_time', space: 1 },
                ]
                : [
                    ...(isMobile && isTeacher
                        ? []
                        : [{ title: 'no', space: 1, maxWidth: 45 }]),
                    { title: 'title', space: 4 },
                    ...(isMobile
                        ? []
                        : [
                            { title: 'files', space: 1 },
                            { title: 'views', space: 1 },
                            ...(isTeacher ? [{ title: 'author_name', space: 1 }] : []),
                        ]),
                    { title: isMobile ? 'fileAndTime' : 'creation_time', space: 1 },
                ]),
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, [isHome, isMobile, isTablet, isTeacher]);
    const { data, refetch } = useNoticeList({
        ...(searchOption ? { ...searchOption } : {}),
        type: isResource ? 'study_data' : 'notice',
        offset: page === 0 ? 0 : page * count,
        limit: count,
        orderby: ['importance DESC', 'creation_time DESC'],
    });
    // (리스트 갱신
    const refetchList = throttle(refetch, 1000, { leading: true, trailing: false });
    // 이벤트 등록 - 리스트 갱신
    useEffect(() => {
        APP.eventManager.subscribe(REFRESH_POSTS_LIST, refetchList);
        return () => {
            APP.eventManager.unsubscribe(REFRESH_POSTS_LIST, refetchList);
        };
    }, []);
    useEffect(() => {
        const initializeState = () => {
            const previousPage = historyStore.getRecentPageHistory(isResource ? 'resourcePage' : 'noticePage');
            const searchOptions = previousPage?.searchOption;
            setSearchOption(searchOptions || {});
            setPage(previousPage?.prePage || 0);
            setInitLoad(true);
        };
        initializeState();
        return () => {
            setInitLoad(false);
            setSearchOption({});
        };
    }, [isResource]);
    useEffect(() => {
        if (selectedId !== defaultOpen)
            setSelectedId(defaultOpen);
    }, [defaultOpen]);
    useEffect(() => {
        if (data) {
            const { total_count, found_count, items, important_count } = data;
            if (total_count > 0 && found_count === 0) {
                const maxPage = Math.max(0, Math.ceil(total_count / count) - 1);
                setPage(maxPage);
                return;
            }
            const numbersItem = addUniqueNumbers(items, page === 0 ? 0 : page * count - important_count);
            setTotalCount(isHome ? Math.min(total_count, 5) : total_count);
            setTestData(numbersItem);
            return;
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    useEffect(() => {
        if (isHome) {
            setCount(5);
        }
        else {
            setCount(10);
        }
    }, [isHome, isResource]);
    useEffect(() => {
        if (!initLoad)
            return;
        if (count <= 0)
            return;
        refetchList();
    }, [count, page, searchOption, isResource, selectedId, initLoad]);
    const handlerMoreData = (page) => {
        if (isHome) {
            return;
        }
        setPage(page);
    };
    // 상단 레이아웃 검색 조건을 위한 action
    const handlerFilterList = (filter) => {
        setSearchOption(filter);
    };
    const renderTitle = useMemo(() => {
        return (React.createElement("div", { className: "header", style: {
                alignSelf: !isTeacher && 'flex-end',
                width: '100%',
            } },
            isTeacher && !isMobile && (React.createElement(DeleteResourceItem, { isOneDel: false, menu: isResource ? 'resource' : 'notice', deleteList: checkedList })),
            React.createElement(SearchBar, { className: `${!isMobile ? 'search-bar-qna' : 'search-bar-mobile'}`, filterData: "title", defaultSearchContent: searchOption['title'], handlerFilterList: handlerFilterList })));
    }, [isTeacher, isResource, checkedList, isDesktop, searchOption]);
    const handlerSelectItem = (item) => {
        if (!item)
            return;
        // 새로운 페이지로 이동할 때 현재 상태를 기록
        const historyItem = {
            preItems: {},
            props: {
                isResource,
                isTeacher,
                isHome,
            },
            prePage: page,
            searchOption: searchOption,
            pageName: isResource ? 'resourcePage' : 'noticePage',
        };
        historyStore.pushHistory(historyItem);
        dispatch(updateMenu('learningSupportRoom', { menu: isResource ? 'resource' : 'notice', detail: 'detail' }, selectedId, {
            item,
            isResource,
            selectedId: item.id,
        }));
    };
    const renderItem = (item, styles) => {
        if (!item)
            return;
        return tableReadDataKeys.map((headItem, index) => (React.createElement("div", { key: index, className: `body-item ${headItem.title}`, style: {
                flex: !isMobile && headItem.space,
                cursor: 'pointer',
                ...styles?.bodyItem,
                maxWidth: headItem?.maxWidth,
                width: isMobile && headItem.title === 'title' ? '100%' : 'unset', //학습지원실 제목 잘리게 하기 위해
            } },
            React.createElement(OpenItem, { key: item.id, item: item, headItem: headItem, isMobile: isMobile, isTablet: isTablet, isTeacher: isTeacher }))));
    };
    const renderEmpty = useMemo(() => React.createElement(EmptyTable, { title: `supportRoom.${isResource ? 'resource' : 'notice'}Empty` }), [isResource]);
    const renderGuidItem = useMemo(() => (React.createElement(DeleteResourceItem, { isOneDel: false, menu: isResource ? 'resource' : 'notice', deleteList: checkedList })), [checkedList, isResource]);
    return (React.createElement(OpenTableStyled, { "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile, "$isHome": isHome, "$isTeacher": isTeacher, className: `task-board-container open-table ${isHome && 'home'}` },
        React.createElement(Table, { className: isHome ? 'home-list-003 ' : 'notice-list-001', showCheckBox: isTeacher ? true : false, isInifinite: isHome ? true : false, selectedId: selectedId, page: page, data: testData, totalCount: totalCount, headTitleList: headTitleList, renderGuidItem: isTeacher && isMobile && !isHome && renderGuidItem, renderTitle: !isHome && renderTitle, renderItem: renderItem, renderEmpty: totalCount <= 0 && renderEmpty, handlerMoreData: handlerMoreData, handlerCheckList: setCheckedList, handlerSelectItem: handlerSelectItem, forceShowheader: isMobile && !isTeacher ? false : true })));
});
