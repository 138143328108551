import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../Colors';
import { Icon } from '../../../common';
export const OpenItem = React.memo(({ headItem, item, isTeacher, isMobile, isTablet, }) => {
    const { t } = useTranslation();
    const renderFile = React.useMemo(() => item.files && item.files.length > 0 ? (React.createElement("div", { className: "file file-value", style: { justifyContent: 'unset' } },
        React.createElement(Icon, { icon: "file", size: isMobile ? 12 : isTablet ? 14 : 20, fill: Colors.w_grey }),
        React.createElement("p", { className: "overText2" },
            "(",
            item.files.length,
            ")"))) : undefined, [item.files, isTablet]);
    const renderDate = React.useMemo(() => (React.createElement("p", { className: "overText2 date" }, moment(item.creation_time).format('YYYY-MM-DD'))), [item.creation_time, headItem]);
    switch (headItem.title) {
        case 'importantAndtitleAndFiles':
            return (React.createElement("div", { className: "home-title" },
                item.importance && (React.createElement("p", { className: "must-read", style: { minWidth: 'fit-content' } },
                    "[",
                    t('supportRoom.mustRead'),
                    "]")),
                React.createElement("p", { className: "overText name" }, item.title),
                renderFile));
        case 'title':
            if (isMobile) {
                return (React.createElement("div", null,
                    isTeacher && item.importance && (React.createElement("p", { className: "must-read importance", style: {
                            minWidth: 'fit-content',
                            ...(isTeacher
                                ? { top: '13px', position: 'absolute', left: '30px' }
                                : {}),
                        } }, t('supportRoom.mustRead'))),
                    React.createElement("p", { className: "overText name" }, item.title)));
            }
            else {
                return React.createElement("p", { className: "overText2" }, item.title);
            }
        case 'no':
            return (React.createElement("p", { className: "overText2" }, item.importance ? t('supportRoom.mustRead') : !isMobile && item.number));
        case 'files':
            return renderFile || React.createElement("p", null, "-");
        case 'creation_time':
            return renderDate;
        case 'fileAndTime':
            return (React.createElement("div", { className: "notice-info-wrap" },
                renderFile,
                React.createElement("div", { className: "view-wrap" },
                    React.createElement(Icon, { icon: "view", size: 13, fill: Colors.light_grey }),
                    React.createElement("p", null, item['views'])),
                isTeacher && React.createElement("p", null, item['author_name']),
                renderDate));
        default:
            return (React.createElement("p", { className: `
                        ${headItem.title === 'answerState' && item[headItem.title] === '답변완료'
                    ? 'pink'
                    : item[headItem.title] === '답변대기'
                        ? 'purple'
                        : ''} ${headItem.title} overText2
		            ` }, headItem.title === 'creation_time'
                ? moment(item.creation_time).format('YYYY-MM-DD')
                : item[headItem.title]));
    }
});
