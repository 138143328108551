import React from 'react';
import styled, { css } from 'styled-components';
import { Colors } from '../../../Colors';
import { Button, Icon } from '../../../common';
import { useGlobalState } from '../../../GlobalStateProvider';
const InfoTitleStyled = styled.div `
	&.test-info-title,
	&.test-info-title * {
		box-sizing: border-box;
	}
	&.test-info-title {
		background: var(--color-silver, #f3f4f8);
		border-radius: 12px;
		padding: 10px 12px 10px 24px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		min-width: ${props => (props.$isMobile ? 'unset' : '560px')};
		position: relative;
	}
	.title-container {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		flex: 1;
		overflow: hidden;

		svg {
			min-width: 22px;
		}
	}

	.text-1 {
		text-align: left;
		vertical-align: top;
		font-family: var(--h4-font-family, 'Noto Sans KR', sans-serif);

		font-weight: var(--h4-font-weight, 700);
		color: var(--color-black, #101010);
		flex: 1;
		word-break: break-all;
	}

	.btn-container {
		padding: 0;
		gap: 12px;
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.text-1 {
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 32px);
	}
	margin-bottom: 12px;
`;
const tabletStyles = css `
	.text-1 {
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 32px);
	}
`;
const desktopStyles = css `
	&.test-info-title {
		gap: 8px;
	}
	.text-1 {
		font-size: var(--h4-font-size, 20px);
		line-height: var(--h4-line-height, 32px);
		position: relative;

		&.noText {
			.button {
				border-radius: 6px;
				padding: 4px 8px 4px 8px;
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: center;
				justify-content: flex-start;
				flex-shrink: 0;
				position: relative;

				p {
					min-width: unset;
					color: var(--color-white, #ffffff);
					text-align: left;
					font-family: var(--b5-font-family, 'NotoSansKr-Regular', sans-serif);
					font-size: var(--b5-font-size, 12px);
					line-height: var(--b5-line-height, 22px);
					letter-spacing: var(--b5-letter-spacing, -0.02em);
					font-weight: var(--b5-font-weight, 400);
					position: relative;
					display: flex;
					align-items: center;
					justify-content: flex-start;
				}
			}
			span {
				position: relative;
			}
		}
	}
`;
export const DetailTitleInfo = React.memo(({ name, title, previousPage, children, }) => {
    const { isMobile, isTablet } = useGlobalState();
    return (React.createElement(InfoTitleStyled, { "$isMobile": isMobile, "$isTablet": isTablet, className: "test-info-title" },
        React.createElement("div", { className: "title-container" },
            React.createElement(Button, { className: "arrow", option: {
                    buttonBeforeIcon: {
                        show: true,
                        name: 'arrow',
                        size: 28,
                        color: Colors.black,
                    },
                }, onClick: previousPage }),
            typeof title === 'string' ? (React.createElement("div", { className: "text-1" }, title)) : (React.createElement("div", { className: "text-1 noText" }, title)),
            name && (React.createElement(React.Fragment, null,
                React.createElement(Icon, { className: "arrow-right", icon: "arrow", size: 22, fill: Colors.black }),
                React.createElement("p", { className: "text-1 overText" }, name)))),
        children));
});
