import { styled } from 'styled-components';
export const QnAContentsStyled = styled.div `
	&.question-contents {
		display: flex;
		flex-direction: column;
		gap: ${({ $isMobile }) => ($isMobile ? '16px' : '24px')};
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		${({ $isMobile }) => $isMobile &&
    `width: 100%;
			padding: 16px 20px;
			display: flex;
			flex-direction: column;
			gap: ${({ $isMobile }) => ($isMobile ? '16px' : '24px')};
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			border-radius: 8px;
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px;
			box-shadow: ${({ $isHistory }) => $isHistory
        ? 'none'
        : 'var(--shadow-silver-box-shadow, 2px 4px 10px 0px rgba(213, 217, 232, 0.1))'};`};

		.content-wrap {
			width: 100%;
			padding: ${({ $isMobile }) => ($isMobile ? '16px 20px' : '28px')};
			display: flex;
			flex-direction: column;
			gap: ${({ $isMobile }) => ($isMobile ? '16px' : '24px')};
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			border-radius: ${({ $isMobile }) => ($isMobile ? '8px' : '12px')};
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px;
			box-shadow: ${({ $isHistory }) => $isHistory
    ? 'none'
    : 'var(--shadow-silver-box-shadow, 2px 4px 10px 0px rgba(213, 217, 232, 0.1))'};
		}

		${({ $isMobile }) => $isMobile &&
    `
			font-size: 14px;
		`};

		.icon-question-mobile {
			padding-bottom: 4px;
		}

		.ql-editor {
			width: 100%;
		}

		.sub-info {
			border-style: dashed;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 0px 0px 1px 0px;
			overflow: hidden;
			width: 100%;

			.frame-987343-wrap {
				display: flex;
				gap: 20px;
				width: 100%;
				flex-wrap: wrap;

				${({ $isMobile }) => $isMobile && ` flex-direction: column; gap: 8px;`}
			}

			${({ $isMobile, $isTablet }) => $isMobile
    ? `
				.frame-987343, &.sub-info {
					display: flex;
					flex-direction: column;
					gap: 8px;
					align-items: flex-start;
					justify-content: center;
					align-self: stretch;
					flex-shrink: 0;
					position: relative;
					
				}

				& {
					padding-bottom: 24px;
				}
			`
    : `
					.frame-987343 {
						display: flex;
						flex-direction: column;
						gap: 8px;
						align-items: flex-start;
					justify-content: flex-start;
						align-self: stretch;
						flex-shrink: 0;
						position: relative;
						overflow: hidden;
						margin: 0 auto 0;


						&.one {
							width: fit-content;
    						max-width: 50%;
							min-width: 200px;
						}

						&.two {
							flex: 1;
						}
					}

					padding: ${$isTablet ? '0px 0px 14px 0px' : ' 0px 0px 28px 0px'};
					display: flex;
					flex-direction: row;
					gap: 24px;
					align-items: center;
					justify-content: center;
					align-self: stretch;
					flex-shrink: 0;
					position: relative;
			`}

			.icon-question-mobile {
				.ellipse-2126 {
					background: ${({ $isQuestion }) => $isQuestion ? `var(--pur, #a08ede)` : `var(--color-pink, #f46790)`};
					border-radius: 999px;
					padding: 6px 12px;
					display: flex;
					flex-direction: row;
					gap: 4px;
					align-items: center;
					justify-content: flex-start;
					flex-shrink: 0;
					position: relative;

					svg {
						min-width: 18px;
					}
				}

				.div {
					color: var(--color-white, #ffffff);
					text-align: left;
					font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
					font-size: var(--b5-font-size, 12px);
					line-height: var(--b5-line-height, 18px);
					letter-spacing: var(--b4-letter-spacing, -0.02em);
					font-weight: var(--b4-font-weight, 700);
					position: relative;
				}
			}

			.icon-question {
				flex-shrink: 0;
				width: ${({ $isTablet }) => ($isTablet ? '60px' : '72px')};
				height: ${({ $isTablet }) => ($isTablet ? '60px' : '72px')};
				position: relative;

				.ellipse-2126 {
					background: ${({ $isQuestion }) => $isQuestion ? `var(--pur, #a08ede)` : `var(--color-pink, #f46790)`};
					border-radius: 50%;
					width: ${({ $isTablet }) => ($isTablet ? '60px' : '72px')};
					height: ${({ $isTablet }) => ($isTablet ? '60px' : '72px')};
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					gap: 2px;

					.div {
						color: var(--color-white, #ffffff);
						text-align: left;
						font-family: var(--h5-font-family, 'Noto Sans KR', sans-serif);
						letter-spacing: var(--h5-letter-spacing, -0.02em);
						font-weight: var(--h5-font-weight, 700);
						position: relative;
					}

					${({ $isTablet }) => $isTablet
    ? `
						.div {
							font-size: var(--b5-font-size, 12px);
							line-height: var(--b5-line-height, 18px);
						}
					`
    : `
						.div {
							font-size: var(--h5-font-size, 16px);
							line-height: var(--h5-line-height, 24px);
						}
					`}
				}
			}

			.sub-item {
				display: flex;
				flex-direction: row;
				gap: 12px;
				align-items: flex-start;
				justify-content: flex-start;
				flex-shrink: 0;
				position: relative;
				overflow: hidden;
				width: 100%;

				&.attachFile {
					.value {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						gap: 8px;
					}
				}

				.label {
					color: var(--color-black, #101010);
					text-align: left;
					font-family: var(--h5-font-family, 'Noto Sans KR', sans-serif);
					letter-spacing: var(--h5-letter-spacing, -0.02em);
					font-weight: var(--h5-font-weight, 700);
					position: relative;
					width: fit-content;
					word-break: keep-all;
					min-width: ${props => (props.$isMobile ? '52px' : '60px')};
				}

				.value {
					color: var(--color-black, #101010);
					text-align: left;
					font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
					letter-spacing: var(--b3-letter-spacing, -0.02em);
					font-weight: var(--b3-font-weight, 400);
					position: relative;
				}

				.btn {
					display: flex;
					flex-direction: column;
					gap: 4px;
				}

				${({ $isTablet, $isMobile }) => $isTablet || $isMobile
    ? `
					.label {
						font-size: var(--b4-font-size, 14px);
						line-height: var(--b4-line-height, 22px);					  
					}	

					.value {
						font-size: var(--b4-font-size, 14px);
						line-height: var(--b4-line-height, 22px);					  
					}
				`
    : `
					.label {
						font-size: var(--h5-font-size, 16px);
						line-height: var(--h5-line-height, 24px);
					}

					.value {
						font-size: var(--b3-font-size, 16px);
						line-height:var(--h5-line-height, 24px);
					}
				`}
			}

			.divider {
				background: var(--color-grey-w, #b6bac0);
				flex-shrink: 0;
				width: 1px;
				height: 14px;
				position: relative;
				margin-top: 4px;
			}
		}
	}

	.test {
		width: fit-content;
		display: flex;
		flex-direction: column;
		gap: 8px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
		margin: 0 auto 0;

		span {
			width: max-content;
		}
	}

	${({ $isTablet, $isMobile }) => ($isTablet || $isMobile) &&
    `.test {
		position: absolute;
		top: 6px;
		right: 0;
	}`}

	.edit-bar2 {
		margin: 0 0 0 auto;
		${({ $isTablet, $isMobile }) => $isTablet || $isMobile
    ? `
			& {
				display: flex;
				flex-direction: row;
				gap: 8px;
				align-items: center;
				justify-content: flex-end;
				flex: 1;
				position: relative;
			}
					.tablet_btn {
						text-align: left;
						font-family: 'Noto Sans KR', sans-serif;
						font-size: 12px;
						letter-spacing: -0.02em;
						font-weight: 400;
						text-decoration: underline;
						position: relative;
						display: flex;
						align-items: center;
						justify-content: flex-start;
					}
					.modify_btn {
						color: var(--pur-d, #8268d9);
					}

					.delete_btn {
						color: var(--color-accent, #ed010f);
					}

					.tablet_btn {
						p {
							min-width: unset;
						}
					}
		`
    : `
			& {
				display: flex;
				flex-direction: column;
				gap: 10px;
				align-items: flex-end;
				justify-content: center;
				flex: 1;
				position: relative;
				
			}
		`}

		.btn-container2 {
			display: flex;
			flex-direction: row;
			gap: ${({ $isTablet, $isMobile }) => ($isTablet || $isMobile ? `10px` : `6px`)};
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}
`;
