import moment from 'moment';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from '../../../common';
import { useGlobalState } from '../../../GlobalStateProvider';
import { useConferenceReport, useGradeCardList, useReplyReportList } from '../../../server';
import { getIsTeacher, getUserId } from '../../base/account';
import { MobileTabLayout } from '../../base/layout';
import { GradeDropDown, StatusTable } from './DSSL007_List_Child';
import { AllStatusPanel } from './DSSL007_List_Child/AllStatusPanel';
import { downloadPdf } from './functions';
const DSSL007ListStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	justify-content: flex-start;
	flex: 1;
	position: relative;
	overflow-y: ${props => (props.$isAdmin ? '' : 'auto')};
	width: 100%;
	height: 100%;

	${({ $isTablet }) => $isTablet && `padding: 20px;`}

	${({ $isMobile }) => $isMobile &&
    `
		.empty_data {
			min-height: 64px;
		}	
	`}

	

	.download-btn {
		padding: 12px 24px;
	}

	.select-bar {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		padding: ${props => (props.$isDesktop ? ' 0px 0px 20px 0px' : ' 0px 0px 20px 0px')};
		display: flex;
		flex-direction: row;
		gap: 16px;
		align-items: center;
		justify-content: flex-end;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		${({ $isMobile }) => $isMobile
    ? `
			.dropdown {
				align-items: flex-end;
			}
			.dropdown-field {
				width:	126px;
				height: 46px;
			}
		`
    : `
			.dropdown {
				width: unset!important;
			}
		`}

		.field-short {
			justify-content: center;
			align-items: center;
			${props => props.$isMobile && `flex-direction: column;`}

			.field-global-001 {
				flex: 0;
				justify-content: center;

				${props => props.$isMobile
    ? `width: 100%; flex-direction: column; align-items: flex-start;
			
					
			`
    : ``}

				.task-label {
					white-space: break-spaces;
					${props => (props.$isDesktop ? `width: 130px;` : `width: 100px`)}
				}

				.fext-field {
					${props => (props.$isMobile ? `width: 100%;` : `width: auto; flex: 0;`)}
				}
			}
		}
	}

	.status-panel {
		.divider {
			background: var(--color-grey-c, #e6e8ed);
			align-self: stretch;
			flex-shrink: 0;
			width: 1px;
			position: relative;
		}
	}
`;
const STUDENT_LIST = [
    {
        title: 'attend',
        divider: true,
        color: '#f46790',
        list: ['allRound', 'attend', 'nonAttend'],
    },
    { title: 'test', color: '#3ab2bf', divider: true, list: ['allRound', 'stare', 'noStare'] },
    { title: 'task', color: '#458c94', divider: false, list: ['allRound', 'stare', 'noStare'] },
];
const TEACHER_LIST = [
    {
        title: 'attend',
        divider: true,
        color: '#f46790',
        list: ['allRound', 'attend', 'nonAttend'],
    },
    { title: 'test', color: '#3ab2bf', divider: true, list: ['allRound', 'stare', 'noStare'] },
    { title: 'task', color: '#458c94', divider: true, list: ['allRound', 'stare', 'noStare'] },
    { title: 'reply', color: '#458c94', divider: false, list: ['allRound', 'stare', 'noStare'] },
];
export const DSSL007_List = React.memo(({ isAdmin = false, classId, selectUserId, }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const LIST = useMemo(() => (isTeacher ? TEACHER_LIST : STUDENT_LIST), [isTeacher]);
    const user_id = selectUserId
        ? selectUserId
        : useSelector((state) => getUserId(state));
    const [selectDay, setSelectDay] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [attendStatus, setAttendStatus] = useState({ testData: [], totalCount: 0, done: 0 });
    const [testGrade, setTestGrade] = useState({ testData: [], totalCount: 0, done: 0 });
    const [assignmentGrade, setAssignmentGrade] = useState({
        testData: [],
        totalCount: 0,
        done: 0,
    });
    const [replyGrade, setReplyGrade] = useState({ testData: [], totalCount: 0, done: 0 });
    const { data: testData, refetch: testRefetch } = useGradeCardList({
        id: user_id,
        start_after: startDate?.startOf('month').format('YYYY-MM-DDTHH:mm:ssZ') || '',
        start_before: startDate?.endOf('month').format('YYYY-MM-DDTHH:mm:ssZ') || '',
        assignment: false,
        // offset: page === 0 ? 0 : page * count,
        // limit: count,
    });
    const { data: assignmentData, refetch: assignmentRefetch } = useGradeCardList({
        id: user_id,
        start_after: startDate?.startOf('month').format('YYYY-MM-DDTHH:mm:ssZ') || '',
        start_before: startDate?.endOf('month').format('YYYY-MM-DDTHH:mm:ssZ') || '',
        assignment: true,
        // offset: page === 0 ? 0 : page * count,
        // limit: count,
    });
    const { data: conferenceData, refetch: conferenceRefetch } = useConferenceReport({
        id: user_id,
        start_after: startDate?.startOf('month').format('YYYY-MM-DDTHH:mm:ssZ') || '',
        start_before: startDate?.endOf('month').format('YYYY-MM-DDTHH:mm:ssZ') || '',
        // offset: page === 0 ? 0 : page * count,
        // limit: count,
    });
    const { data: replyData, refetch: replyRefetch } = useReplyReportList({
        id: user_id,
        start_after: startDate?.startOf('month').format('YYYY-MM-DDTHH:mm:ssZ') || '',
        start_before: startDate?.endOf('month').format('YYYY-MM-DDTHH:mm:ssZ') || '',
        // offset: page === 0 ? 0 : page * count,
        // limit: count,
    });
    useEffect(() => {
        if (selectDay) {
            setStartDate(moment.unix(parseInt(selectDay)));
        }
    }, [selectDay]);
    useEffect(() => {
        if (user_id && startDate) {
            testRefetch();
            conferenceRefetch();
            assignmentRefetch();
            replyRefetch();
        }
    }, [user_id, startDate]);
    useEffect(() => {
        if (testData) {
            const { items, total_count: totalCount, done } = testData;
            setTestGrade({ testData: items, totalCount, done });
        }
    }, [testData]);
    useEffect(() => {
        if (assignmentData) {
            const { items: testData, total_count: totalCount, done } = assignmentData;
            setAssignmentGrade({ testData, totalCount, done });
        }
    }, [assignmentData]);
    useEffect(() => {
        if (conferenceData) {
            const { items: testData, total_count: totalCount, done } = conferenceData;
            setAttendStatus({ testData, totalCount, done });
        }
    }, [conferenceData]);
    useEffect(() => {
        if (replyData) {
            const { items: testData, total_count: totalCount, done } = replyData;
            setReplyGrade({ testData, totalCount, done });
        }
    }, [replyData]);
    const renderStatus = (item, data) => {
        return React.createElement(StatusTable, { key: "status_table", ...item, ...data });
    };
    const renderSelectDate = name => {
        return (React.createElement("div", { className: "select-bar", key: "select-bar" },
            React.createElement(GradeDropDown, { setDropValue: setSelectDay }),
            isMobile && (React.createElement(Button, { className: "btn_default xmd download-btn", text: "common.pdfDownload", onClick: () => downloadPdf(name) }))));
    };
    if (isMobile) {
        return (React.createElement(DSSL007ListStyled, { "$isAdmin": isAdmin, "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop },
            React.createElement(MobileTabLayout, { isTabMode: true, tabList: [
                    {
                        id: 1,
                        title: '출결 현황',
                        component: [
                            React.createElement(Fragment, { key: "attend" },
                                renderSelectDate(LIST[0]),
                                React.createElement("div", { className: "pdf-download" }, renderStatus(LIST[0], attendStatus))),
                        ],
                        styles: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        },
                    },
                    {
                        id: 2,
                        title: '테스트 성적',
                        component: [
                            React.createElement(Fragment, { key: "test" },
                                renderSelectDate(LIST[1]),
                                React.createElement("div", { className: "pdf-download" }, renderStatus(LIST[1], testGrade))),
                        ],
                        styles: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        },
                    },
                    {
                        id: 3,
                        title: '과제 성적',
                        component: [
                            React.createElement(Fragment, { key: "score" },
                                renderSelectDate(LIST[2]),
                                React.createElement("div", { className: "pdf-download" }, renderStatus(LIST[2], assignmentGrade))),
                        ],
                        styles: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        },
                    },
                ], showNav: false, showPageButton: false, showPageTab: true })));
    }
    return (React.createElement(DSSL007ListStyled, { "$isAdmin": isAdmin, "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop },
        React.createElement("div", { className: "select-bar" },
            React.createElement(GradeDropDown, { setDropValue: setSelectDay }),
            React.createElement(Button, { className: "btn_default xmd download-btn", text: "common.pdfDownload", onClick: () => downloadPdf(undefined) })),
        React.createElement("div", { className: "pdf-download" },
            React.createElement("div", { className: "status-panel2" }, LIST.map((item, index) => {
                if (!isTeacher && item.title === 'reply')
                    return null;
                const data = item.title === 'attend'
                    ? attendStatus
                    : item.title === 'test'
                        ? testGrade
                        : assignmentGrade;
                return (React.createElement(AllStatusPanel, { key: index, title: item.title, list: item.list, done: data.done, totalCount: data.totalCount, divider: item.divider }));
            })),
            LIST.map((item, index) => {
                if (!isTeacher && item.title === 'reply')
                    return null;
                const data = item.title === 'attend'
                    ? attendStatus
                    : item.title === 'test'
                        ? testGrade
                        : assignmentGrade;
                return (React.createElement(Fragment, { key: index },
                    React.createElement(StatusTable, { ...item, ...data }),
                    item.divider && React.createElement("div", { className: "divider" })));
            }))));
});
