import { toState } from '../../../redux/regist';
import { getIsTeacher } from '../account';
export const getClasses = (statful) => {
    const state = toState(statful);
    return state['base/classes'].classes;
};
export const getClassesTitle = (statful) => {
    const classes = getClasses(statful);
    return classes?.title;
};
const TEACHER_MENUS = [];
export const getHiddenMenus = (statful) => {
    const classes = getClasses(statful);
    return getIsTeacher(statful) ? TEACHER_MENUS : classes?.hidden_menus;
};
// location 객체에서 URL에서 UUID 추출
const extractClassIdFromPath = (pathname) => {
    const uuidRegex = /\/class\/([0-9a-fA-F-]+)/; // UUID 추출 정규표현식
    const match = pathname.match(uuidRegex);
    return match ? match[1] : undefined;
};
export const getClassesId = () => {
    const { pathname } = location;
    const classIdFromUrl = extractClassIdFromPath(pathname); // URL에서 UUID 추출
    const fallbackId = getClasses(APP.store.getState)?.id; // fallback ID (state에서 가져옴)
    return classIdFromUrl || fallbackId;
};
/**
 * 클래스에 속하는 그룹 멤버 아이디 가져온다
 * @param statful
 * @returns
 */
export const getClassesMember = (statful) => {
    const classes = getClasses(statful);
    return [...classes?.members, ...classes?.managers, classes?.host] || [];
};
export const getClassesSliceMember = (statful, count) => {
    return getClassesMember(statful).splice(0, count);
};
//학생 id만 가져옴
export const getClassesStudnet = (statful) => {
    const classes = getClasses(statful);
    return [...classes?.members] || [];
};
export const getClassesMemberCount = (statful) => {
    const members = getClassesMember(statful);
    return members.length || 0;
};
