import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Colors } from '../../../Colors';
import { Button, InputElement } from '../../../common';
import { FileAttach } from '../../../common/option';
import { useGlobalState } from '../../../GlobalStateProvider';
import { modifyQna, postFile, registQna, useQnaList, } from '../../../server';
import { getClassesId } from '../../base/classes';
import { DetailTitleInfo, MobileTabLayout } from '../../base/layout';
import { changeMobileMenuName, updateMenu } from '../../menu/action';
import { RegistContent } from '../DSTSL001/DSTSL001_Regist_Child';
import { QNA_TYPE } from './constant';
import { CategoryRadio } from './DSTS004_Regist_Child/CategoryRadio';
import { QnAListTable } from './DTST004_List_Child';
const DSTS004RegistStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 28px;
	align-items: flex-end;
	justify-content: flex-start;
	flex: 1;
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	padding: ${props => (props.$isTablet || props.$isMobile ? '20px' : '0px')};

	.ql-editor {
		padding: 4px 12px;
	}

	.content-regist-width {
		display: flex;
	}

	.task-label {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h5-font-family, 'Noto Sans KR', sans-serif);
		letter-spacing: var(--h5-letter-spacing, -0.02em);
		font-weight: var(--h5-font-weight, 700);
		position: relative;
		min-width: 70px;

		${({ $isTablet, $isMobile }) => $isMobile
    ? css ``
    : $isTablet
        ? css `
						font-size: 14px;
						line-height: 150%;
				  `
        : css `
						font-size: var(--h5-font-size, 16px);
						line-height: var(--h5-line-height, 24px);
				  `}
	}

	.border-line {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
	}

	.retry-wrap {
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		border-radius: 4px;
		padding: 0px 9px;
		gap: 0px !important;

		button {
			padding: 6px 0px;
		}
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.regist-content {
		width: 100%;
		overflow: auto;
		height: 100%;
		gap: 28px;
		display: flex;
		flex-direction: column;
		padding-bottom: 28px;
	}

	.content {
		min-height: 265px;
	}

	.border-line {
		padding: 0px 0px 28px 0px;
	}

	.attach_file {
		width: 100%;
	}
`;
const tabletStyles = css `
	.regist-content {
		width: 100%;
		overflow: auto;
		height: 100%;
		gap: 12px;
		display: flex;
		flex-direction: column;
		padding-bottom: 28px;
	}

	.border-line {
		padding: 0px 0px 12px 0px;
	}

	label {
		span {
			font-size: 12px;
		}
	}
`;
const desktopStyles = css `
	.task-label {
		max-width: 135px !important;
		min-width: 135px !important;
	}
	.regist-content {
		width: 100%;
		overflow: auto;
		height: 100%;
		gap: 28px;
		display: flex;
		flex-direction: column;
		padding-bottom: 28px;
	}

	.border-line {
		padding: 0px 0px 28px 0px;
	}
`;
export const DSTS004_Regist = React.memo(({ id, depths, isReQuestion = false, isTeacher, isPopUp = false, isModify, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isTablet, isMobile, isDesktop } = useGlobalState();
    const titleRef = React.createRef();
    const class_id = getClassesId();
    const containerRef = useRef(null);
    const [rootId, setRootId] = useState(id);
    const [item, setItem] = useState();
    const [title, setTitle] = useState();
    const [category, setCategory] = useState();
    const [privateValue, setPrivateValue] = useState(false);
    const [content, setContent] = useState();
    const [textLength, setTextLength] = useState(0);
    const [files, setFiles] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const fileMutation = useMutation(postFile);
    const registQnaMutation = useMutation(registQna);
    const modifyQnaMutation = useMutation(modifyQna);
    const { data, refetch } = useQnaList({
        ...(!isReQuestion ? { root_id: id } : { id }),
        with_content: true,
    }, 'qna_item');
    useEffect(() => {
        if ((title && title.trim() !== '') ||
            (content && content.replace(/<[^>]*>/g, '').trim().length !== 0) ||
            category ||
            (files && files.length > 0)) {
            APP.eventManager.publish('MENU_MOVE_ACTIVE', false);
        }
        else {
            APP.eventManager.publish('MENU_MOVE_ACTIVE', true);
        }
    }, [title, content, category, files]);
    useEffect(() => {
        if (isReQuestion) {
            if (isModify) {
                dispatch(changeMobileMenuName(t('menu.qnaRequestionModify')));
            }
            else {
                dispatch(changeMobileMenuName(t('menu.qnaRequestion')));
            }
        }
        else {
            if (isModify) {
                dispatch(changeMobileMenuName(t('menu.qnaModify')));
            }
            else {
                dispatch(changeMobileMenuName(t('menu.qnaRegist')));
            }
        }
    }, [isModify, isReQuestion]);
    useEffect(() => {
        if (data) {
            const { found_count, items, total_count } = data;
            if (found_count > 0) {
                const item = items[0];
                if (item)
                    setRootId(item.root_id);
                if (item && isModify) {
                    if (titleRef.current)
                        titleRef.current.value = item.title;
                    setTitle(item.title);
                    setCategory(item.category);
                    setPrivateValue(item.private);
                    setContent(item.content);
                    setFiles(item.files);
                    setItem(item);
                }
                setTotalCount(total_count);
                return;
            }
        }
        if (titleRef.current)
            titleRef.current.value = '';
        setTitle('');
        setContent('');
        setCategory('');
        setPrivateValue(false);
        setItem(undefined);
    }, [data]);
    useEffect(() => {
        if (id) {
            refetch();
        }
    }, [id]);
    const previousPage = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'list',
            id: undefined,
        });
    };
    const handleAnswerOK = async (index) => {
        let moveId = id;
        try {
            if (title === undefined || title.trim() === '') {
                alert('제목을 입력해주세요.');
                return;
            }
            if (!isReQuestion && (category === undefined || category === '')) {
                alert('카테고리를 선택해주세요.');
                return;
            }
            if (content.replace(/<[^>]*>/g, '').trim().length === 0) {
                alert('내용을 입력해주세요.');
                return;
            }
            APP.eventManager.publish('SHOW_LOADING', true);
            APP.eventManager.publish('MENU_MOVE_ACTIVE', true);
            let convertAttachFileList = [];
            // 첨부 파일이 있는 경우
            if (files && files.length > 0) {
                const alredyFile = files.filter(file => typeof file === 'string');
                const filterFile = files.filter(file => file instanceof File);
                // 첨부 파일 업로드를 비동기로 실행하고 완료될 때까지 기다립니다.
                if (filterFile && filterFile.length > 0) {
                    // @ts-ignore
                    const data = await fileMutation.mutateAsync(filterFile);
                    convertAttachFileList = [...data.result.link, ...alredyFile];
                }
                else {
                    convertAttachFileList = alredyFile;
                }
            }
            if (id && isModify) {
                const data = {
                    attachments: convertAttachFileList,
                    category,
                    content,
                    private: privateValue,
                    title: title.trim(),
                };
                await modifyQnaMutation.mutateAsync({
                    id,
                    props: data,
                });
                previousPage();
                dispatch(updateMenu(undefined, { menu: 'qna', detail: 'detail' }, rootId, {
                    depths,
                    isContent: false,
                }));
            }
            else {
                const data = isReQuestion
                    ? {
                        attachments: convertAttachFileList,
                        class_id,
                        content,
                        title: title.trim(),
                        type: QNA_TYPE.QUESTION,
                        root_id: id,
                    }
                    : {
                        attachments: convertAttachFileList,
                        category,
                        class_id,
                        content,
                        private: privateValue,
                        title: title.trim(),
                        type: isTeacher ? QNA_TYPE.ANSWER : QNA_TYPE.QUESTION,
                    };
                try {
                    //카테고리, 제목 미 설정 시 로딩창 닫기
                    if (!content || !category) {
                        APP.eventManager.publish('SHOW_LOADING', false);
                    }
                    const response = await registQnaMutation.mutateAsync(data);
                    moveId = rootId ? rootId : response.result.id;
                    previousPage();
                    dispatch(updateMenu(undefined, { menu: 'qna', detail: 'detail' }, moveId, {
                        depths: depths === undefined ? 0 : depths + 2,
                        isContent: false,
                    }));
                }
                catch (error) {
                    throw new Error(error);
                }
            }
        }
        catch (error) {
            alert(error);
        }
        finally {
            APP.eventManager.publish('MENU_MOVE_ACTIVE', false);
            APP.eventManager.publish('SHOW_LOADING', false);
        }
    };
    const renderTitleInfo = useMemo(() => (React.createElement(DetailTitleInfo, { key: "DetailTitleInfo", title: isReQuestion
            ? isModify
                ? t('menu.qnaRequestionModify')
                : t('menu.qnaRequestion')
            : isModify
                ? t('menu.qnaModify')
                : t('menu.qnaRegist'), previousPage: previousPage })), [isModify, isReQuestion]);
    const renderTitle = useMemo(() => (React.createElement("div", { key: 'title', className: `fext-field ${isReQuestion ? 'retry-wrap' : ''}` },
        isReQuestion && (React.createElement(Button, { className: "btn_tur mobile-xs", text: 'supportRoom.req' })),
        React.createElement(InputElement, { ref: titleRef, className: "showText", type: "text", placeholder: "\uC81C\uBAA9\uC744 \uC785\uB825\uD558\uC138\uC694", onChange: setTitle, style: {
                backgroundColor: '#ffffff',
                borderStyle: isReQuestion && !isTeacher && 'none',
                outline: isReQuestion && !isTeacher && 'none',
            } }))), [titleRef, setTitle, isTeacher, isReQuestion]);
    const renderTitleWrap = useMemo(() => (React.createElement("div", { key: 'title-wrap', className: "field-global-001" }, renderTitle)), [renderTitle]);
    const renderCategoryRadio = useMemo(() => !isReQuestion && (React.createElement(CategoryRadio, { key: 'category', defaultCategory: category, setCategory: setCategory, defaultPrivate: privateValue, setPrivate: setPrivateValue })), [category, privateValue]);
    const renderContent = useMemo(() => (React.createElement(RegistContent, { key: 'content', contentName: "\uB0B4\uC6A9", defaultValue: !isModify ? undefined : item?.content, setValue: setContent, handlerTextLength: setTextLength, isTeacher: isTeacher, containerRef: containerRef, minHeight: '265px' })), [isReQuestion, item]);
    const renderFileAttach = useMemo(() => (React.createElement(FileAttach, { key: 'attach-file', id: "attach_file", className: isMobile
            ? 'btn_tur mobile-xs'
            : isTablet
                ? 'btn_tur mobile-sm'
                : 'btn_tur xmd', isRegist: true, isQnA: true, fileList: files, setAttachFileList: setFiles })), [files]);
    const questionListPage = () => {
        dispatch(updateMenu(undefined, { menu: 'qna', detail: 'question_list' }, rootId, {
            isModify,
            isReQuestion: true,
            depths: depths,
            isHistory: true,
            isPopUp: true,
        }));
    };
    // 모바일일때 질문이력 보기 버튼
    const renderQuestionList = useMemo(() => depths > 0 && (React.createElement(Button, { key: 'renderQuestionList', className: "btn_line_tur mobile-xs", text: t('supportRoom.showQuestionList'), option: {
            buttonBeforeIcon: {
                show: true,
                name: 'question',
                size: 16,
                color: Colors.tur,
            },
        }, styles: { buttonWrap: { width: '100%', padding: '8px 16px', gap: '4px' } }, onClick: questionListPage })), [totalCount]);
    const renderQnAListTable = useMemo(() => (React.createElement(QnAListTable, { key: "table", id: rootId, isPopUp: isPopUp, isRetry: true, isHistory: true, hideDetail: isPopUp ? false : true })), [rootId, isPopUp]);
    if (isPopUp) {
        return (React.createElement(DSTS004RegistStyled, { className: "dsts004_regist_popup", "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        component: [renderQnAListTable],
                        styles: { gap: '12px', display: 'flex', flexDirection: 'column' },
                    },
                ], hideHr: true, test_id: id })));
    }
    if (isMobile) {
        return (React.createElement(DSTS004RegistStyled, { className: "dsts004_regist_mobile", "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        component: [
                            renderQuestionList,
                            renderCategoryRadio,
                            renderTitleWrap,
                            renderContent,
                            renderFileAttach,
                        ],
                        styles: { gap: '12px', display: 'flex', flexDirection: 'column' },
                    },
                ], showNav: false, showPageButton: true, submitAssignment: handleAnswerOK })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(DSTS004RegistStyled, { className: "dsts004_regist", "$isTablet": isTablet, "$isMobile": isMobile },
            isDesktop && renderTitleInfo,
            React.createElement("div", { className: "regist-content", ref: containerRef },
                depths !== undefined && (React.createElement("div", { className: "field-global-001" },
                    React.createElement("div", { className: "task-label" }, t('supportRoom.questionList')),
                    renderQnAListTable)),
                React.createElement("div", { className: "field-global-001 border-line" },
                    React.createElement("div", { className: "task-label" }, t('supportRoom.title')),
                    renderTitle),
                renderCategoryRadio,
                renderContent,
                renderFileAttach,
                React.createElement("div", { className: "btn-container", style: { gap: 6 } },
                    React.createElement(Button, { className: "btn_white xmd", text: t('common.cancel'), onClick: previousPage }),
                    React.createElement(Button, { className: "btn_default xmd", text: t('common.okay'), onClick: () => handleAnswerOK() }))))));
});
