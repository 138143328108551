import React, { useMemo } from 'react';
import { AverageBox } from './AverageBox';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../../GlobalStateProvider';
const AverageExamBoxStyled = styled.div `
	${props => (props.$isMobile ? mobileStyles : props.$isMobile ? tabletStyles : desktopStyles)}

	.pinkText {
		color: #f46790;
	}
`;
const mobileStyles = css `
	.fixed {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b2-font-family, 'Noto Sans KR', sans-serif);
		font-size: ${props => props.$isHome ? 'var(--b2-font-size, 14px)' : 'var(--b2-font-size, 16px)'};
		line-height: var(--b2-line-height, 36px);
		font-weight: var(--b2-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.value {
		color: var(--color-black, #101010);
		text-align: right;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 20px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.fixed-span-wrap {
		width: 100%;
		min-width: 150px;
		display: flex;
		align-items: center;
	}
	.fixed-2-span {
		color: var(--color-black, #101010);
		font-family: var(--b2-font-family, 'Noto Sans KR', sans-serif);
		font-size: ${props => props.$isHome ? 'var(--b2-font-size, 14px)' : 'var(--b2-font-size, 16px)'};
		line-height: var(--b2-line-height, 36px);
		font-weight: var(--b2-font-weight, 400);
		position: relative;
		min-width: 60px;
	}
	.fixed-2-span2 {
		color: var(--color-black, #101010);
		font-family: var(--b6-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b6-font-size, 10px);
		line-height: var(--b6-line-height, 20px);
		font-weight: var(--b6-font-weight, 400);
		position: relative;
	}
	.score2 {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: flex-end;
		justify-content: flex-end;
		position: relative;
		width: 100%;
	}
	.value2 {
		text-align: right;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 20px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
`;
const tabletStyles = css `
	.fixed {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		position: relative;
		width: 150px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.value {
		color: var(--color-black, #101010);
		text-align: right;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 20px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.fixed-span-wrap {
		width: 100%;
		min-width: 150px;
		display: flex;
		align-items: center;
	}
	.fixed-2-span {
		color: var(--color-black, #101010);
		font-family: var(--b2-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b2-font-size, 14px);
		line-height: var(--b2-line-height, 36px);
		font-weight: var(--b2-font-weight, 400);
		position: relative;
		min-width: 60px;
	}
	.fixed-2-span2 {
		color: var(--color-black, #101010);
		font-family: var(--b6-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b6-font-size, 10px);
		line-height: var(--b6-line-height, 20px);
		font-weight: var(--b6-font-weight, 400);
		position: relative;
	}
	.score2 {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: flex-end;
		justify-content: flex-end;
		position: relative;
		width: 100%;
	}
	.value2 {
		text-align: right;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 20px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
`;
const desktopStyles = css `
	.fixed {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b2-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b2-font-size, 18px);
		line-height: var(--b2-line-height, 36px);
		font-weight: var(--b2-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.value {
		color: var(--color-black, #101010);
		text-align: right;
		font-family: var(--h3-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--h3-font-size, 24px);
		line-height: var(--h3-line-height, 36px);
		font-weight: var(--h3-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
	}

	.fixed2 {
		color: var(--color-black, #101010);
		text-align: left;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.score2 {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: flex-end;
		justify-content: flex-end;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}
	.fixed-2-span {
		color: var(--color-black, #101010);
		font-family: var(--b2-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b2-font-size, 18px);
		line-height: var(--b2-line-height, 36px);
		font-weight: var(--b2-font-weight, 400);
	}
	.fixed-2-span2 {
		color: var(--color-black, #101010);
		font-family: var(--b6-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b6-font-size, 10px);
		line-height: var(--b6-line-height, 20px);
		font-weight: var(--b6-font-weight, 400);
	}
	.value2 {
		color: var(--color-black, #101010);
		text-align: right;
		font-family: var(--h3-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--h3-font-size, 24px);
		line-height: var(--h3-line-height, 36px);
		font-weight: var(--h3-font-weight, 700);
		position: relative;
		width: 74px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.fixed3 {
		color: var(--color-black, #101010);
		text-align: right;
		font-family: var(--h4-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--h4-font-size, 20px);
		line-height: var(--h4-line-height, 32px);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
`;
export const AverageExamAttendance = React.memo(({ average, question_count, isHome, }) => {
    const { isMobile, isTablet } = useGlobalState();
    const renderItem = useMemo(() => {
        return (React.createElement(AverageExamBoxStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isHome": isHome, className: "box-wrap" },
            React.createElement("div", { className: "box-wrap-item" },
                React.createElement("div", { className: "fixed" }, "\uD3C9\uADE0 \uC815\uB2F5\uC218"),
                React.createElement("div", { className: "value" }, average.corrects === -1
                    ? `-/${question_count}`
                    : `${average.corrects}/${question_count}`)),
            React.createElement("div", { className: "box-wrap-item" },
                React.createElement("div", { className: "fixed2" },
                    React.createElement("span", { className: "fixed-span-wrap" },
                        React.createElement("span", { className: "fixed-2-span" }, "\uD3C9\uADE0 \uC810\uC218"),
                        React.createElement("span", { className: "fixed-2-span2" }, "(100\uC810 \uD658\uC0B0 \uAE30\uC900)"))),
                React.createElement("div", { className: "score2" },
                    React.createElement("div", { className: `value2 ${isHome ? 'pinkText' : ''}` }, average.score === -1 ? '-' : average.score),
                    !isHome && React.createElement("div", { className: "fixed3" }, "\uC810")))));
    }, [average, question_count]);
    return (React.createElement(AverageBox, { title: "assignment.averageExamAttendance", renderItem: renderItem, isHome: isHome }));
});
