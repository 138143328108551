import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../../Colors';
import { Icon, InputElement, Table } from '../../../../../common';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { useScoreStatus, useStatistics } from '../../../../../server';
import { getUserId } from '../../../../base/account';
const ErrataTableStyled = styled.div `
	flex: 1;

	.popup-list-005 {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.item {
		display: flex;
		flex-shrink: 0;
		position: relative;

		.body-item {
			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
			position: relative;
			align-items: center;
			display: flex;
		}
	}

	.answer {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.frame {
			border-color: var(--color-black, #101010);
			border-radius: 50px;
			width: 28px;
			height: 28px;
			border-style: solid;
			border-width: 1px;
			padding: 0px 10px 0px 10px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-black, #101010);
				text-align: center;
				font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}
		}

		&.pink {
			.frame {
				border-color: var(--color-pink, #f46790);
			}

			p {
				color: var(--color-pink, #f46790);
			}
		}
	}

	.input-answer {
		background: #ffffff;
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 4px 10px 4px 10px;
		white-space: break-spaces;
		width: 100%;
		overflow: hidden;

		&.pink {
			.value {
				color: var(--color-pink, #f46790);
			}
		}

		.value {
			overflow-wrap: break-word;
			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 22px);
			letter-spacing: var(--b4-letter-spacing, -0.02em);
			font-weight: var(--b4-font-weight, 400);
			position: relative;
			align-self: stretch;
			flex: 1;
		}
	}

	.input_answer_wrapper {
		background: #ffffff;
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		overflow: hidden;

		input {
			width: 100%;
			outline: none;
			border: none;
			background: none;
		}
	}

	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	height: 100%;
	width: 100%;

	.popup-list-005 {
		align-self: stretch;

		.body-item {
			width: 100%;
			justify-content: flex-start;
		}

		.answer-wrap-mobile {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: ${props => (props.$isTeacher ? 'unset' : 'space-between')};
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			width: 100%;
		}

		.frame-987021 {
			display: flex;
			flex-direction: row;
			gap: 6px;
			align-self: stretch;

			${props => props.$isTeacher &&
    css `
					flex: 1;
					align-items: center;
				`}
			position: relative;
		}

		${props => props.$isTeacher
    ? css `
						.body-item {
							gap: 6px;
							width: 100%;
							justify-content: flex-start !important;

							.answer {
								flex: 1;
								align-items: flex-start;
							}
						}

						.answer-wrap {
							flex: 1;
							display: flex;
							flex-direction: row;
							gap: 12px;
						}

						.answer-wrap-mobile {
							width: 100%;
						}
				  `
    : css `
						.answer-wrap-mobile {
							gap: 12px;
						}

						.frame-987021 {
							width: 50%;
							align-items: center;
						}
				  `}
	}

	.item {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
		padding: 12px 0px 12px 0px;
		flex-direction: column;
		gap: 6px;
		align-items: flex-start;
		justify-content: center;
		align-self: stretch;

		.body-item {
			font-size: var(--b4-font-size, 12px);
		}
	}

	.input_answer_box {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}

	.input_answer_wrapper {
		padding: 10px;
		flex: 1;
		max-height: 38px;
	}

	.answer-text {
		color: var(--color-grey-l, #83868a);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 12px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.moblie-subject-text {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		position: relative;
		align-self: stretch;
		height: 21px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.subject-text {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		align-self: stretch;
		height: 21px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.subject-wrapper {
		display: flex;
		flex-direction: row;
		gap: 6px;
	}
`;
const desktopStyles = css `
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	height: ${props => (props.$noSubmit ? '100%' : 'unset')};
	width: 100%;
	/* overflow: hidden; */

	.popup-list-005 {
		flex: 1;
	}

	.table-header {
		background: var(--color-silver, #f3f4f8);
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px 0px 0px 0px;
		padding: 10px 16px 10px 16px;
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		color: var(--color-black, #101010);
		text-align: center;
		font-family: var(--b5-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b5-font-size, 14px);
		line-height: var(--b5-line-height, 22px);
		font-weight: var(--b5-font-weight, 400);
		position: relative;
	}

	.item {
		padding: 8px 16px 8px 16px;
		flex-direction: row;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;

		.body-item {
			font-size: var(--b4-font-size, 14px);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
			position: relative;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			&.pink {
				color: var(--color-pink, #f46790);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}

	.body-item {
		justify-content: center;
	}

	.input_answer_wrapper {
		padding: 4px 10px 4px 10px;
	}
`;
const count = 100;
export const ErrataTable = React.memo(({ subject_name, isDetail, test_id, member_id, isTeacher = false, }) => {
    const { t } = useTranslation();
    const { isDesktop, isMobile, isTablet } = useGlobalState();
    const local_user = useSelector((state) => getUserId(state));
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [notSubmit, setNotSubmit] = useState(false);
    const { data: statisticData, refetch: statisticRefetch } = useStatistics({ test_id });
    //모바일 창에서 오답률을 보여주기 위해
    useEffect(() => {
        statisticRefetch();
    }, [test_id]);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            { title: 'subject', space: 2, sort: false },
            { title: 'no', space: 1, sort: false },
            { title: 'answer', space: 1, sort: false },
            ...(isDetail ? [{ title: 'submitAnswer', space: 1, sort: false }] : []),
            ...(isDetail && !notSubmit ? [{ title: 'errata', space: 1, sort: false }] : []),
            { title: 'score', space: 1, sort: false },
        ];
        const tableReadDataKeys = [
            { title: 'subject', space: 2 },
            { title: 'number', space: 1 },
            { title: 'answer', space: 1 },
            ...(isDetail ? [{ title: 'value', space: 1 }] : []),
            ...(isDetail && !notSubmit ? [{ title: 'correction', space: 1 }] : []),
            { title: 'point', space: 1 },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, [notSubmit]);
    const { data, refetch } = useScoreStatus({
        id: test_id,
        member_id,
        offset: page === 0 ? 0 : page * count,
        limit: count,
        valid_correction: member_id === '' ? false : true,
    });
    const refetchList = throttle(refetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        if (test_id)
            refetchList();
    }, [member_id, test_id]);
    useEffect(() => {
        if (data && data.length > 0) {
            setTestData(data);
            setNotSubmit(member_id && data.every(i => i.correction === '' && i.value === ''));
            setTotalCount(data.length);
            return;
        }
        setTestData([]);
        setTotalCount(0);
        setNotSubmit(true);
    }, [data]);
    const handlerMoreData = () => {
        setPage(prevPage => prevPage + 1);
    };
    const renderBodyItem = (item, headItem, styles, isMobile, subject_name) => {
        const getContent = () => {
            if (isMobile && ['point', 'number'].includes(headItem.title))
                return null;
            switch (headItem.title) {
                case 'correction':
                    return React.createElement(Icon, { icon: item[headItem.title] === 'O' ? 'pass' : 'fail' });
                case 'value':
                case 'answer':
                case 'submitAnswer':
                    return (React.createElement(React.Fragment, null, !item[headItem.title] ? ('-') : item.type === 'choice' ? (isMobile ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "answer-wrap" },
                            React.createElement("div", { className: "answer-text" }, t('assignment.answer')),
                            React.createElement("div", { key: 'choiceAnswer', className: `answer ${headItem.title === 'answer' ? 'pink' : ''}`, style: styles?.bodyText },
                                React.createElement("div", { className: "frame" },
                                    React.createElement("p", null, item[headItem.title] || '-')))),
                        React.createElement("div", { className: "input_answer_wrapper" },
                            React.createElement(InputElement, { type: "text", isDisable: true, defaultValue: item[isMobile ? 'point' : headItem.title] }),
                            "\uC810"))) : (React.createElement("div", { className: `answer ${headItem.title === 'answer' ? 'pink' : ''}`, style: styles?.bodyText },
                        React.createElement("div", { className: "frame" },
                            React.createElement("p", null, item[headItem.title] || '-'))))) : isMobile ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "answer-wrap" },
                            React.createElement("div", { className: "answer-text" },
                                t('assignment.answer'),
                                ' '),
                            React.createElement("div", { className: `input-answer ${headItem.title === 'answer' ? 'pink' : ''}` },
                                React.createElement("p", { className: "value" },
                                    item.answer,
                                    " "))),
                        React.createElement("div", { key: 'AnswerWrap', className: "input_answer_wrapper" },
                            React.createElement(InputElement, { type: "text", isDisable: true, defaultValue: item[isMobile ? 'point' : headItem.title] }),
                            "\uC810"))) : (React.createElement("div", { className: `input-answer ${headItem.title === 'answer' ? 'pink' : ''}` },
                        React.createElement("p", { className: "value" }, item[isMobile ? 'point' : headItem.title])))));
                case 'point':
                    return (React.createElement("div", { className: "input_answer_wrapper" },
                        React.createElement(InputElement, { type: "text", isDisable: true, defaultValue: item[isMobile ? 'point' : headItem.title] }),
                        "\uC810"));
                case 'subject':
                    return (React.createElement("div", { key: headItem.title, className: isTeacher ? 'subject-wrapper' : '' },
                        isMobile && isTeacher && (React.createElement("p", { className: "subject-text" }, item['number'])),
                        React.createElement("div", { className: isMobile ? 'moblie-subject-text' : 'subject-text' }, ['history', 'character'].includes(subject_name)
                            ? t(`assignment.${subject_name}`)
                            : item[headItem.title])));
                default:
                    return React.createElement("p", { className: "overText" }, item[headItem.title]);
            }
        };
        return (React.createElement("div", { key: headItem.title, className: "body-item", style: {
                flex: !isMobile && headItem.space,
                ...styles?.bodyItem,
                overflow: 'hidden',
            } }, getContent()));
    };
    const renderItem = (item, styles) => tableReadDataKeys.map(headItem => renderBodyItem(item, headItem, styles, isMobile, subject_name));
    //학생 모바일 UI / 강사 모바일 자세히보기 UI
    const renderMobileItem = (item, styles) => {
        const { number, answer, value } = item;
        return tableReadDataKeys.map(headItem => {
            if (['number', 'answer', 'value'].includes(headItem.title))
                return null;
            if (headItem.title === 'correction') {
                return (React.createElement("div", { key: headItem.title, className: "body-item", style: {
                        flex: !isMobile && headItem.space,
                        ...styles?.bodyItem,
                        overflow: 'hidden',
                        flexDirection: 'column',
                    } }, isTeacher ? (React.createElement("div", { className: "answer-wrap-mobile" },
                    React.createElement("div", { className: "frame-987021" },
                        React.createElement("div", { className: "answer-text" }, t('assignment.answer')),
                        item.type === 'choice' ? (React.createElement("div", { className: "answer pink", style: styles?.bodyText },
                            React.createElement("div", { className: "frame" },
                                React.createElement("p", null, answer)))) : (React.createElement("div", { className: "input-answer" },
                            React.createElement("p", { className: "value" },
                                item.answer,
                                " ")))),
                    React.createElement("div", { className: "frame-987021" },
                        React.createElement("div", { className: "answer-text" }, t('assignment.submitValue')),
                        item.type === 'choice' ? (React.createElement("div", { className: "answer", style: styles?.bodyText },
                            React.createElement("div", { className: "frame" },
                                React.createElement("p", null, value)))) : (React.createElement("div", { className: "input-answer" },
                            React.createElement("p", { className: "value" },
                                item.value,
                                " ")))))) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "answer-wrap-mobile" },
                        React.createElement("div", { className: "frame-987021" },
                            React.createElement("div", { className: "answer-text" }, t('assignment.no')),
                            React.createElement("p", null, number)),
                        React.createElement("div", { className: "frame-987021" },
                            React.createElement("div", { className: "answer-text" }, t('assignment.errata')),
                            React.createElement(Icon, { icon: item[headItem.title] === 'O' ? 'pass' : 'fail' }))),
                    React.createElement("div", { className: "answer-wrap-mobile", style: {
                            flexDirection: item.type === 'choice' ? 'row' : 'column',
                        } },
                        React.createElement("div", { className: "frame-987021", style: {
                                width: item.type === 'choice' ? '50%' : '100%',
                            } },
                            React.createElement("div", { className: "answer-text", style: { minWidth: 55 } }, t('assignment.answer')),
                            item.type === 'choice' ? (React.createElement("div", { className: "answer pink", style: styles?.bodyText },
                                React.createElement("div", { className: "frame" },
                                    React.createElement("p", null, answer)))) : (React.createElement("div", { className: "input-answer" },
                                React.createElement("p", { className: "value" },
                                    item.answer,
                                    " ")))),
                        React.createElement("div", { className: "frame-987021", style: {
                                width: item.type === 'choice' ? '50%' : '100%',
                            } },
                            React.createElement("div", { className: "answer-text", style: { minWidth: 55 } }, t('assignment.submitValue')),
                            item.type === 'choice' ? (React.createElement("div", { className: "answer", style: styles?.bodyText },
                                React.createElement("div", { className: "frame" },
                                    React.createElement("p", null, value)))) : (React.createElement("div", { className: "input-answer" },
                                React.createElement("p", { className: "value" },
                                    item.value,
                                    " ")))))))));
            }
            else if (headItem.title === 'point') {
                return (React.createElement("div", { key: headItem.title, className: "input_answer_box" },
                    isTeacher && (React.createElement("div", { className: "frame-987021" },
                        React.createElement("div", { className: "answer-text" }, t('assignment.errata')),
                        React.createElement(Icon, { icon: item[headItem.title] === 'O' ? 'pass' : 'fail' }))),
                    React.createElement("div", { className: "input_answer_wrapper" },
                        React.createElement(InputElement, { type: "text", isDisable: true, defaultValue: item[headItem.title] }),
                        "\uC810"),
                    !isTeacher && (React.createElement("div", { className: "input_answer_wrapper" },
                        React.createElement(InputElement, { type: "text", isDisable: true, defaultValue: (statisticData.hardest_rank.find(rate => rate.question_id === item.question_id)?.incorrect_rate || '0') + '%' })))));
            }
            return renderBodyItem(item, headItem, styles, true, subject_name);
        });
    };
    const renderEmpty = useMemo(() => {
        return (React.createElement("div", { className: "flex center body3 sel_eval_item", style: { color: Colors.light_grey, width: '100%', height: '100%' } }, t('assignment.noSubmitPersonGuid')));
    }, []);
    return (React.createElement(ErrataTableStyled, { className: "errata_table", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile, "$isTeacher": isTeacher, "$noSubmit": notSubmit, style: {
            padding: isTablet ? '0 0 20px' : '0',
        } },
        React.createElement(Table, { className: "popup-list-005", showIndex: false, isInifinite: true, page: page, data: testData, showCount: count, renderEmpty: member_id === '' ? undefined : notSubmit && renderEmpty, totalCount: totalCount, headTitleList: headTitleList, styles: {
                headWrapper: {
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                headItem: {
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }, renderItem: isMobile && ((isTeacher && isDetail) || !isTeacher)
                ? renderMobileItem
                : renderItem, handlerMoreData: handlerMoreData, forceShowheader: isMobile ? false : true }),
        member_id === local_user && (React.createElement("div", { className: "final-notification" },
            React.createElement("div", { className: "line" }),
            React.createElement("div", { className: "message" },
                React.createElement(Icon, { icon: "check-circle", size: 24, fill: Colors.pink }),
                React.createElement("div", { className: "div" }, "\uC81C\uCD9C\uC774 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.")),
            React.createElement("div", { className: "line" })))));
});
