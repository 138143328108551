import _ from 'lodash';
import throttle from 'lodash/throttle';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Colors } from '../../../../Colors';
import { Button, CheckBox, CheckBoxElement, InputElement } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { postModifySendSMS, postRegistSendSMS, useSendSMSList, } from '../../../../server/sms';
import { DeletePopUpLayout } from '../../../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../../../base/popup';
import { updateMenu } from '../../../menu/action';
import { DATE_CHECK_LIST } from '../constant';
const SendSMSContentStyled = styled.div `
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	position: relative;

	.btn-recipient-setting {
		background: var(--color-white, #ffffff);
		border-radius: 100px;
		border-style: solid;
		border-color: var(--tur, #3ab2bf);
		border-width: 1px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;

		p {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			${props => !props.$isDesktop
    ? `
				font-size: 12px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
			`
    : `
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);`}
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		${props => !props.$isMobile
    ? `
					position: absolute;
					right: 24px;
					padding: 4px 18px 4px 18px;
					z-index: 1;	
					gap: 8px;
				`
    : `  
					padding: 6px 10px 6px 10px;
					gap: 6px;
				`}
	}

	${props => props.$isMobile
    ? `
				gap: 12px;
				flex: 1;
			  `
    : `
				background: var(--color-white, #ffffff);
				border-radius: 10px;
				border-style: solid;
				border-color: var(--color-grey-c, #e6e8ed);
				border-width: 1px;

				${props.$isDesktop
        ? `
							min-width: 450px;
							gap: 14px;
							padding: 26px;
						`
        : `
							min-width: 380px;
							gap: 12px;
							padding: 20px;
						`}
			  `}

	span,
	.overText {
		position: relative;
		text-align: left;

		${props => props.$isDesktop
    ? `
					font-size: var(--b3-font-size, 16px);
					line-height: var(--b3-line-height, 32px);
					letter-spacing: var(--b3-letter-spacing, -0.02em);
					font-weight: var(--b3-font-weight, 400);
				  `
    : `
					font-size: 14px;
					line-height: 150%;
					letter-spacing: -0.02em;
					font-weight: 400;
				  `}
	}

	.sms-wrap {
		flex-direction: column;
		flex: 1;
		gap: ${props => (props.$isDesktop ? '24px' : '12px')};
	}

	.send-wrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: ${props => (props.$isDesktop ? '14px' : '12px')};
	}

	textarea {
		&.focus {
			outline: 2px solid transparent;
			outline-offset: 2px;
			box-shadow: 0 0 5px $tur; // 흐림 효과 추가
		}

		height: 100% !important;
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		width: 100%;
		min-height: 200px;
		resize: none;
		overflow: hidden;
		padding: 9px;

		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: 150%;
		font-weight: var(--b4-font-weight, 400);
	}

	.xmd {
		p {
			font-size: 14px;
			line-height: 20px;
			display: flex;
			justify-content: center;
		}
	}

	.message-text {
		min-height: ${props => (props.$isDesktop ? '35px' : props.$isTablet ? '25px' : '20px')};
	}

	.send-type {
		span {
			font-size: ${props => (props.$isDesktop ? '14px' : '12px')};
		}
	}

	.input_send {
	}

	${props => props.$isMobile &&
    `
			.mobile-full {
				border-radius: 6px;
				p {
					justify-content: center;
				}
			}

			.btn-container {
				width: 100%;
			}
		`}
`;
export const SendSMSContent = React.memo(({ setIsOpen, editId, setEditId, openMemberList, receiverMemberList, setOpenMemberList, setReceiverMemberList, setSendMemberSetting, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [isImmediately, setIsImmediately] = React.useState(true); //발송일자 checkBox
    const [isFixNumber, setIsFixNumber] = React.useState(true); //발신번호 checkBox
    const [startDate, setStartDate] = useState(''); // 발송일
    const [startTime, setStartTime] = useState(''); // 발송시간
    const [directNumber, setDirectNumber] = useState(''); // 발신번호
    const [message, setMessage] = useState(''); // 발송 메시지
    // 현재 시간
    const now = useMemo(() => moment(), []);
    const registSendSMSMutation = useMutation(postRegistSendSMS);
    const modifySendSMSMutation = useMutation(postModifySendSMS);
    const { data, refetch } = useSendSMSList({
        id: editId,
    });
    const refetchList = throttle(() => refetch(), 1000, { leading: true, trailing: false });
    const cleanup = () => {
        setIsImmediately(true);
        setStartDate(moment().format('YYYY-MM-DD'));
        setStartTime(moment().format('HH:mm'));
        setIsFixNumber(true);
        setDirectNumber('');
        setMessage('');
        setReceiverMemberList([]);
        setIsOpen(false);
        setOpenMemberList(false);
    };
    useEffect(() => {
        if (editId)
            refetchList();
        else
            cleanup();
    }, [editId]);
    useEffect(() => {
        if (data) {
            const { items } = data;
            if (items && items.length >= 1) {
                const item = items[0];
                setIsImmediately(item.send_time === '' ? true : false);
                setStartDate(moment(item.send_time).format('YYYY-MM-DD'));
                setStartTime(moment(item.send_time).format('HH:mm'));
                setIsFixNumber(item.phone_number.includes('*') ? true : false);
                setDirectNumber(item.phone_number.includes('*') ? '' : item.phone_number);
                setMessage(item.content);
                const newReceiverMemberList = !_.isEqual(receiverMemberList, item.receivers) &&
                    receiverMemberList.length !== 0
                    ? receiverMemberList
                    : item.receivers;
                // @ts-ignore
                setReceiverMemberList(isMobile ? newReceiverMemberList : item.receivers);
                return;
            }
        }
        !isMobile && cleanup();
    }, [data]);
    const handlerCancel = () => {
        if (isMobile) {
            dispatch(updateMenu(undefined, { menu: 'sendSMS', detail: 'list' }));
            setIsOpen(false);
        }
        else {
            setEditId('');
            cleanup();
        }
    };
    const validatePhoneNumber = phoneNumber => {
        // 한국 전화번호 형식 검증: 010-1234-5678, 01012345678, 011-123-4567, 016-123-4567 등
        const phoneRegex = /^(01[016789]-?\d{3,4}-?\d{4}|070-\d{3,4}-\d{4}|0[2-9]{1}\d{1,2}-?\d{3,4}-?\d{4})$/;
        return phoneRegex.test(phoneNumber);
    };
    const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
    // (onClick) 보내기
    const handlerSend = async () => {
        if (!isImmediately && moment(`${startDate} ${startTime}`).isBefore(now)) {
            return alert(t('sendSMS.retypeDate'));
        }
        if (receiverMemberList.length === 0) {
            return alert(t('sendSMS.selectMember'));
        }
        if (!isFixNumber && !validatePhoneNumber(directNumber)) {
            return alert(t('sendSMS.retypeNumber'));
        }
        if (message.trim().length === 0) {
            return alert(t('sendSMS.retypeMessage'));
        }
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DeletePopUpLayout,
            componentProps: {
                deleteContent: editId ? t('sendSMS.editSMSPopUp') : t('sendSMS.submitSMSPopUp'),
                onRegist: hanlerSubmmit,
            },
            width: 639,
            title: editId ? t('sendSMS.editSMS') : t('sendSMS.submitSMS'),
            isFit: true,
        });
    };
    const hanlerSubmmit = async () => {
        if (editId) {
            try {
                APP.eventManager.publish('SHOW_LOADING', true);
                await modifySendSMSMutation.mutateAsync({
                    id: editId,
                    props: {
                        content: message,
                        phone_number: isFixNumber ? '' : directNumber,
                        receivers: receiverMemberList.map(item => item.id),
                        send_time: isImmediately
                            ? null
                            : moment(`${startDate} ${startTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                    },
                });
                APP.eventManager.publish('UPDATE_SEND_SMS');
                setEditId('');
                refetchList();
                cleanup();
                if (isMobile) {
                    dispatch(updateMenu(undefined, { menu: 'sendSMS', detail: 'list' }));
                    setIsOpen(false);
                }
            }
            catch (error) {
                console.error('Failed to post modifySendSMS:', error);
                alert(error);
            }
            finally {
                APP.eventManager.publish('SHOW_LOADING', false);
                closePopup();
            }
        }
        else {
            try {
                APP.eventManager.publish('SHOW_LOADING', true);
                await registSendSMSMutation.mutateAsync({
                    content: message,
                    phone_number: isFixNumber ? '' : directNumber,
                    receivers: receiverMemberList.map(item => item.id),
                    send_time: isImmediately
                        ? null
                        : moment(`${startDate} ${startTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                });
                APP.eventManager.publish('UPDATE_SEND_SMS');
                setReceiverMemberList([]);
                cleanup();
                if (isMobile) {
                    dispatch(updateMenu(undefined, { menu: 'sendSMS', detail: 'list' }));
                    setIsOpen(false);
                }
            }
            catch (error) {
                console.error('Failed to post registSendSMS:', error);
                alert(error);
            }
            finally {
                APP.eventManager.publish('SHOW_LOADING', false);
                closePopup();
            }
        }
    };
    const handlerOpenMemberList = () => {
        setOpenMemberList(!openMemberList);
        setSendMemberSetting('detail');
    };
    return (React.createElement(SendSMSContentStyled, { className: "send-sms-content", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        React.createElement(Button, { className: "btn-recipient-setting", text: `수신자 (${receiverMemberList?.length > 0
                ? receiverMemberList.length.toString().padStart(2, '0')
                : '00'})명`, option: {
                buttonAfterIcon: {
                    show: true,
                    className: isMobile
                        ? ''
                        : openMemberList
                            ? 'arrow-left'
                            : 'arrow-right',
                    name: isMobile ? 'plus' : 'twoarrow',
                    size: isDesktop ? 22 : 12,
                    color: Colors.tur,
                },
            }, onClick: handlerOpenMemberList }),
        React.createElement("div", { className: "field-global-001 sms-wrap" },
            React.createElement("div", { className: "field-short" },
                React.createElement("p", { className: "overText" }, t('sendSMS.sendType')),
                React.createElement(CheckBoxElement, { isRadioButton: true, id: "sendType", className: "send-type", title: t('sendSMS.message'), checked: true, handlerCheck: () => { } })),
            React.createElement("div", { className: "send-wrap" },
                React.createElement("div", { className: "field-short" },
                    React.createElement("p", { className: "overText" }, t('sendSMS.sendDate')),
                    React.createElement(CheckBox, { isRadioButton: true, list: DATE_CHECK_LIST, defaultCheckId: isImmediately ? 'now' : 'reservation', handlerCheck: (id, checked) => {
                            setIsImmediately(id === 'now');
                        } })),
                !isImmediately && (React.createElement("div", { className: "field-short" },
                    React.createElement("div", { className: `fext-field ${isMobile ? 'mobile-date-wrap' : ''}` },
                        React.createElement(InputElement, { type: "date", className: "input_send", value: startDate, onChange: setStartDate, min: now.format('YYYY-MM-DD') })),
                    React.createElement("div", { className: `fext-field ${isMobile ? 'mobile-date-wrap' : ''}` },
                        React.createElement(InputElement, { type: "time", className: "input_send", value: startTime, onChange: setStartTime }))))),
            React.createElement("div", { className: "send-wrap", style: { flex: '1' } },
                React.createElement("p", { className: "overText message-text", style: { width: '100%' } }, t('sendSMS.messageContent')),
                React.createElement(InputElement, { type: "text", textarea: true, isAutoHeight: true, value: message, onChange: value => {
                        if (value.length < 1000) {
                            setMessage(value);
                        }
                    }, placeholder: t('sendSMS.messageContent') }))),
        editId ? (React.createElement("div", { className: "btn-container", style: { width: '100%' } },
            React.createElement(Button, { className: `btn_line_pink ${isMobile ? 'mobile-full' : 'xmd'}`, text: t('common.cancel'), onClick: handlerCancel }),
            React.createElement(Button, { className: `btn_white ${isMobile ? 'mobile-full' : 'xmd'}`, text: t('common.modify'), onClick: handlerSend }))) : (React.createElement("div", { className: "btn-container", style: { width: '100%' } },
            React.createElement(Button, { className: `btn_white ${isMobile ? 'mobile-full' : 'xmd'}`, text: "\uBCF4\uB0B4\uAE30", onClick: handlerSend, styles: { buttonWrap: { marginTop: '12px' } } })))));
});
