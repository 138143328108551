import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../../../GlobalStateProvider';
export const AllStatusPanel = React.memo(({ title, list, done, totalCount, divider, }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "container" },
            !isMobile && React.createElement("div", { className: "label" }, t(`assignment.${title}Title`)),
            React.createElement("div", { className: "figure-group" }, list &&
                list.map((listItem, idx) => {
                    const value = listItem === 'allRound'
                        ? totalCount
                        : listItem === 'attend' || listItem === 'stare'
                            ? done
                            : totalCount - done;
                    return (React.createElement(Fragment, { key: idx },
                        React.createElement("div", { className: "figure" },
                            React.createElement("div", { className: "label2" }, t(`assignment.${listItem}`)),
                            React.createElement("div", { className: "value" }, value))));
                }))),
        divider && React.createElement("div", { className: "divider" })));
});
