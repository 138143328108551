import throttle from 'lodash/throttle';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Colors } from '../../../../../Colors';
import { useGlobalState, useHistoryStore } from '../../../../../GlobalStateProvider';
import { Button, SearchBar, Table } from '../../../../../common';
import { deleteReplay, useReplyList } from '../../../../../server';
import { getAccessToken, getIsTeacher } from '../../../../base/account';
import { getClasses } from '../../../../base/classes';
import { DeletePopUpLayout } from '../../../../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../../../../base/popup';
import { updateMenu } from '../../../../menu/action';
import { formatFileSize } from '../../functions';
import { DeleteReplayItem } from './DeleteReplayItem';
import { EmptyTable } from '../../../../../common/option';
const ReplyRoomTableStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: ${props => (props.$isMobile ? '12px' : '24px')};
	align-items: flex-start;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	width: 100%;
	flex: 1;
	height: 100%;

	${props => props.$isTablet && `padding: 20px;`}

	.title-wrap {
		display: flex;
		flex-direction: ${props => (props.$isMobile ? 'column' : 'row')};
		align-items: center;
		gap: ${props => (props.$isMobile ? '24px' : '0')};
		justify-content: ${props => (!props.$isMobile ? 'space-between' : 'flex-end')};
		flex-shrink: 0;
		position: relative;
		width: 100%;
		${props => props.$isMobile &&
    `
			padding: 20px;
		`}
	}

	.mobile_txt {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: ${props => (props.$isMobile ? '5px' : '12px')};

		span {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.bold_txt {
			font-weight: 700;
		}

		.grey_txt {
			color: var(--color-grey-l, #83868a);
		}
	}

	.live-list-005 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		${props => props.$isMobile &&
    `
			padding: 12px 20px;
			height: auto;
			.check-btn {
				border-style: solid;
				border-color: var(--color-silver, #f3f4f8);
				border-width: 0px 0px 1px 0px;
				padding: 0px 0px 8px 0px;
			}
		`}

		.scroll-content {
			display: flex;
			flex-direction: column;
		}

		.body-item {
			overflow: hidden;
		}

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: ${props => (props.$isTablet ? '12px 20px' : '6px 28px')};
			display: flex;
			flex-direction: row;
			gap: ${props => (props.$isTablet ? '12px' : '28px')};
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
				font-size: ${props => props.$isTablet ? 'var(--b4-font-size, 14px)' : 'var(--b3-font-size, 16px)'};
				line-height: ${props => (props.$isTablet ? '150%' : 'var(--b3-line-height, 32px)')};
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
				position: relative;
			}
		}

		.item {
			${props => props.$isMobile
    ? `
                        border-style: solid;
                        border-color: var(--color-silver, #f3f4f8);
                        border-width: 0px 0px 1px 0px;
                        padding: 12px 0px 12px 0px;
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        align-items: flex-start;
                        justify-content: center;
                        align-self: stretch;
                        flex-shrink: 0;
                        position: relative;
            `
    : `
                    border-style: solid;
                    border-color: var(--color-silver, #f3f4f8);
                    border-width: 0px 0px 1px 0px;
                    padding: ${props.$isTablet ? '24px 20px' : '20px 28px'};
                    display: flex;
                    flex-direction: row;
                    gap: ${props.$isTablet ? '12px' : '28px'};
                    align-items: center;
                    justify-content: flex-start;
                    align-self: stretch;
                    flex-shrink: 0;
                    position: relative;`}
			${props => props.$isTablet &&
    `
				.body-item {
					p {
						font-size: var(--b4-font-size, 14px);
						line-height: 150%;
					}
				}	
			`}
		}
	}

	.live-replay-005 {
		.scroll-content,
		.table-item-wrap {
			display: flex;
			flex-direction: row;
			gap: ${props => (props.$isMobile ? '14px' : props.$isTablet ? '16px' : '20px')};
		}
		.item {
			max-width: 300px;
			flex: 1;
		}
	}
`;
export const ReplyRoomTable = React.memo(({ isHome, round, year, month, }) => {
    const { t } = useTranslation();
    const tableRef = useRef(null);
    const dispatch = useDispatch();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const access_token = useSelector((state) => getAccessToken(state));
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const classes_data = useSelector((state) => getClasses(state));
    const isTable = useMemo(() => (!isTeacher || isHome ? false : true), [isTeacher, isHome]);
    const historyStore = useHistoryStore();
    const [previousPageState, setPreviousPageState] = useState(); // 이전 페이지의 상태를 저장
    const [previousSearchContent, setPreviousSearchContent] = useState(''); // 이전 검색어를 저장
    // 학생이 스트리밍을 선택한 경우
    const [choiceStreaming, setChoiceStreaming] = React.useState();
    const [testData, setTestData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [totalCount, setTotalCount] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [checkedList, setCheckedList] = React.useState([]);
    const [searchOption, setSearchOption] = React.useState({});
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        const headTitleList = isMobile || !isTable
            ? []
            : [
                { title: 'dataType', space: 1, sort: false },
                { title: 'live_title', space: 3, sort: false },
                { title: 'fileName', space: 2, sort: false },
                { title: 'size', space: 1, sort: false },
                { title: 'disclosure', space: 1, sort: false },
                { title: 'creation_time', space: 2, sort: false },
                { title: 'etc', space: 1, sort: false },
            ];
        const tableReadDataKeys = [
            ...(!isTable
                ? []
                : isMobile
                    ? [
                        { title: 'title', space: 1 },
                        { title: 'fileName', space: 1 },
                        { title: 'etc', space: 1 },
                    ]
                    : [
                        { title: 'round', space: 1 },
                        { title: 'title', space: 3 },
                        { title: 'fileName', space: 2 },
                        { title: 'size', space: 1 },
                        { title: 'status', space: 1 },
                        { title: 'creation_time', space: 2 },
                        { title: 'etc', space: 1 },
                    ]),
        ];
        return { headTitleList, tableReadDataKeys };
    }, [isTable, isMobile]);
    const deleteReplayMutation = useMutation(deleteReplay);
    const updateState = () => {
        if (tableRef.current) {
            const { offsetWidth } = tableRef.current;
            const count = Math.floor(offsetWidth / (isDesktop ? 300 : 252));
            setCount(count);
        }
    };
    useEffect(() => {
        if (isTeacher) {
            // 이전 페이지의 상태를 가져와서 적용
            const previousPage = historyStore.getRecentPageHistory('replayListPage');
            if (previousPage) {
                // 이전 페이지의 상태를 적용
                setSearchOption(previousPage.searchOption);
                setPreviousSearchContent(previousPage.searchOption[Object.keys(previousPage.searchOption)[0]]);
                setPage(previousPage.prePage);
                setPreviousPageState(previousPage);
            }
            historyStore.clearHistoryForPage('replayListPage');
        }
    }, [isTeacher]);
    useEffect(() => {
        if (!isTable) {
            updateState();
            window.addEventListener('resize', updateState);
            APP.eventManager.subscribe('UPDATE_REPLY_PAGE', handlerMoreData);
        }
        else {
            setCount(10);
            window.removeEventListener('resize', updateState);
            APP.eventManager.unsubscribe('UPDATE_REPLY_PAGE', handlerMoreData);
        }
        return () => {
            window.removeEventListener('resize', updateState);
            APP.eventManager.unsubscribe('UPDATE_REPLY_PAGE', handlerMoreData);
        };
    }, [isTable]);
    //홈화면 리스트 갱신
    useEffect(() => {
        if (!isTable)
            APP.eventManager.publish('SEND_REVIEW_LIST', { totalCount, count, page });
    }, [isTable, totalCount, count, page, classes_data]);
    const { data, refetch } = useReplyList({
        ...(previousPageState
            ? previousPageState.preItems
            : {
                ...searchOption,
                offset: page === 0 ? 0 : page * count,
                limit: count,
                round,
                year,
                month,
                orderby: ['creation_time DESC'],
            }),
    });
    const refetchList = throttle(() => {
        refetch();
    }, 1000, { leading: true, trailing: false });
    /** 다시보기 리스트 창에서 round, year, month 값이 변경되며
        리스트 갱신 오류가 생기는 것 같아 홈 화면과분리,
        학생 강의 다시보기가 제대로 동작하지 않아 isTeacher 추가 **/
    useEffect(() => {
        if (isHome || !isTeacher) {
            refetchList();
        }
    }, [round, year, month, page, count, searchOption, isHome, isTeacher]);
    useEffect(() => {
        if (!isHome && isTeacher) {
            refetchList();
        }
    }, [page, count, searchOption, isHome, isTeacher]);
    useEffect(() => {
        if (choiceStreaming) {
            let popup;
            if (popup && !popup.closed) {
                popup.location.href = '/player/' + choiceStreaming.id;
            }
            else {
                popup = window.open('/player/' + choiceStreaming.id, 'popupPage', 'directories=no,titlebar=no,toolbar=no,status=no,menubar=no,location=yes,width=1920,height=964,scrollbars=yes');
            }
            popup.localStorage.removeItem('access_token');
            popup.localStorage.setItem('access_token', access_token);
            if (popup) {
                popup.onload = function () {
                    popup = null;
                    setChoiceStreaming(undefined);
                    return;
                };
            }
            setChoiceStreaming(undefined);
        }
    }, [choiceStreaming]);
    // 이벤트 등록 - 리스트 갱신
    useEffect(() => {
        APP.eventManager.subscribe('REFRESH_REPLY_LIST', refetchList);
        return () => {
            APP.eventManager.unsubscribe('REFRESH_REPLY_LIST', refetchList);
        };
    }, []);
    useEffect(() => {
        if (data) {
            const { items, total_count } = data;
            //이전 페이지 저장된 기록 초기화
            if (previousPageState) {
                setPreviousPageState(undefined);
            }
            if (total_count > 0) {
                setTestData(items);
                setTotalCount(isHome ? Math.min(5, total_count) : total_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    // 상세 페이지로 이동
    const handlerDetailPage = (id, isModify = false) => {
        if (isHome) {
            dispatch(updateMenu('liveRoom', { menu: 'reply', detail: isModify ? 'modify' : 'detail' }, id));
        }
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: isModify ? 'modify' : 'detail',
            id,
        });
    };
    const handlerMoreData = (page) => {
        setPage(page);
    };
    const handlerSelectItem = (item) => {
        if (isTeacher) {
            const historyItem = {
                preItems: {
                    ...searchOption,
                    offset: page === 0 ? 0 : page * count,
                    limit: count,
                    round,
                    year,
                    month,
                    orderby: ['creation_time DESC'],
                },
                prePage: page,
                searchOption: searchOption,
                pageName: 'replayListPage',
            };
            historyStore.pushHistory(historyItem);
            handlerDetailPage(item.id, false);
        }
        else {
            setChoiceStreaming(item);
        }
    };
    const onDelete = async (id) => {
        try {
            APP.eventManager.publish('SHOW_LOADING', true);
            await deleteReplayMutation.mutateAsync(id);
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'list',
                id: undefined,
            });
            APP.eventManager.publish('REFETCH_QNA_LIST');
            console.log('1');
            refetchList();
        }
        catch (error) {
            alert(error);
        }
        finally {
            APP.eventManager.publish('SHOW_LOADING', false);
        }
    };
    // 하나 삭제
    const handlerOneDel = async (id) => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DeletePopUpLayout,
            componentProps: {
                deleteContent: t('assignment.delteInto', { count: 1 }),
                onDelete: () => onDelete(id),
            },
            width: 639,
            title: t(`conference.replayDelete`),
            isFit: true,
        });
    };
    const handlerFilterList = (filter) => {
        setSearchOption(filter);
    };
    const renderGuidItem = useMemo(() => React.createElement(DeleteReplayItem, { isOneDel: false, deleteList: checkedList, menu: "replay" }), [checkedList]);
    // Title 가져오기
    const renderTitle = useMemo(() => {
        return (React.createElement("div", { className: "title-wrap" },
            !isMobile && isTeacher && renderGuidItem,
            React.createElement(SearchBar, { className: `${!isMobile ? 'search-bar-qna' : 'search-bar-mobile'}`, handlerFilterList: handlerFilterList, filterData: "title", defaultSearchContent: previousSearchContent })));
    }, [setSearchOption, checkedList]);
    const renderEmpty = useMemo(() => React.createElement(EmptyTable, { title: "conference.replyaEmpty" }), [totalCount]);
    // Item 가져오기
    const renderItem = (item, styles) => {
        if (isTable) {
            return tableReadDataKeys.map((headItem, index) => {
                const isTitle = headItem.title === 'title';
                const isEtc = headItem.title === 'etc';
                const isCreationTime = headItem.title === 'creation_time';
                const round = item.round === 0 ? '-' : `${item.round} 주차`;
                const media = item.medias.length > 0 ? item.medias[0] : null;
                const mobileContent = isTitle ? (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "bold_txt" }, round),
                    React.createElement("span", { className: "bold_txt" }, item.title))) : isEtc ? (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "grey_txt" }, media ? formatFileSize(media?.size) : '-'),
                    React.createElement("span", { className: "grey_txt" }, t(`conference.${item.status}`)),
                    React.createElement("span", { className: "grey_txt" }, moment(item.creation_time).format('YYYY-MM-DD, HH:mm')))) : (React.createElement("span", null, item[headItem.title]));
                const desktopContent = isCreationTime ? (React.createElement("p", { className: "overText2" }, moment(item.creation_time).format('YYYY-MM-DD, HH:mm'))) : headItem.title === 'round' ? (React.createElement("p", { className: "overText2" }, round)) : headItem.title === 'fileName' ? (React.createElement("p", { className: "overText2" }, media?.name || '-')) : headItem.title === 'size' ? (React.createElement("p", { className: "overText2" }, media ? formatFileSize(media?.size) : '-')) : headItem.title === 'status' ? (React.createElement("p", { className: "overText2" }, t(`conference.${item.status}`))) : isEtc ? (React.createElement("div", { className: "flex row", style: { gap: 10 } },
                    React.createElement(Button, { option: {
                            buttonAfterIcon: {
                                show: true,
                                name: 'edit',
                                color: Colors.dark_blue,
                                size: 20,
                            },
                        }, onClick: e => {
                            e.stopPropagation();
                            handlerDetailPage(item.id, true);
                        } }),
                    React.createElement(Button, { option: {
                            buttonAfterIcon: {
                                show: true,
                                name: 'delete',
                                size: 20,
                            },
                        }, onClick: e => {
                            e.stopPropagation();
                            handlerOneDel(item.id);
                        } }))) : (React.createElement("p", { className: "overText2" }, item[headItem.title]));
                return (React.createElement("div", { key: index, className: `body-item ${isMobile ? 'mobile_txt' : ''}`, style: {
                        flex: headItem.space,
                        cursor: 'pointer',
                    } }, isMobile ? mobileContent : desktopContent));
            });
        }
        else {
            return (React.createElement("div", { className: "review-class-list", key: item.id },
                React.createElement("div", { className: "new" },
                    React.createElement("div", { className: "text" }, "new")),
                React.createElement("div", { className: "class-title overText2" }, item.title),
                React.createElement("div", { className: "regist-date-wrapper" },
                    React.createElement("div", { className: "regist-date" }, "\uB4F1\uB85D\uC77C\uC790"),
                    React.createElement("div", { className: "regist-time" }, moment(item.creation_time).format('YYYY-MM-DD, HH:mm')))));
        }
    };
    return (React.createElement(ReplyRoomTableStyled, { ref: tableRef, "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop },
        React.createElement(Table, { className: isTable ? 'live-list-005' : 'live-replay-005', showIndex: !isTable || isMobile ? false : true, showCheckBox: isTable && true, isInifinite: false, headTitleList: headTitleList, data: testData, page: page, totalCount: totalCount, showCount: count, noPaging: isTable ? false : true, renderTitle: isTable && renderTitle, renderEmpty: totalCount === 0 && renderEmpty, renderItem: renderItem, renderGuidItem: isTeacher && renderGuidItem, handlerCheckList: setCheckedList, handlerMoreData: handlerMoreData, handlerSelectItem: handlerSelectItem, forceShowheader: !isTable || isMobile ? false : true })));
});
