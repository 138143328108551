import _ from 'lodash';
import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from '../../../../../common';
import { EmptyTable } from '../../../../../common/option';
import { useGlobalState, useHistoryStore } from '../../../../../GlobalStateProvider';
import { useAssignmentList } from '../../../../../server';
import { getClasses } from '../../../../base/classes';
import { TOGGLE_POPUP } from '../../../../base/popup';
import { updateMenu } from '../../../../menu/action';
import { REFRESH_ASSIGNMENT_LIST, TEST_STATUS } from '../../constant';
import { AssignmentItem } from './AssignmentItem';
import { AssignmentTitle } from './AssignmentTitle';
import { DeleteListBtn } from './DeleteListBtn';
import { Container002Styled } from './DSTL001ListStyled';
import { getAssignmentTableProps } from './functions';
export const AssignmentTable = React.memo(({ isTeacher = false, isTest = false, isReserve = false, isBackOffice = false, isReserveRegist = false, isHome = false, defaultCheckList = [], setUpdateCheckList, selectItem, handlerSelectItem, conference_id, }) => {
    const historyStore = useHistoryStore();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes_data = useSelector((state) => getClasses(state));
    const { isDesktop, isTablet, isMobile, classesId } = useGlobalState();
    const [previousPageState, setPreviousPageState] = useState(); // 이전 페이지의 상태를 저장
    const [initLoad, setInitLoad] = useState(false);
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [count, setCount] = useState(isHome ? 5 : 10);
    const [checkList, setCheckList] = useState([]);
    // 과제 진행 상태
    const [status, setStatus] = useState('select');
    // 출제 유형
    const [type, setType] = useState('select');
    // Keyword 검색어
    const [keyword, setKeyword] = useState('');
    const { data, refetch } = useAssignmentList({
        ...(isReserve
            ? isReserveRegist
                ? { conference_id: '' }
                : { conference_id: ['', conference_id] }
            : {}),
        assignment: !isTest,
        offset: page === 0 ? 0 : page * count,
        limit: count,
        orderby: isBackOffice
            ? ['creation_time DESC']
            : isTest
                ? [
                    'status DESC',
                    'creation_time DESC',
                    'abs(timestampdiff(minute,now(),startline))',
                ]
                : ['status DESC', 'abs(timestampdiff(minute,now(),startline))'],
        ...(isHome && !isTest
            ? { status: [TEST_STATUS.OPEN, TEST_STATUS.CREATED] }
            : isReserve
                ? isBackOffice
                    ? {}
                    : { status: TEST_STATUS.CREATED }
                : {}),
        ...(isBackOffice ? { back_office: true } : { class_id: classesId }),
        ...(status !== 'select' ? { status } : {}),
        ...(type !== 'select' ? { type: t(`assignment.${type}`) } : {}),
        ...(keyword ? { keyword } : {}),
    });
    // (리스트 갱신
    const refetchList = throttle(() => {
        refetch();
    }, 1000, { leading: true, trailing: false });
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        return getAssignmentTableProps(isTest, isHome, isReserve, isTeacher, isBackOffice, isMobile, isTablet);
    }, [isTest, isHome, isReserve, isTeacher, isBackOffice, isMobile, isTablet]);
    useEffect(() => {
        const initializeState = () => {
            const previousPage = historyStore.getRecentPageHistory(isTest ? 'testListPage' : 'assignmentListPage');
            const searchOptions = previousPage?.searchOption;
            if (searchOptions) {
                for (const key in searchOptions) {
                    switch (key) {
                        case 'keyword':
                            setKeyword(searchOptions[key]);
                            break;
                        case 'status':
                            setStatus(searchOptions[key]);
                            break;
                        case 'type':
                            setType(searchOptions[key]);
                            break;
                    }
                }
            }
            setCheckList([]);
            setPage(previousPage?.prePage || 0);
            setInitLoad(true);
        };
        initializeState();
        return () => {
            setInitLoad(false);
            setStatus('select');
            setType('select');
        };
    }, [isTest]);
    // 이벤트 등록 - 리스트 갱신
    useEffect(() => {
        const delSuccessList = (list) => {
            setCheckList(prev => prev.filter(id => !list.includes(id)));
        };
        APP.eventManager.subscribe('DEL_SUCCESS_LIST', delSuccessList);
        return () => {
            APP.eventManager.unsubscribe('DEL_SUCCESS_LIST', delSuccessList);
        };
    }, []);
    // 이벤트 등록 - 리스트 갱신
    useEffect(() => {
        const updateOptions = (option) => {
            if (option.status !== undefined) {
                setStatus(option.status);
            }
            if (option.type !== undefined) {
                setType(option.type);
            }
            if (option.keyword !== undefined) {
                setKeyword(option.keyword);
            }
        };
        if (isBackOffice) {
            APP.eventManager.subscribe('UPDATE_SEARCH_OPTION', updateOptions);
        }
        return () => {
            APP.eventManager.unsubscribe('UPDATE_SEARCH_OPTION', updateOptions);
        };
    }, [isBackOffice]);
    // 과제/테스트 이동 시 체크리스트 초기화
    useEffect(() => {
        if (isBackOffice)
            return;
        APP.eventManager.publish('UPDATE_TABLE_ITEM', { className: 'task-list-001' });
    }, [testData]);
    // 이벤트 등록 - 리스트 갱신
    useEffect(() => {
        APP.eventManager.subscribe(REFRESH_ASSIGNMENT_LIST, refetchList);
        return () => {
            APP.eventManager.unsubscribe(REFRESH_ASSIGNMENT_LIST, refetchList);
        };
    }, []);
    //홈화면 리스트 갱신
    useEffect(() => {
        APP.eventManager.publish(REFRESH_ASSIGNMENT_LIST);
    }, [classes_data?.id]);
    useEffect(() => {
        if (data) {
            //이전 페이지 저장된 기록 초기화
            const { total_count, found_count, items } = data;
            if (total_count > 0 && found_count === 0) {
                const maxPage = Math.max(0, Math.ceil(total_count / 10) - 1);
                setPage(maxPage);
                return;
            }
            //이전 페이지 저장된 기록 초기화
            if (previousPageState) {
                setPreviousPageState(undefined);
            }
            setTotalCount(isHome ? Math.min(total_count, 5) : total_count);
            if (total_count > 0) {
                // @ts-ignore
                const list = items.map(i => ({ ...i }));
                if (checkList.length > 0 && isReserve) {
                    setTestData(prevTestData => {
                        const prevDataById = _.keyBy(prevTestData, 'id');
                        const newDataById = _.keyBy(list, 'id');
                        const mergedData = _.merge({}, prevDataById, newDataById);
                        const allItems = _.values(mergedData);
                        const checkedItems = allItems.filter(item => checkList.includes(item.id));
                        const notCheckedItems = allItems.filter(item => !checkList.includes(item.id));
                        return [...checkedItems, ...notCheckedItems];
                    });
                }
                else {
                    if (isReserve) {
                        setTestData(prevTestData => {
                            // prevTestData에서 list에 포함된 id만 필터링
                            const filteredPrevData = prevTestData.filter(item => !list.some(newItem => newItem.id === item.id));
                            const prevDataById = _.keyBy(filteredPrevData, 'id');
                            const newDataById = _.keyBy(list, 'id');
                            // 병합된 데이터 생성
                            const mergedData = _.merge({}, prevDataById, newDataById);
                            // 최종 배열 반환
                            return _.values(mergedData);
                        });
                    }
                    else {
                        if (!_.isEqual(testData, list))
                            setTestData(list);
                    }
                }
                return;
            }
            // setTestData(items);
            if (handlerSelectItem && total_count >= 1) {
                handlerSelectItem(items[0].id, items[0].title);
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    useEffect(() => {
        setCount(isHome ? 5 : 10);
        refetchList();
    }, [isHome, isTest, count, page, status, type, keyword]);
    useEffect(() => {
        return () => {
            setKeyword('');
        };
    }, [isTest]);
    // 상세 페이지로 이동
    const goToDetailPage = (id) => {
        // 새로운 페이지로 이동할 때 현재 상태를 기록
        const historyItem = {
            preItems: {},
            props: {
                isHome: isHome,
                isTest: isTest,
                isReserve: isReserve,
                isReserveRegist: isReserveRegist,
                conference_id: conference_id,
            },
            prePage: page,
            searchOption: {
                keyword,
                status,
                type,
            },
            pageName: isTest ? 'testListPage' : 'assignmentListPage',
        };
        historyStore.pushHistory(historyItem);
        dispatch(updateMenu(isTeacher ? 'learningManagement' : 'myRoom', {
            menu: isTest ? 'test' : 'assignment',
            detail: 'detail',
        }, id));
    };
    // 수정 페이지로 이동
    const handlerEdit = (id) => {
        // 새로운 페이지로 이동할 때 현재 상태를 기록
        const historyItem = {
            preItems: {},
            props: {
                isHome: isHome,
                isTest: isTest,
                isReserve: isReserve,
                isReserveRegist: isReserveRegist,
                conference_id: conference_id,
            },
            prePage: page,
            searchOption: {
                keyword,
                status,
                type,
            },
            pageName: isTest ? 'testListPage' : 'assignmentListPage',
        };
        historyStore.pushHistory(historyItem);
        if (isBackOffice) {
            APP.eventManager.publish('CHANGE_MENU', {
                showDepth: 'modify',
                props: { id },
            });
        }
        else {
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'modify',
                id: id,
                props: {
                    isBackOffice,
                },
            });
        }
    };
    const renderTitle = useMemo(() => initLoad && (React.createElement(AssignmentTitle, { isBackOffice: isBackOffice, isTest: isTest, isTeacher: isTeacher, checkList: checkList, status: status, type: type, keyword: keyword, setStatus: setStatus, setType: setType, setKeyword: setKeyword })), [isTest, isBackOffice, initLoad, isTeacher, status, type, keyword, checkList]);
    // list 페이지 업데이트 시
    const handlerMoreData = (page) => {
        if (isReserve) {
            setPage(prevPage => prevPage + 1);
        }
        else {
            setPage(page);
        }
    };
    const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
    const submitAssignment = () => {
        if (checkList.length > 1) {
            alert('테스트 등록은 하나만 가능합니다.');
            return;
        }
        setUpdateCheckList && setUpdateCheckList(checkList);
        closePopup();
    };
    const renderItem = (item, styles) => {
        if (!item)
            return;
        return tableReadDataKeys.map(headItem => {
            return (React.createElement("div", { key: headItem.title, className: `body-item ${headItem.title}`, style: {
                    flex: !isMobile && headItem.space,
                    ...styles?.bodyItem,
                    overflow: 'hidden',
                    cursor: (isHome && !isTest) || headItem.title === 'etc' || isBackOffice
                        ? 'default'
                        : 'pointer',
                    ...(isMobile &&
                        isTeacher &&
                        headItem.title === 'etc' && {
                        position: 'absolute',
                        top: '12px',
                        right: isHome ? '10px' : '0',
                        flex: 1,
                        width: 'calc(100% - 28px)',
                    }),
                    ...(headItem.title !== 'etc' && {
                        width: isMobile && headItem.title === 'title' ? '80%' : '100%',
                    }),
                }, onClick: isReserve || headItem.title === 'etc' || isBackOffice
                    ? undefined
                    : e => {
                        e.preventDefault();
                        goToDetailPage(item.id);
                    } },
                React.createElement(AssignmentItem, { headItem: headItem, item: item, isHome: isHome, isTest: isTest, isMobile: isMobile, isTablet: isTablet, isTeacher: isTeacher, isBackOffice: isBackOffice, styles: styles, handlerEdit: handlerEdit })));
        });
    };
    const renderEmpty = useMemo(() => {
        return (React.createElement(EmptyTable, { title: `assignment.${isTest ? 'test' : 'assignment'}emptyData` })
        // <div className="empty_data" style={{ color: Colors.light_grey }}>
        // 	{t(`assignment.${isTest ? 'test' : 'assignment'}emptyData`)}
        // </div>
        );
    }, [isTest]);
    const renderGuidItem = useMemo(() => !isReserve && (React.createElement(DeleteListBtn, { isTest: isTest, isOneDel: false, deleteList: checkList })), [checkList, isTest, isReserve]);
    return (React.createElement(Container002Styled, { id: "assignment-table", className: `task-board-container assignment-table ${isHome && 'home'} ${isReserve && isTest && 'liveRoom'}`, "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile, "$isHome": isHome, "$isTeacher": isTeacher, "$isTest": isTest, "$isReserve": isReserve, "$isBackOffice": isBackOffice },
        React.createElement(Table, { className: isHome ? 'home-list-003' : isReserve ? 'popup-list-006' : 'task-list-001', showCheckBox: !isHome && (isTeacher || isReserve) ? true : false, pagingCount: isMobile ? 5 : isTablet ? 7 : 10, showIndex: isHome ? false : isMobile ? false : true, isInifinite: isReserve ? true : false, data: testData, defaultCheckList: defaultCheckList, totalCount: totalCount, page: page, showCount: count, sortDefault: "id", headTitleList: headTitleList, renderTitle: !isReserve && !isHome && renderTitle, renderGuidItem: isTeacher && isMobile && !isHome && renderGuidItem, renderEmpty: totalCount <= 0 && renderEmpty, renderItem: renderItem, handlerMoreData: handlerMoreData, handlerCheckList: setCheckList, forceShowheader: !isTeacher && isMobile ? false : true, selectedId: selectItem }),
        isReserve && (React.createElement("div", { className: "btn-container", style: { margin: 0 } },
            React.createElement(Button, { text: "common.cancel", className: "btn_white xmd", onClick: closePopup }),
            React.createElement(Button, { text: "common.okay", className: "btn_default xmd", onClick: submitAssignment })))));
});
