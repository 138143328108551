import _ from 'lodash';
import throttle from 'lodash/throttle';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../Colors';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { Button, Icon, SearchBar, Table, } from '../../../../common';
import { useContents } from '../../../../server';
import { getIsManager } from '../../../base/account';
import { TOGGLE_POPUP } from '../../../base/popup';
import { ContentDropDown } from './ContentDropDown';
import { DeleteContentItem } from './DeleteItem';
import { DownloadQueue } from './DownloadQueue';
const ContentTableStyled = styled.div `
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${props => (props.$isMobile ? '24px' : '12px')};

	${props => props.$isDesktop
    ? `
				height: ${props.$isReserve ? 'unset' : '100%'};
			  `
    : props.$isTablet &&
        `
				height: 100%;
			  `}

	${props => (props.$isTablet ? TabletStyles : props.$isMobile ? mobileStyles : DesktopStyles)}
`;
const mobileStyles = css `
	padding: 20px;

	.btn-container {
		justify-content: center;
		button {
			flex: 1;
		}
	}

	.contents-list-001 {
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
		height: unset;

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			align-self: stretch;
			flex: 1;
			position: relative;
			box-sizing: border-box;

			${props => props.$isReserve &&
    css `
					max-height: 600px;
					overflow: auto;
				`}
		}

		.table-header {
			padding-bottom: 14px;
		}

		.item {
			box-sizing: border-box;
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 0px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: flex-start;
			justify-content: space-between;
			flex-shrink: 0;
			position: relative;
			width: 100%;

			color: var(--color-black, #101010);
			font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b3-font-size, 12px);
			line-height: 150%;
			letter-spacing: var(--b3-letter-spacing, -0.02em);
			font-weight: var(--b3-font-weight, 400);

			.typeText {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: 'Noto Sans KR', sans-serif;
				font-size: var(--b3-font-size, 12px);
				line-height: 150%;
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
			}

			.body-item {
				height: 100%;
				display: flex;
				justify-content: flex-start;
			}
		}
	}

	.contents-wrap {
		gap: 4px;
		display: flex;
		flex-direction: column;
		.title-text {
			font-size: 14px;
			font-weight: 700;
		}
	}

	.contents-item-wrap {
		color: var(--color-grey-l, #83868a);
		gap: 4px;
		display: flex;
	}

	.search-bar-big {
		padding: 10px;
		height: unset;
	}
`;
const TabletStyles = css `
	.header {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-end;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.contents-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		height: unset;

		.scroll-content {
			${props => props.$isReserve &&
    css `
					max-height: 600px;
					overflow: auto;
				`}
		}
	}

	.table-header {
		${props => props.$isReserve
    ? css `
						background: var(--color-silver, #f3f4f8);
						border-style: solid;
						border-color: var(--color-grey-w, #b6bac0);
						border-width: 1px 0px 0px 0px;
						padding: 10px 16px 10px 16px;
				  `
    : css `
						background: var(--color-silver, #f3f4f8);
						border-radius: 40px;
						padding: 12px 20px 12px 20px;
						color: var(--color-grey, #666666);
						line-height: var(--b3-line-height, 32px);
				  `}

		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		color: var(--color-grey, #666666);
		text-align: center;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.body-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.item {
		${props => props.$isReserve
    ? css `
						padding: 12px 16px 12px 16px;
				  `
    : css `
						padding: 24px 20px 24px 20px;
				  `}
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		p {
			color: var(--color-black, #101010);
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;
const DesktopStyles = css `
	.header {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-end;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.contents-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		height: unset;

		.scroll-content {
			${props => props.$isReserve &&
    css `
					max-height: 600px;
					overflow: auto;
				`}
		}
	}

	.table-header {
		${props => props.$isReserve
    ? css `
						background: var(--color-silver, #f3f4f8);
						border-style: solid;
						border-color: var(--color-grey-w, #b6bac0);
						border-width: 1px 0px 0px 0px;
						padding: 10px 16px 10px 16px;
				  `
    : css `
						background: var(--color-silver, #f3f4f8);
						border-radius: 40px;
						padding: 6px 28px 6px 28px;
						color: var(--color-grey, #666666);
						line-height: var(--b3-line-height, 32px);
				  `}

		display: flex;
		flex-direction: row;
		gap: 24px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		text-align: center;
		font-size: var(--b3-font-size, 16px);
		font-weight: var(--b3-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.body-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.item {
		${props => props.$isReserve
    ? css `
						padding: 12px 16px 12px 16px;
				  `
    : css `
						padding: 20px 28px 20px 28px;
				  `}
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;

		display: flex;
		flex-direction: row;
		gap: 24px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		p {
			color: var(--color-black, #101010);
			font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;
const downloadQueue = new DownloadQueue();
export const ContentTable = React.memo(({ typeList, menu, isReserve = false, defaultCheckList = [], setUpdateCheckList, isPopUp = false, }) => {
    const { t } = useTranslation();
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const isManager = useSelector((state) => getIsManager(state));
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [count, setCount] = useState(10);
    const [checkList, setCheckList] = useState([]);
    const [searchOption, setSearchOption] = useState();
    const [filterMenu, setFilterMenu] = useState([]);
    const { data, refetch } = useContents({
        type: filterMenu,
        offset: page === 0 ? 0 : page * count,
        limit: count,
        ...(searchOption ? { ...searchOption } : {}),
        status: isPopUp ? ['converted'] : ['converted', 'uploaded'],
        orderby: ['creation_time desc'],
    });
    useEffect(() => {
        setTestData([]);
        setTotalCount(0);
        setPage(0);
    }, [filterMenu]);
    useEffect(() => {
        setFilterMenu(menu ? [menu] : typeList);
    }, [menu, typeList]);
    const refetchList = throttle(() => {
        refetch();
    }, 1000, { leading: true, trailing: false });
    useEffect(() => {
        APP.eventManager.subscribe('REFRESH_CONTENTS', refetchList);
        APP.eventManager.subscribe('CONTENT_STATUS_ACCEPT', acceptContent);
        return () => {
            APP.eventManager.unsubscribe('REFRESH_CONTENTS', refetchList);
            APP.eventManager.unsubscribe('CONTENT_STATUS_ACCEPT', acceptContent);
        };
    }, []);
    const acceptContent = (data) => {
        const { contents_type } = data;
        if (contents_type === 'video') {
            refetchList();
        }
    };
    useEffect(() => {
        if (page !== 0)
            setPage(0);
    }, [searchOption]);
    useEffect(() => {
        if (filterMenu && filterMenu.length > 0)
            refetchList();
    }, [menu, page, searchOption, filterMenu]);
    useEffect(() => {
        if (data) {
            const { total_count, found_count, items } = data;
            if (total_count > 0 && found_count === 0 && !isReserve) {
                const maxPage = Math.max(0, Math.ceil(total_count / 10) - 1);
                setPage(maxPage);
                return;
            }
            if (total_count > 0) {
                const list = items.map(i => ({
                    ...i,
                }));
                if (checkList.length > 0) {
                    setTestData(prevTestData => {
                        const prevDataById = _.keyBy(prevTestData, 'id');
                        const newDataById = _.keyBy(list, 'id');
                        const mergedData = _.merge({}, prevDataById, newDataById);
                        const allItems = _.values(mergedData);
                        const checkedItems = allItems.filter(item => checkList.includes(item.id));
                        const notCheckedItems = allItems.filter(item => !checkList.includes(item.id));
                        return [...checkedItems, ...notCheckedItems];
                    });
                }
                else {
                    if (isReserve)
                        setTestData(prevTestData => {
                            const prevDataById = _.keyBy(prevTestData, 'id');
                            const newDataById = _.keyBy(list, 'id');
                            const mergedData = _.merge({}, prevDataById, newDataById);
                            return _.values(mergedData);
                        });
                    else
                        setTestData(items);
                }
                setTotalCount(total_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data, checkList]);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = isMobile && isReserve
            ? []
            : [
                { title: 'fileName', space: 3, sort: false },
                ...(isPopUp ? [{ title: 'type', space: 1 }] : []),
                { title: 'size', space: 1 },
                ...(isReserve
                    ? []
                    : [
                        { title: 'author_name', space: 1, sort: false },
                        { title: 'registDate', space: 1, sort: false },
                        { title: 'status', space: 1, sort: false },
                        { title: 'etc', space: 1, sort: false },
                    ]),
            ];
        const tableReadDataKeys = [
            { title: 'name', space: isMobile ? 2 : 3 },
            ...(isPopUp ? [{ title: 'type', space: 1 }] : []),
            { title: 'size', space: isMobile ? 2 : 1 },
            ...(isReserve
                ? []
                : [
                    { title: 'author_name', space: 1 },
                    { title: 'creation_time', space: 1 },
                    { title: 'status', space: 1, sort: false },
                    { title: 'etc', space: 1 },
                ]),
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, []);
    const handlerMoreData = (page) => {
        if (isReserve)
            setPage(prevPage => prevPage + 1);
        else
            setPage(page);
    };
    // 상단 레이아웃 검색 조건을 위한 action
    const handlerFilterList = (filter) => {
        setSearchOption(filter);
    };
    const handlerDownload = async (item) => {
        if (item.id && item.name) {
            downloadQueue.enqueue(item);
        }
    };
    const getFileName = (name) => {
        let parts = name.split('.');
        // 마지막 요소(확장자) 제거
        if (parts.length > 1) {
            parts.pop();
        }
        // 다시 문자열로 결합
        return (name = parts.join('.'));
    };
    const renderTitle = useMemo(() => {
        return (React.createElement("div", { className: "header" },
            isPopUp && (React.createElement(ContentDropDown, { typeList: typeList, setFilterMenu: setFilterMenu })),
            React.createElement(SearchBar, { className: `${!isMobile ? 'search-bar-qna' : 'search-bar-mobile'}`, filterData: "name", handlerFilterList: handlerFilterList })));
    }, [handlerFilterList]);
    const renderItem = (item, styles) => {
        return tableReadDataKeys.map((headItem, index) => isMobile &&
            (headItem.title === 'name' ||
                headItem.title === 'author_name' ||
                headItem.title === 'creation_time' ||
                headItem.title === 'status') ? null : (React.createElement("div", { key: index, className: "body-item", style: {
                flex: headItem.space,
                ...styles?.bodyItem,
                overflow: 'hidden',
                justifyContent: headItem.title === 'etc' && isMobile && 'flex-end',
            } }, headItem.title === 'etc' ? (item['status'] === 'converted' && (React.createElement("div", { style: { display: 'flex', gap: isMobile ? '20px' : '10px' } },
            isMobile && (React.createElement("p", { style: { width: 'max-content' } }, item['status'] === 'converted'
                ? t('common.complete')
                : t('supportRoom.uploading'))),
            isManager && (React.createElement(Button, { option: {
                    buttonAfterIcon: {
                        show: true,
                        name: 'file-down',
                        size: 20,
                        color: Colors.pur,
                    },
                }, onClick: () => handlerDownload(item) })),
            React.createElement(DeleteContentItem, { menu: menu, deleteList: [item.id] })))) : headItem.title === 'size' ? (isMobile ? (React.createElement("div", { className: "contents-wrap" },
            React.createElement("p", { className: "title-text overText2" }, getFileName(item['name'])),
            React.createElement("div", { className: "contents-item-wrap" },
                React.createElement("p", { style: { color: Colors.black } }, item[headItem.title]),
                React.createElement("p", null, item['author_name']),
                React.createElement("p", null, moment(item['creation_time']).format('YYYY-MM-DD'))))) : (React.createElement("p", null, item[headItem.title]))) : headItem.title === 'status' ? (React.createElement("p", null, item[headItem.title] === 'uploaded'
            ? t('supportRoom.uploading')
            : t('common.complete'))) : headItem.title === 'type' ? (React.createElement("p", null, t(`supportRoom.${item[headItem.title]}`))) : (React.createElement("p", null, headItem.title === 'creation_time'
            ? moment(item[headItem.title]).format('YYYY-MM-DD')
            : headItem.title === 'name'
                ? getFileName(item[headItem.title])
                : item[headItem.title])))), []);
    };
    const renderEmpty = useMemo(() => (React.createElement("div", { className: "empty_data", style: { color: Colors.light_grey } },
        React.createElement(Icon, { icon: "detail-delete", size: isTablet || isMobile ? 36 : 60, fill: Colors.w_grey }),
        React.createElement("p", null, isReserve ? t(`supportRoom.contentsEmpty`) : t(`supportRoom.${menu}Empty`)))), [menu]);
    const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
    const submitContents = () => {
        if (testData
            .filter(item => checkList.includes(item.id))
            .filter(item => item.type === 'background').length > 1) {
            alert(t('error.onlyOneBackground'));
            return;
        }
        setUpdateCheckList && setUpdateCheckList(checkList);
        closePopup();
    };
    const renderGuidItem = useMemo(() => isMobile && isPopUp && React.createElement("div", null), [isMobile, isPopUp]);
    return (React.createElement(ContentTableStyled, { "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile, "$isReserve": isReserve, className: "content-table" },
        React.createElement(Table, { className: 'contents-list-001', showIndex: isReserve || isMobile ? false : true, showCheckBox: isReserve ? true : false, 
            //isKeepChecked={isReserve ? true : false}
            isInifinite: isReserve ? true : false, defaultCheckList: defaultCheckList, page: page, showCount: count, data: testData, totalCount: totalCount, headTitleList: headTitleList, renderTitle: renderTitle, renderItem: renderItem, renderEmpty: totalCount <= 0 && renderEmpty, renderGuidItem: renderGuidItem, handlerCheckList: setCheckList, handlerMoreData: handlerMoreData, forceShowheader: (isTablet && totalCount <= 0) || (isMobile && !isReserve) ? false : true }),
        isReserve && (React.createElement("div", { className: "btn-container" },
            React.createElement(Button, { text: "common.cancel", className: "btn_white xmd", onClick: closePopup }),
            React.createElement(Button, { text: "common.okay", className: "btn_default xmd", onClick: submitContents })))));
});
