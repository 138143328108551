import { CHANGE_MENU_FROM_NAME, CHANGE_MENU_PROPS, CHANGE_MOBIL_MENU_NAME, GO_TO_PREV_MENU, SET_MENU, } from './actionType';
export const updateMenu = (depths, menu, id, props, isHistory) => {
    return {
        type: SET_MENU,
        depths,
        menu,
        id,
        props,
        isHistory,
    };
};
export const changeMenuFromName = (name, isMain = false) => {
    return {
        type: CHANGE_MENU_FROM_NAME,
        name,
        isMain,
    };
};
export const changeMemuProps = (props) => {
    return {
        type: CHANGE_MENU_PROPS,
        props,
    };
};
export const changeMobileMenuName = (name) => {
    return {
        type: CHANGE_MOBIL_MENU_NAME,
        name,
    };
};
export const goToPrevMenu = () => {
    return {
        type: GO_TO_PREV_MENU,
    };
};
