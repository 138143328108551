import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Button } from '../../../../../common';
import { deleteAssignmentList, postDeletePosts } from '../../../../../server';
import { DeletePopUpLayout } from '../../../../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../../../../base/popup';
import { REFRESH_POSTS_LIST } from '../../../DSTSL001/constant';
import { REFRESH_ASSIGNMENT_LIST } from '../../constant';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { Colors } from '../../../../../Colors';
export const DeleteListBtn = React.memo(({ isTest, isOneDel, deleteList, supportRoomType = undefined, styles, isBackOffice, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet } = useGlobalState();
    const deleteMutation = useMutation(deleteAssignmentList);
    const deletePostMutation = useMutation(postDeletePosts);
    const onDelete = async () => {
        const successfulDeletes = [];
        const failedDeletes = [];
        const deleteHandler = async (id, mutation) => {
            try {
                await mutation
                    .mutateAsync(id)
                    .then(data => {
                    // 삭제에 성공한 id를 successfulDeletes 리스트에 추가합니다.
                    successfulDeletes.push(id);
                })
                    .catch(error => {
                    // 삭제에 실패한 id를 failedDeletes 리스트에 추가합니다.
                    failedDeletes.push(id);
                });
                successfulDeletes.push(id);
            }
            catch (error) {
                console.error('Failed to delete post:', error);
                failedDeletes.push(id);
            }
        };
        const deletePromises = deleteList.map(id => {
            return !(supportRoomType === undefined)
                ? deleteHandler(id, deletePostMutation)
                : deleteHandler(id, deleteMutation);
        });
        try {
            // 모든 삭제 작업이 완료될 때까지 기다립니다.
            await Promise.all(deletePromises);
            APP.eventManager.publish('DEL_SUCCESS_LIST', successfulDeletes);
            APP.eventManager.publish(!(supportRoomType === undefined) ? REFRESH_POSTS_LIST : REFRESH_ASSIGNMENT_LIST);
            APP.eventManager.publish(TOGGLE_POPUP, undefined);
        }
        catch (error) {
            console.error('An error occurred while deleting ids:', error);
        }
    };
    const handlerDel = () => {
        if (deleteList.length <= 0)
            return;
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DeletePopUpLayout,
            componentProps: {
                deleteContent: deleteList.length
                    ? t('assignment.delteInto', { count: deleteList?.length })
                    : t('common.notSelect'),
                onDelete: onDelete,
            },
            width: 639,
            title: !(supportRoomType === undefined)
                ? t(`supportRoom.${supportRoomType}Delete`)
                : t(`assignment.${isTest ? 'testDelete' : 'delete'}`),
            isFit: true,
        });
    };
    if (isOneDel) {
        return (React.createElement(Button, { option: {
                buttonAfterIcon: {
                    show: true,
                    name: 'delete',
                    size: 20,
                    color: Colors.pink,
                },
            }, onClick: () => handlerDel() }));
    }
    return (React.createElement(Button, { text: "common.delete", className: `btn_default ${isMobile ? 'mobile-xs' : isTablet ? 'sm' : 'xmd'}`, onClick: handlerDel, styles: { buttonWrap: styles } }));
});
