import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../Colors';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { Button, DropDown, InputElement } from '../../../../common';
import { SurveyCheckBox } from './SurveyCheckBox';
import { DEFAULT_SURVEY_DROP, SURVEY_DROP_LIST, SURVEY_TYPE } from './constant';
import { useSelector } from 'react-redux';
import { getIsTeacher } from '../../account';
// SurveyItem 컴포넌트
const SurveyItem = React.memo(({ index, item, isSubmit = false, isRegist, setChangeSurveyList, setChangeSurveyData }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    const [data, setData] = React.useState();
    const titleRef = React.useRef(null);
    const isTeacher = useSelector((state) => getIsTeacher(state));
    // 드롭다운 값 상태
    const [dropValue, setDropValue] = React.useState(DEFAULT_SURVEY_DROP);
    useEffect(() => {
        if (_.isEqual(item, data))
            return;
        const newDropValue = SURVEY_DROP_LIST.find(drop => drop.id === item.type) || DEFAULT_SURVEY_DROP;
        setDropValue(newDropValue);
        if (titleRef.current)
            titleRef.current.value = item.title || '';
        setData(item);
    }, [item]);
    useEffect(() => {
        if (!data)
            return;
        if (data?.title && titleRef.current)
            titleRef.current.value = data?.title;
    }, [data?.title]);
    // 드롭다운 변경 핸들러
    const handleDropChange = (dropItem) => {
        setDropValue(dropItem);
        // @ts-ignore
        setData(prevData => ({
            ...prevData,
            data: dropItem.id === SURVEY_TYPE.CHECKBOX || dropItem.id === SURVEY_TYPE.OBJECTIVE
                ? []
                : '',
        }));
        setChangeSurveyData({
            id: item.id,
            type: dropItem.id,
            data: dropItem.id === SURVEY_TYPE.CHECKBOX || dropItem.id === SURVEY_TYPE.OBJECTIVE
                ? []
                : '',
        });
    };
    const renderQuestion = useMemo(() => (React.createElement("div", { className: "survey-title" },
        React.createElement("p", null,
            " Q",
            index + 1,
            ". "),
        isRegist ? (React.createElement(InputElement, { ref: titleRef, className: `survey-input-title ${isMobile ? '' : 'body4 '}`, type: "text", placeholder: t('survey.inputQuestionPlaceholder'), onChange: value => {
                // @ts-ignore
                setData({ ...data, title: value });
                setChangeSurveyData({
                    // @ts-ignore
                    id: data.id,
                    title: value,
                });
            } })) : (React.createElement("p", null, data?.title)))), [isRegist, index, titleRef, data]);
    const renderType = useMemo(() => (
    /* 드롭다운 */
    React.createElement(DropDown, { className: "dropdown-mini", value: dropValue, list: SURVEY_DROP_LIST, setDropValue: handleDropChange, option: {
            titleIcon: { show: false },
            itemIcon: { show: false },
        }, styles: {
            dropWrap: { width: isMobile ? '100%' : 95 },
            titleWrap: { backgroundColor: Colors.transparent, height: '100%' },
        }, isDisable: !isRegist })), [dropValue, isRegist]);
    const render = useMemo(() => (
    /* 드롭다운 */
    React.createElement(DropDown, { className: "dropdown-mini", value: dropValue, list: SURVEY_DROP_LIST, setDropValue: handleDropChange, option: {
            titleIcon: { show: false },
            itemIcon: { show: false },
        }, styles: {
            dropWrap: { width: isMobile ? '100%' : 95 },
            titleWrap: { backgroundColor: Colors.transparent, height: '100%' },
        }, isDisable: !isRegist })), [dropValue, isRegist]);
    const inputRef = React.useRef(null);
    React.useEffect(() => {
        if (!item)
            return;
        if (inputRef.current) {
            inputRef.current.value = item.data;
        }
    }, [item]);
    if (!data)
        return null;
    return (React.createElement("div", { className: "survey body4", key: index },
        React.createElement("div", { className: "survey-header" }, isMobile ? (React.createElement(React.Fragment, null,
            renderType,
            renderQuestion)) : (React.createElement(React.Fragment, null,
            renderQuestion,
            renderType))),
        React.createElement("div", { className: "survey-body" },
            React.createElement("div", { className: "survey-content" }, dropValue.id === SURVEY_TYPE.CHECKBOX ||
                dropValue.id === SURVEY_TYPE.OBJECTIVE ? (React.createElement(SurveyCheckBox, { key: data.id, id: data.id, isRadioButton: dropValue.id === 'choice', defaultList: item.data, isRegist: isRegist, isSubmit: isSubmit, isDisable: isTeacher, setChangeSurveyData: setChangeSurveyData })) : (React.createElement("div", { className: "textarea-item", style: { flex: 1 } }, (isTeacher && !isRegist) || isSubmit ? (React.createElement("div", { className: "input-answer", style: {
                    minWidth: dropValue.id === SURVEY_TYPE.LONG_ANSWER
                        ? '100%'
                        : '150px',
                } }, item.data)) : (React.createElement(InputElement, { ref: inputRef, className: "survey-input ", type: "text", style: dropValue.id === SURVEY_TYPE.LONG_ANSWER
                    ? { height: 85 }
                    : undefined, textarea: dropValue.id === SURVEY_TYPE.LONG_ANSWER ? true : false, defaultValue: isRegist ? item.data : isSubmit ? item.data : '', onChange: value => {
                    setData({ ...data, data: value });
                    setChangeSurveyData({
                        id: data.id,
                        data: value,
                    });
                }, isDisable: isTeacher || isSubmit, max: 500 }))))),
            isRegist && (React.createElement("div", { className: "survey-footer" },
                React.createElement(Button, { styles: {
                        buttonWrap: {
                            background: Colors.transparent,
                        },
                    }, onClick: () => setChangeSurveyList(item.id), option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: 'del-survey',
                            size: isMobile ? 14 : 20,
                            color: Colors.black,
                        },
                    } }),
                React.createElement(Button, { styles: {
                        buttonWrap: {
                            background: Colors.transparent,
                        },
                    }, onClick: () => setChangeSurveyList(), option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: 'add-survey',
                            size: isMobile ? 14 : 20,
                            color: Colors.black,
                        },
                    } }))))));
});
export default SurveyItem;
