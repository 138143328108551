import { t } from 'i18next';
import moment from 'moment';
import React, { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Table } from '../../../../common';
import { EmptyTable } from '../../../../common/option';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { updateMenu } from '../../../menu/action';
import { StatusBox } from './StatusBox';
const StatusTableStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 6px;
	align-items: flex-end;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	width: 100%;
	height: 100%;
	flex: ${props => (props.$isTablet ? 3 : 1)};

	.head-isAttend,
	.head-isSubmit,
	.head-score,
	.attend_done,
	.test_done,
	.test_score,
	.task_done,
	.task_score {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.report-list-001 {
		${props => props.$isDesktop
    ? `border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
		overflow: hidden;
		`
    : props.$isMobile &&
        `
				`}
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: 'Noto Sans KR', sans-serif;
				${props => props.$isDesktop
    ? `font-size: var(--b3-font-size, 16px);
                    line-height: var(--b3-line-height, 32px);
                    letter-spacing: var(--b3-letter-spacing, -0.02em);`
    : props.$isMobile
        ? `font-size: 12px;
                        line-height: 150%;
                        letter-spacing: -0.02em;`
        : `font-size: 14px;
                        line-height: 150%;
                        letter-spacing: -0.02em;`};
				position: relative;
				flex: 1;
			}
		}

		.item {
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			span {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: 'Noto Sans KR', sans-serif;
				${props => props.$isDesktop
    ? `font-size: var(--b3-font-size, 16px);
                    line-height: var(--b3-line-height, 32px);
                    letter-spacing: var(--b3-letter-spacing, -0.02em);`
    : props.$isMobile
        ? `font-size: 12px;
                        line-height: 150%;
                        letter-spacing: -0.02em;
						min-width: 35px`
        : `font-size: 14px;
                        line-height: 150%;
                        letter-spacing: -0.02em;`};
				font-weight: 400;
				position: relative;
			}

			.title {
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				word-break: keep-all;
				overflow-wrap: break-word;
				white-space: break-spaces;
			}
		}

		${props => props.$isMobile && `.scroll-content {overflow: visible; display: flex}`}

		.mobile_item {
			display: flex;
			flex-direction: row;
			gap: 12px;
			padding: 0;

			.mobile_wrap {
				border-style: solid;
				border-color: var(--color-silver, #f3f4f8);
				border-width: 0px 0px 1px 0px;
				padding: 12px 0px 12px 0px;
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: center;
				justify-content: flex-start;
				align-self: stretch;
				flex-shrink: 0;
				position: relative;
			}

			.title_wrap {
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: flex-start;
				justify-content: center;
				flex: 1;
				position: relative;
			}

			.left_wrap {
				display: flex;
				flex-direction: column;
				gap: 4px;
				align-items: flex-start;
				justify-content: center;
				flex: 1;
				position: relative;
			}

			.one_line {
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: center;
				justify-content: flex-start;
				flex-shrink: 0;
				position: relative;
			}

			.grey {
				color: var(--color-grey-l, #83868a);
			}

			.big {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: 'Noto Sans KR', sans-serif;
				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 600;
			}

			.big title {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: 'Noto Sans KR', sans-serif;
				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 600;
				position: relative;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			.big_txt {
				color: var(--color-black, #101010);
				text-align: right;
				font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 22px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
				width: 42px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}

	.more {
		color: var(--color-grey, #666666);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 16px;
		line-height: 32px;
		letter-spacing: -0.02em;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;

		&.close {
			color: var(--color-pink, #f46790);
		}
	}

	.empty_data {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 24px;
		color: var(--color-grey, #666666);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		min-height: 230px;
	}

	.none {
		min-height: 35px;
	}
`;
function formatNumber(value) {
    const formattedValue = value.toFixed(1); // Format the number to one decimal place
    if (formattedValue.endsWith('00')) {
        return parseInt(formattedValue, 10); // Convert to integer if no decimal part
    }
    if (formattedValue.endsWith('0')) {
        return parseFloat(formattedValue.slice(0, -1)); // Remove trailing zero if exists
    }
    return parseFloat(formattedValue); // Return the formatted value with up to one decimal place
}
function getWeekNumber(targetDate) {
    // Get the start of the month of the targetDate
    const startOfMonth = targetDate.clone().startOf('month');
    // Calculate the week number of the targetDate within the month
    const diffDays = targetDate.diff(startOfMonth, 'days');
    const weekNumber = Math.floor(diffDays / 7) + 1;
    return weekNumber;
}
const renderTextContent = (headItem, item, title) => {
    switch (headItem.title) {
        case 'mobile_data':
            return (React.createElement("div", { className: "mobile_wrap" },
                React.createElement("div", { className: "left_wrap" },
                    React.createElement("div", { className: "title_wrap" },
                        React.createElement("span", null,
                            getWeekNumber(moment(item.startline)),
                            " \uC8FC\uCC28"),
                        title === 'test' && (React.createElement("span", { className: "title" },
                            " ",
                            item.conference_name))),
                    React.createElement("span", { className: "big title" }, item?.test_name || item.conference_name),
                    React.createElement("span", { className: "grey" }, moment(item.startline).format('YYYY-MM-DD'))),
                title === 'attend' && (React.createElement("span", { className: "big_txt" }, item.done ? '출석' : '결석')),
                title !== 'attend' && (React.createElement("span", { className: "big_txt", style: { width: '60px' } }, item.score === -1 ? '미제출' : item.score))));
        case 'done':
            return (React.createElement("span", null, title === 'attend'
                ? item[headItem.title]
                    ? '출석'
                    : '결석'
                : item[headItem.title]
                    ? 'O'
                    : 'X'));
        case 'total_streaming_play_duration':
            const totalSeconds = item[headItem.title];
            // NaN 체크
            if (isNaN(totalSeconds)) {
                return React.createElement("span", null, "-");
            }
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;
            // 시간과 분 모두 0일 경우 초만 표시, 시간이 0일 때는 분과 초만 표시
            if (hours > 0) {
                return (React.createElement("span", null,
                    String(hours).padStart(2, '0'),
                    "\uC2DC ",
                    String(minutes).padStart(2, '0'),
                    "\uBD84",
                    String(seconds).padStart(2, '0'),
                    "\uCD08"));
            }
            else if (minutes > 0) {
                return (React.createElement("span", null,
                    String(minutes).padStart(2, '0'),
                    "\uBD84",
                    String(seconds).padStart(2, '0'),
                    "\uCD08"));
            }
            else {
                return React.createElement("span", null,
                    String(seconds).padStart(2, '0'),
                    "\uCD08");
            }
        case 'last_streaming_play_time':
        case 'startline':
            return item[headItem.title] ? (React.createElement("span", null, moment(item[headItem.title]).format('YYYY-MM-DD'))) : (React.createElement("span", null, "-"));
        case 'weeks':
            return React.createElement("span", null,
                getWeekNumber(moment(item.startline)),
                " \uC8FC\uCC28");
        case 'replay_name':
            return React.createElement("span", null, item.test_name);
        case 'title':
            return React.createElement("span", null, title === 'attend' ? item.test_name : item.conference_name);
        case 'score':
            return React.createElement("span", null, item.score === -1 ? '미제출' : item.score);
    }
    return React.createElement("span", { className: "overText2" }, item[headItem.title]);
};
export const StatusTable = React.memo(({ title, color, testData = [], totalCount, done, }) => {
    const { isDesktop, isMobile, isTablet } = useGlobalState();
    const dispatch = useDispatch();
    const statusTableRef = useRef(null); // Reference for the scrollable div
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        const isAttend = title === 'attend';
        const isTest = title === 'test';
        const isReply = title === 'reply';
        const headTitleList = isMobile
            ? []
            : isReply
                ? [
                    { title: 'weeks', space: 1, maxWidth: 100 },
                    { title: 'replyTitle', space: 3 },
                    { title: 'isWatching', space: 1 },
                    { title: 'watchtime', space: 1 },
                    { title: 'recentWatchDate', space: 2 },
                ]
                : [
                    { title: 'weeks', space: 1, maxWidth: 100 },
                    ...(isAttend || isTest ? [{ title: 'class_name', space: 2 }] : []),
                    ...(isAttend ? [] : [{ title: isTest ? 'testName' : 'title', space: 2 }]),
                    { title: isAttend ? 'attendDate' : 'examinationDate', space: 1 },
                    ...(isAttend
                        ? [{ title: 'isAttend', space: 1, maxWidth: 100 }]
                        : [{ title: 'isSubmit', space: 1, maxWidth: 100 }]),
                    ...(isAttend ? [] : [{ title: 'score', space: 1, maxWidth: 50 }]),
                ];
        const tableReadDataKeys = isMobile
            ? [{ title: 'mobile_data', space: 1 }]
            : isReply
                ? [
                    { title: 'weeks', space: 1, maxWidth: 100 },
                    { title: 'replay_name', space: 3 },
                    { title: 'done', space: 1 },
                    { title: 'total_streaming_play_duration', space: 1 },
                    { title: 'last_streaming_play_time', space: 2 },
                ]
                : [
                    { title: 'weeks', space: 1, maxWidth: 100 },
                    ...(isAttend || isTest ? [{ title: 'conference_name', space: 2 }] : []),
                    ...(isAttend ? [] : [{ title: 'test_name', space: 2 }]),
                    { title: 'startline', space: 1 },
                    { title: 'done', space: 1, maxWidth: 100 },
                    ...(isAttend ? [] : [{ title: 'score', space: 1, maxWidth: 50 }]),
                ];
        return { headTitleList, tableReadDataKeys };
    }, [title, isMobile]);
    const renderItem = (item, styles, index, isMobile) => tableReadDataKeys.map((headItem, index) => (React.createElement("div", { key: headItem.title + index, className: title + '_' + headItem.title, style: {
            flex: headItem.space,
            ...styles?.bodyItem,
            overflow: 'hidden',
            maxWidth: headItem.maxWidth,
            cursor: title == 'attend' ? 'unset' : 'pointer',
        }, onClick: e => {
            if (title !== 'attend') {
                dispatch(updateMenu('myRoom', {
                    menu: title === 'test' ? 'test' : 'assignment',
                    detail: 'detail',
                }, item.test_id));
            }
        } }, renderTextContent(headItem, item, title))));
    const renderEmpty = useMemo(() => {
        if (testData.length === 0) {
            return React.createElement(EmptyTable, { title: t('assignment.noSubmitInfo') });
        }
    }, [testData.length]);
    return (React.createElement("div", { ref: statusTableRef, className: "attend-container" },
        !isMobile && React.createElement("div", { className: "label" }, t(`assignment.${title}Title`)),
        React.createElement("div", { className: "frame-987334" },
            React.createElement(StatusBox, { title: title, color: color, percentage: formatNumber((done / totalCount) * 100), totalCount: totalCount, done: done, noData: testData.length === 0 }),
            React.createElement(StatusTableStyled, { "$isDesktop": isDesktop, "$isMobile": isMobile, "$isTablet": isTablet },
                React.createElement(Table, { className: "report-list-001", headTitleList: headTitleList, isInifinite: true, data: testData, page: 0, totalCount: totalCount, renderEmpty: renderEmpty, renderItem: renderItem, forceShowheader: isMobile ? false : true })))));
});
export default StatusTable;
