import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../../Colors';
import { Button, Icon, Table } from '../../../../../common';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { useAssignmentList } from '../../../../../server';
import { TOGGLE_POPUP } from '../../../../base/popup';
import { DSTP001_Regist } from '../../../DSTL001';
import { AssignmentTable } from '../../../DSTL001/component/DSTL001_List_Child';
import { TestSetting } from './TestSetting';
const TestSettingStyled = styled.div `
	width: 100%;
	${props => props.$isMobile &&
    props.$isTablet &&
    css `
			flex: 1;
			height: 100%;
		`}

	display: flex;
	flex-direction: column;

	.live-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		height: 516px;
		position: relative;
		overflow: hidden;
		flex: 1;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			align-self: stretch;

			color: var(--color-grey, #666666);
			text-align: left;
			font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.underbar_line {
		cursor: pointer;
		color: var(--color-accent, #e7424c);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 14px;
		line-height: 28px;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		width: 38px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	${props => (props.$isMobile ? mobileSytles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileSytles = css `
	gap: 6px;
	.live-list-002 {
		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 0px 0px;
			padding: 12px;
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;
			justify-content: space-between;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
			flex: 1;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			&:nth-child(1) {
				border-width: 0px;
			}
			&:nth-last-child(1) {
				border-width: 0px;
			}
		}
	}

	.bold {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		align-self: stretch;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.underbar_line {
		text-align: end;
		width: 100%;
	}

	.btn-container {
		width: 100%;
		padding-bottom: 4px;
		.sm {
			flex: 1;
		}
	}
`;
const tabletStyles = css `
	gap: 13px;
	.live-list-002 {
		.item {
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
			position: relative;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
`;
const desktopStyles = css `
	gap: 13px;
	.test-setting-list {
		min-height: 180px;
	}

	.live-list-002 {
		.item {
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
			position: relative;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
`;
const COUNT = 10;
export const TestSettingTable = React.memo(({ isDetail = false, isModify = false, conference_id, item, choice, setItem, setChoice, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet, classesId } = useGlobalState();
    const [load, setLoad] = useState(false);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const { data, refetch } = useAssignmentList({
        assignment: false,
        id: choice,
        class_id: classesId,
    });
    useEffect(() => {
        if (choice.length > 0) {
            refetch();
        }
    }, [choice]);
    useEffect(() => {
        if (data) {
            const { total_count, items } = data;
            if (total_count > 0) {
                setItem(items.map(i => i.id));
                setTestData(items);
                setTotalCount(total_count);
                return;
            }
        }
        if (item.length === 0) {
            setTestData([]);
            setTotalCount(0);
        }
    }, [data]);
    const { headTitleList, tableReadDataKeys } = useMemo(() => ({
        headTitleList: [
            { title: 'testName', space: 3, sort: false, maxWidth: 250 },
            ...(isDetail ? [] : [{ title: 'etc', space: 1, sort: false }]),
        ],
        tableReadDataKeys: [
            { title: 'title', space: 3, sort: false, maxWidth: 250 },
            ...(isDetail ? [] : [{ title: 'etc', space: 1, sort: false }]),
        ],
    }), [isDetail]);
    const openAddSetting = () => {
        if (item.length >= 1) {
            alert('테스트 등록은 하나만 가능합니다.');
            return;
        }
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DSTP001_Regist,
            componentProps: {
                isTest: true,
                isReserve: true,
                setUpdateCheckList: setChoice,
                isLive: true,
                isPopup: true,
            },
            width: 757,
            title: '테스트 등록',
        });
    };
    const openAssignment = () => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: AssignmentTable,
            componentProps: {
                isTest: true,
                isReserve: true,
                isTeacher: true,
                defaultCheckList: testData.map(i => i.id),
                setUpdateCheckList: setChoice,
                conference_id,
                isReserveRegist: !isModify,
            },
            width: 757,
            title: t('conference.testDownload'),
        });
    };
    const deleteCheckList = (id) => {
        setChoice(prev => prev.filter(itemId => itemId !== id));
        setItem(prev => prev.filter(itemId => itemId !== id));
        setTestData(prev => prev.filter(i => i.id !== id));
    };
    const renderTitle = useMemo(() => (React.createElement(TestSetting, { tableName: t('conference.testSetting') }, !isDetail && (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: "btn_default sm", text: t('conference.enroll'), onClick: openAddSetting }),
        React.createElement(Button, { className: "btn_default sm", text: isMobile
                ? t('conference.testAdd')
                : t('conference.testDownload'), onClick: openAssignment }))))), [testData, isDetail, isModify, item]);
    const renderItem = (item, styles) => tableReadDataKeys.map(headItem => (React.createElement("div", { key: headItem.title, style: {
            flex: headItem.space,
            maxWidth: headItem?.maxWidth,
            ...styles?.bodyItem,
            overflow: 'hidden',
        } }, headItem.title === 'etc' ? (React.createElement("p", { className: "underbar_line", onClick: () => deleteCheckList(item.id) }, t('common.delete'))) : (React.createElement("p", { className: "overText" }, item[headItem.title])))));
    const renderEmpty = useMemo(() => (React.createElement("div", { className: "empty_data", style: { color: Colors.light_grey } },
        React.createElement(Icon, { icon: "detail-delete", size: 36, fill: Colors.w_grey }),
        React.createElement("p", null, "\uB4F1\uB85D\uB41C \uD14C\uC2A4\uD2B8\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4."))), []);
    return (React.createElement(TestSettingStyled, { "$isTablet": isTablet, "$isMobile": isMobile, className: "user-list test-setting-list" },
        React.createElement(Table, { className: "live-list-002", forceShowheader: !isMobile, showCheckBox: false, showIndex: !isMobile, isInifinite: true, data: testData, totalCount: totalCount, page: 0, showCount: COUNT, sortDefault: "id", headTitleList: headTitleList, renderTitle: renderTitle, renderItem: renderItem, renderEmpty: isDetail && totalCount <= 0 && renderEmpty })));
});
