import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { CONTENTS_TABS } from '../constants';
const ContentTabStyled = styled.div `
	&.content_tab {
		background: #ffffff;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		padding: 12px 0px 0px 0px;
		display: flex;
		flex-direction: row;
		gap: 48px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.content-tab-item {
			flex: 1;
			padding: 0px 0px 12px 0px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			cursor: pointer;

			&.on {
				border-style: solid;
				border-color: var(--tur-d, #479da6);
				border-width: 0px 0px 2px 0px;

				.tab-text {
					color: var(--tur-d, #479da6);
					font-weight: var(--b4-font-weight, 700);
					font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
				}
			}

			.tab-text {
				color: var(--color-black, #101010);
				text-align: center;
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}
		}
	}

	${({ $isTablet, $isMobile }) => $isMobile
    ? css `
					.content-tab-item {
						.tab-text {
							font-size: var(--b4-font-size, 14px);
							line-height: var(--b4-line-height, 22px);
						}
					}
			  `
    : $isTablet
        ? css `
					.content-tab-item {
						.tab-text {
							font-size: var(--b4-font-size, 14px);
							line-height: var(--b4-line-height, 22px);
						}
					}
			  `
        : css `
					.content-tab-item {
						.tab-text {
							font-size: var(--b3-font-size, 16px);
							line-height: var(--b3-line-height, 32px);
						}
					}
			  `}
`;
export const ContentTab = React.memo(({ menu, setMenu }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = useGlobalState();
    const handlerContentTab = (menu) => {
        setMenu(menu);
    };
    return (React.createElement(ContentTabStyled, { className: "content_tab", "$isTablet": isTablet, "$isMobile": isMobile }, CONTENTS_TABS.map(tab => (React.createElement("div", { key: tab.key, className: `content-tab-item ${menu === tab.key ? 'on' : 'off'}`, onClick: () => handlerContentTab(tab.key) },
        React.createElement("p", { className: "tab-text" }, t(tab.text)))))));
});
