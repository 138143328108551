import { throttle } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { Colors } from '../../../../Colors';
import { EmptyTable } from '../../../../common/option';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { useAnswerList } from '../../../../server';
import { ErrataTable } from '../../../DST/DSTL001/component/DSTL001_View_Child';
import { AttachmentQuestionTable } from './AttachmentQuestionTable';
import { DetailQuestionTable } from './DetailQuestionTable';
import { SurveyQuestionTable } from './SurveyQuestionTable';
const SubmissionPopupStyled = styled.div `
	border-width: ${({ $isDesktop }) => (!$isDesktop ? '0' : ' 0px 0px 1px 0px;')};
	border-style: solid;
	border-color: var(--color-grey-w, #b6bac0);

	${({ $isMobile }) => $isMobile &&
    `
		width: 100%;
		height: 100%;
	`}

	${({ $isDesktop }) => $isDesktop &&
    `
	.empty_data {
		flex: unset;
		height: 240px;
	}
	`}

	.txt {
		color: var(--color-black, #101010);
		text-align: right;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 22px);
		letter-spacing: var(--b4-letter-spacing, -0.02em);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		min-height: fit-content;
		${({ $isDesktop }) => !$isDesktop &&
    `
			width: 100%;
			justify-content: center;
			padding-bottom: 12px;
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;

		`}

		.txt-span4 {
			color: var(--color-black, #101010);
			font-family: 'Noto Sans KR', sans-serif;
			font-size: ${({ $isDesktop }) => $isDesktop ? ` var(--b3-font-size, 16px)` : `var(--b4-font-size, 14px)`};
			line-height: ${({ $isDesktop }) => $isDesktop ? `var(--b3-line-height, 32px)` : `var(--b4-line-height, 22px)`};
			letter-spacing: var(--b4-letter-spacing, -0.02em);
			font-weight: var(--b4-font-weight, 400);
		}

		.txt-span5 {
			color: var(--color-black, #101010);
			font-family: 'Noto Sans KR', sans-serif;
			font-size: ${({ $isDesktop }) => $isDesktop ? ` var(--b3-font-size, 16px)` : `var(--b4-font-size, 14px)`};
			line-height: ${({ $isDesktop }) => $isDesktop ? `var(--b3-line-height, 32px)` : `var(--b4-line-height, 22px)`};
			letter-spacing: var(--b4-letter-spacing, -0.02em);
			font-weight: var(--b4-font-weight, 700);
		}
	}
`;
export const SubmissionPopup = React.memo(({ test_id, member_id, item, props, isClosed, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [isSubmit, setIsSubmit] = React.useState(false);
    const { data: answerData, refetch: answerRefetch } = useAnswerList({
        test_id,
        from: member_id,
        orderby: 'value',
    });
    const refetchList = throttle(answerRefetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        member_id && test_id && refetchList();
    }, [member_id, test_id, isSubmit]);
    useEffect(() => {
        if (!answerData)
            return;
        if (answerData.found_count > 0) {
            setIsSubmit(true);
        }
        else {
            setIsSubmit(false);
        }
    }, [answerData]);
    const type = React.useMemo(() => item?.type, [item?.type]);
    const renderContent = () => {
        if (!item)
            return null;
        if (item.status === 'created' || (item.status === 'closed' && !isSubmit)) {
            const title = item.status === 'created'
                ? t('assignment.notOpen', {
                    name: t(`assignment.${props.isTest ? 'test' : 'name'}`),
                })
                : t('assignment.notSubmit');
            return (React.createElement(EmptyTable, { title: title, iconProps: {
                    icon: 'detail-delete',
                    size: isMobile ? 28 : 32,
                    fill: Colors.w_grey,
                } }));
        }
        else {
            switch (type) {
                case '설문형':
                    return (React.createElement(SurveyQuestionTable, { ...props, item: item, isTest: item.assignment ? false : true, isOpen: item?.status === 'open' ? true : false, answerData: answerData, isSubmit: isSubmit }));
                case '파일첨부형':
                    return (React.createElement(AttachmentQuestionTable, { ...props, item: item, isTest: item.assignment ? false : true, isOpen: item?.status === 'open' ? true : false, answerData: answerData, isSubmit: isSubmit }));
                default:
                    if (isSubmit) {
                        return (React.createElement(ErrataTable, { ...props, item: item, isTest: item.assignment ? false : true, isOpen: item?.status === 'open' ? true : false, isSubmit: isSubmit }));
                    }
                    return (React.createElement(DetailQuestionTable, { ...props, item: item, isOpen: item?.status === 'open' ? true : false, subject_name: props.subject_name, isSubmit: isSubmit, isTest: item.assignment ? false : true }));
            }
        }
    };
    return (React.createElement(SubmissionPopupStyled, { className: "frame-987345", "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop },
        isDesktop && isClosed && (React.createElement("div", { className: "title" }, t('assignment.detailQuestions'))),
        !item.assignment && (React.createElement("div", { className: "txt" },
            React.createElement("span", null,
                React.createElement("span", { className: "txt-span5" }, "\uD14C\uC2A4\uD2B8 "),
                React.createElement("span", { className: "txt-span5" }, "\uC751\uC2DC\uB294 LIVE \uAC15\uC758\uC2E4"),
                React.createElement("span", { className: "txt-span5" }, "\uC5D0\uC11C \uAC00\uB2A5\uD569\uB2C8\uB2E4.")),
            isDesktop && React.createElement("div", { className: "" }))),
        item && renderContent(),
        isSubmit && React.createElement("div", { className: "status-panel" })));
});
