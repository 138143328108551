import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Colors } from '../../../../Colors';
import { DoughnutChart } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
const StatusBoxStyled = styled.div `
	display: flex;
	flex-direction: row;
	gap: 32px;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	position: relative;
	width: 100%;

	.status {
		${({ $isMobile }) => $isMobile
    ? `
			border-radius: 10px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		`
    : `
		display: flex;
		flex-direction: row;
		gap: 32px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;`}
	}

	.info {
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		.round {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			flex-shrink: 0;
			width: 90px;
			position: relative;

			.label2 {
				text-align: left;
				font-family: 'Noto Sans KR', sans-serif;
				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}

			.value2 {
				text-align: right;
				font-family: 'Noto Sans KR', sans-serif;
				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
				position: relative;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}
	}

	.status-panel {
		border-radius: 8px;
		padding: 20px 0px 20px 0px;
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.entire {
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			width: 140px;
			position: relative;
		}

		.label2 {
			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b1-font-family, 'Noto Sans KR', sans-serif);
			font-size: ${props => props.$isMobile || props.$isTablet
    ? 'var(--b3-font-size, 16px)'
    : 'var(--b1-font-size, 20px)'};
			line-height: ${props => props.$isMobile || props.$isTablet
    ? 'var(--b3-line-height, 32px)'
    : 'var(--b1-line-height, 42px)'};
			letter-spacing: var(--b1-letter-spacing, -0.02em);
			font-weight: var(--b1-font-weight, 400);
			position: relative;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		.value2 {
			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--h2-font-family, 'Noto Sans KR', sans-serif);
			font-size: ${props => props.$isMobile || props.$isTablet
    ? 'var(--h4-font-size, 20px)'
    : 'var(--h2-font-size, 32px)'};
			line-height: ${props => props.$isMobile || props.$isTablet
    ? 'var(--h4-line-height, 32px)'
    : 'var(--h2-line-height, 46px)'};
			letter-spacing: var(--h2-letter-spacing, -0.02em);
			font-weight: var(--h2-font-weight, 700);
			position: relative;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.divider {
			background: #d9d9d9;
			align-self: stretch;
			flex-shrink: 0;
			width: 1px;
			position: relative;
			height: 100%;
		}
	}
`;
export const StatusBox = React.memo(({ title, color, percentage, totalCount, done, noData, }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const renderDoughnutChart = useMemo(() => {
        return (React.createElement(DoughnutChart, { percentage: noData ? '-' : percentage, size: isMobile ? 112 : isTablet ? 120 : 156, fontSize: !isDesktop && 20, fill: color, isHome: false }));
    }, [percentage, noData, color]);
    if (isMobile) {
        return (React.createElement(StatusBoxStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop },
            React.createElement("div", { className: "status" },
                renderDoughnutChart,
                React.createElement("div", { className: "info" },
                    React.createElement("div", { className: "round" },
                        React.createElement("div", { className: "label2" }, "\uCD1D \uD68C\uCC28"),
                        React.createElement("div", { className: "value2" },
                            noData ? '-' : `${totalCount}회`,
                            " ")),
                    React.createElement("div", { className: "round", style: { color: Colors.p_ture } },
                        React.createElement("div", { className: "label2" }, title === 'reply'
                            ? '시청'
                            : title === 'attend'
                                ? '출석'
                                : '응시'),
                        React.createElement("div", { className: "value2" },
                            noData ? '-' : `${done}회`,
                            " ")),
                    React.createElement("div", { className: "round", style: { color: Colors.accent } },
                        React.createElement("div", { className: "label2" }, title === 'reply'
                            ? '미시청'
                            : title === 'attend'
                                ? '미출석'
                                : '미응시'),
                        React.createElement("div", { className: "value2" }, noData ? '-' : `${totalCount - done}회`))))));
    }
    return (React.createElement(StatusBoxStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop },
        React.createElement("div", { className: "status" }, renderDoughnutChart),
        React.createElement("div", { className: "status-panel" },
            React.createElement("div", { className: "entire" },
                React.createElement("div", { className: "label2" }, "\uCD1D \uD68C\uCC28"),
                React.createElement("div", { className: "value2" },
                    noData ? '-' : `${totalCount}회`,
                    " ")),
            React.createElement("div", { className: "divider" }),
            React.createElement("div", { className: "entire" },
                React.createElement("div", { className: "label2" }, title === 'reply' ? '시청' : title === 'attend' ? '출석' : '응시'),
                React.createElement("div", { className: "value2" },
                    noData ? '-' : `${done}회`,
                    " ")),
            React.createElement("div", { className: "divider" }),
            React.createElement("div", { className: "entire" },
                React.createElement("div", { className: "label2" }, title === 'reply'
                    ? '미시청'
                    : title === 'attend'
                        ? '미출석'
                        : '미응시'),
                React.createElement("div", { className: "value2" }, noData ? '-' : `${totalCount - done}회`)))));
});
