import React from 'react';
import { styled } from 'styled-components';
import { Icon } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { QnAListTable } from '../DTST004_List_Child';
const QnAHistoryTableStyled = styled.div `
	&.qna-history-table {
		width: 100%;
		border-radius: 12px;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 1px;
		padding: ${props => (props.$isMobile ? '8px 16px' : '16px 28px 16px 28px')};
		display: flex;
		flex-direction: column;
		gap: 8px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		box-shadow: ${props => props.$isMobile
    ? 'var(--shadow-silver-box-shadow, 2px 4px 10px 0px rgba(213, 217, 232, 0.1))'
    : 'var(--shadow-silver-box-shadow, 2px 4px 10px 0px rgba(213, 217, 232, 0.4))'};

		.title {
			padding: 10px 0px 10px 0px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			cursor: pointer;

			.label {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--h5-font-family, 'Noto Sans KR', sans-serif);
				font-size: var(--h5-font-size, ${props => (props.$isMobile ? '14px' : '20px')});
				line-height: var(--h5-line-height, 24px);
				letter-spacing: var(--h5-letter-spacing, -0.02em);
				font-weight: var(--h5-font-weight, 700);
				position: relative;
				flex: 1;
			}
		}
	}

	.qna-open-contents {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		${({ $isDesktop }) => !$isDesktop &&
    `
                border-style: dashed;
                border-color: var(--color-grey-w, #b6bac0);
                border-width: 1px 0px 0px 0px;
        `}
	}
`;
export const QnAHistoryTable = React.memo(({ isTeacher, filterItems, root_id, }) => {
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const [isExpanded, setIsExpanded] = React.useState(false);
    return (React.createElement(QnAHistoryTableStyled, { className: "qna-history-table", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile, "$isExpanded": isExpanded },
        React.createElement("div", { className: "title", onClick: () => setIsExpanded(!isExpanded) },
            React.createElement("div", { className: "label" }, "\uC9C8\uBB38\uC774\uB825 \uD655\uC778\uD558\uAE30"),
            React.createElement(Icon, { className: isExpanded ? 'arrow-up' : 'arrow-down', icon: "arrow", size: 24 })),
        isExpanded && (React.createElement("div", { className: "qna-open-contents" },
            React.createElement(QnAListTable, { filterItems: filterItems, isTeacher: isTeacher, isRetry: false, isHome: false, id: root_id, isPopUp: false, isHistory: true, hideDetail: false })))));
});
