import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button } from '../../common';
import { useGlobalState } from '../../GlobalStateProvider';
import { MyPageDropDown } from '../base/account';
import { appWillLoad, appWillUnmount } from '../base/app';
import { goToPrevMenu, updateMenu } from '../menu/action';
import { getMenuData } from '../menu/function';
import { AdminContent } from './AdminContent';
import { AdminMenu } from './AdminMenu';
import { AdminMenuDetail } from './AdminMenuDetail';
export const AdminMain = React.memo(() => {
    const dispatch = useDispatch();
    const { isMobile, isTablet } = useGlobalState();
    const load = useSelector((state) => state['base/app'].isLoad);
    const { depths, menu, props } = useSelector((state) => getMenuData(state), shallowEqual);
    useEffect(() => {
        if (!load)
            dispatch(appWillLoad());
        return () => {
            dispatch(appWillUnmount());
        };
    }, []);
    const handlerChangeMenu = useCallback((data) => {
        dispatch(updateMenu(depths, { menu: menu.menu, detail: data.showDepth }, undefined, data.props));
    }, [depths, menu.menu]);
    useEffect(() => {
        APP.eventManager.subscribe('CHANGE_MENU', handlerChangeMenu);
        return () => {
            APP.eventManager.unsubscribe('CHANGE_MENU', handlerChangeMenu);
        };
    }, [handlerChangeMenu]);
    const handlerPreviousMenu = () => {
        dispatch(goToPrevMenu());
    };
    return (React.createElement("div", { className: `admin-container ${isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'}  ` },
        React.createElement("div", { className: "admin-sidebar" },
            React.createElement(Button, { className: "btn-logo", option: {
                    buttonBeforeIcon: {
                        show: true,
                        width: 98,
                        height: 35,
                        name: 'logo_white',
                    },
                }, onClick: () => {
                    dispatch(updateMenu(depths, {
                        menu: 'serviceManagment',
                        detail: 'list',
                    }));
                } }),
            React.createElement(AdminMenu, { menu: depths, menuDetail: menu.menu, setMenuDetail: changeMenu => dispatch(updateMenu(depths, { menu: changeMenu, detail: 'list' })) })),
        React.createElement("div", { className: "admin-content" },
            React.createElement("div", { className: "admin-menu-detail" },
                React.createElement(AdminMenuDetail, { menu: depths, setMenu: changeDepth => {
                        const menu = changeDepth === 'backOffice' ? 'assignment' : changeDepth;
                        dispatch(updateMenu(changeDepth, { menu, detail: 'list' }));
                    } }),
                React.createElement(MyPageDropDown, { isSidebar: false })),
            React.createElement(AdminContent, { menu: depths, menuDetail: menu.menu, showDepth: menu.detail, props: props, handlerPreviousMenu: handlerPreviousMenu }))));
});
