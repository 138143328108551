import throttle from 'lodash/throttle';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../Colors';
import { Button, Icon } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { postSubmitAnswer, useQuestionList } from '../../../../server';
import { getIsTeacher } from '../../../base/account';
import { SurveyLayout } from '../../../base/layout/Survey';
const DetailQuestionStyled = styled.div `
	& {
		padding-bottom: 20px;
	}

	.label {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h5-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 24px);
		letter-spacing: var(--h5-letter-spacing, -0.02em);
		font-weight: var(--h5-font-weight, 700);
		position: relative;
		align-self: stretch;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;
	height: 100%;

	/* .fext-field {
		height: 100%;
		overflow: hidden;
	} */

	.task-label {
		width: 100%;
	}

	.no-question-wrap {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b1-font-family, 'Noto Sans KR', sans-serif);
				font-size: 18px;
				line-height: 150%;
				letter-spacing: -0.02em;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}

	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 6px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.input_answer_wrapper {
				background: #ffffff;
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 4px 10px 4px 10px;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			.body-item {
				width: 100%;
			}

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b4-font-size, 12px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}
	}
	.input_point_wrapper {
		background: #ffffff;
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 10px;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: center;
		position: relative;
		max-width: 80px;
		height: 38px;

		input {
			width: 100%;
			outline: none !important;
			border: none !important;
			padding: 0 !important;
			background: transparent;
		}
	}

	.detail_txt {
		color: var(--color-grey, #666666);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 16px;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
	}

	.subject-item-wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.subject-item-title {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 600;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.omr-point-wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}

	.submission-header {
		gap: 24px;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
`;
const desktopStyles = css `
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 21px;
	align-items: flex-end;
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	position: relative;
	${props => props.$isDesktop &&
    `
		padding-top: 20px;
		border-top: 1px solid #e6e8ed;
	`}

	/* .fext-field {
		height: 100%;
		overflow: hidden;
	} */

	.task-label {
		width: 100%;
	}

	.no-question-wrap {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b1-font-family, 'Noto Sans KR', sans-serif);
				font-size: var(--b1-font-size, 20px);
				line-height: var(--b1-line-height, 42px);
				font-weight: var(--b1-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}

	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.input_answer_wrapper {
				background: #ffffff;
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 4px 10px 4px 10px;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}
	}
	.input_point_wrapper {
		background: #ffffff;
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 4px 10px 4px 10px;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;

		input {
			width: 100%;
			outline: none !important;
			border: none !important;
			padding: 0 !important;
			background: transparent;
		}
	}

	.detail_txt {
		color: var(--color-grey, #666666);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 16px;
		// line-height: 32px;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
	}
`;
const count = 100;
export const SurveyQuestionTable = React.memo(({ item, isTest, isSubmit, isOpen, test_id, answerData, }) => {
    const { isDesktop, isMobile, isTablet } = useGlobalState();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const [surveyData, setSurveyData] = useState([]);
    const [changeSurveyData, setChangeSurveyData] = useState([]);
    const [submittedSurveyData, setSubmittedSurveyData] = useState([]);
    const submitAnswerMutation = useMutation(postSubmitAnswer);
    const { data, refetch } = useQuestionList({
        test_id,
        offset: 0,
        limit: 100,
        orderby: ['number'],
    });
    const throttledRefetch = throttle(refetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        throttledRefetch();
    }, [test_id, isSubmit]);
    useEffect(() => {
        if (surveyData.length === 0)
            return;
        if (!isSubmit)
            return;
        if (answerData) {
            const { items } = answerData;
            const newSurveyData = surveyData.map(survey => {
                const answers = items.filter((a) => a.question_id === survey.id); // Filter answers for the current survey question
                if (answers.length > 0) {
                    if (survey.type === 'choice') {
                        const newSurvey = { ...survey };
                        newSurvey.data = survey.data.map(option => {
                            const selected = answers.some(answer => answer.value === option.title);
                            return { ...option, checked: selected };
                        });
                        return newSurvey;
                    }
                    else if (survey.type === 'checkbox') {
                        const newSurvey = { ...survey };
                        // For checkboxes, multiple answers can be checked
                        newSurvey.data = survey.data.map(option => {
                            const selected = answers.some(answer => answer.value === option.title);
                            return { ...option, checked: selected };
                        });
                        return newSurvey;
                    }
                    else {
                        // For other types, we just assign the single answer value
                        return { ...survey, data: answers[0].value };
                    }
                }
                return survey; // If no answer is found, return the survey unchanged
            });
            setSubmittedSurveyData(newSurveyData);
        }
    }, [answerData, isSubmit, surveyData]);
    useEffect(() => {
        if (data) {
            const { survey_questions } = item;
            const combinedArray = data.items.map(i => {
                const question = survey_questions[i.id];
                return {
                    id: i.id,
                    type: i.type,
                    title: question ? question.title : null,
                    data: question
                        ? question.options && question.options.length > 0
                            ? i.type == 'choice' || i.type == 'checkbox'
                                ? question.options.map((option, optionIndex) => ({
                                    id: `${i.id}-${optionIndex}`,
                                    title: option,
                                    checked: false,
                                }))
                                : ''
                            : ''
                        : '',
                };
            });
            // Sort the final array by the 'number' field
            combinedArray.sort((a, b) => a.number - b.number);
            // console.log(combinedArray);
            setSurveyData(combinedArray);
        }
    }, [data]);
    const onSubmit = async () => {
        try {
            let submitData = [];
            for (const survey of changeSurveyData) {
                if (survey.type === 'choice') {
                    const selectedOptions = survey.data.filter(option => option.checked);
                    if (selectedOptions.length === 0) {
                        alert('모든 항목에 답변해주세요.');
                        return;
                    }
                    submitData.push({
                        question_id: survey.id,
                        values: selectedOptions.map(option => option.title),
                    });
                }
                if (survey.type === 'checkbox') {
                    const selectedOptions = survey.data.filter(option => option.checked);
                    if (selectedOptions.length === 0) {
                        alert('모든 항목에 답변해주세요.');
                        return;
                    }
                    // push every single selected option as one data
                    selectedOptions.forEach(option => {
                        submitData.push({
                            question_id: survey.id,
                            values: [option.title],
                        });
                    });
                }
                if (survey.type === 'short' || survey.type === 'long') {
                    if (survey.data === '') {
                        alert('모든 항목에 답변해주세요.');
                        return;
                    }
                    if (survey.data.length > 500) {
                        alert('장문형, 단답형은 500자 이내로 작성해주세요.');
                        return;
                    }
                    submitData.push({
                        question_id: survey.id,
                        values: [survey.data],
                    });
                }
            }
            if (submitData && submitData.length === 0) {
                alert('모든 항목에 답변해주세요.');
                return;
            }
            await submitAnswerMutation.mutateAsync({
                id: test_id,
                props: {
                    answers: submitData,
                    subject_items: [],
                },
            });
            APP.eventManager.publish('ANSWER_REFETCH');
        }
        catch (error) {
            alert(error);
        }
    };
    return (React.createElement(DetailQuestionStyled, { "$isDesktop": isDesktop, "$isMobile": isMobile, "$isTablet": isTablet, className: "field-global-001 detail-question-wrap", style: { overflow: 'auto' } },
        React.createElement("div", { className: `flex column ${!isTeacher ? 'survey_wrapper' : 'survey_wrapper border'}` },
            !isSubmit && React.createElement("p", { className: "label" }, "\uC124\uBB38\uC5D0 \uCC38\uC5EC \uD574\uC8FC\uC138\uC694."),
            React.createElement(SurveyLayout, { surveyData: isSubmit ? submittedSurveyData : surveyData, isSubmit: item.status === 'closed' ? (isTeacher ? true : isSubmit) : isSubmit, isRegist: false, setSurveyData: setChangeSurveyData }),
            isTest
                ? null
                : !isMobile &&
                    isOpen &&
                    !isSubmit && (React.createElement("div", { className: "btn-container", style: { textAlign: 'right' } },
                    React.createElement(Button, { className: "btn_default xmd", text: "common.submit", onClick: onSubmit }))),
            isMobile && !isSubmit && (React.createElement("div", { className: "btn-container", style: { textAlign: 'right' } },
                React.createElement("div", { className: "submission-header" }, isTest
                    ? null
                    : isOpen &&
                        !isSubmit && (React.createElement(Button, { className: "btn_grey full", text: "common.submit", onClick: onSubmit }))))),
            isSubmit && (React.createElement("div", { className: "final-notification" },
                React.createElement("div", { className: "line" }),
                React.createElement("div", { className: "message" },
                    React.createElement(Icon, { icon: "check-circle", size: 24, fill: Colors.pink }),
                    React.createElement("div", { className: "div" }, "\uC81C\uCD9C\uC774 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.")),
                React.createElement("div", { className: "line" }))))));
});
