import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from '../../../../Colors';
import { Button, CheckBoxElement, Icon } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { deleteQna, getQnaList, modifyQna } from '../../../../server';
import { objectToQueryString } from '../../../../server/functions';
import { getIsTeacher, getUserId } from '../../../base/account';
import { DeletePopUpLayout } from '../../../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../../../base/popup';
import { goToPrevMenu, updateMenu } from '../../../menu/action';
import { QnAContentsHead } from './QnAContentsHead';
import { QnAContentsStyled } from './qnaContentsStyled';
export const QnAContents = React.memo(({ data, number, totalCount, depths, isQuestion = false, isHistory = false, isCloseAnswer = false, questionData, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isTablet, isMobile } = useGlobalState();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const localId = useSelector((state) => getUserId(state));
    const { root_id, id, author_id, status, type } = useMemo(() => data, [data]);
    const isMyAuthor = useMemo(() => author_id === localId, [author_id, localId]);
    const [checkImportance, setCheckImportance] = React.useState(data?.importance);
    const modifyQnaMutation = useMutation(modifyQna);
    const deleteQnaMutation = useMutation(deleteQna);
    const previousPage = () => {
        dispatch(goToPrevMenu());
    };
    const renderRequestionBtn = useMemo(() => {
        const handlerRequestion = () => {
            dispatch(updateMenu(undefined, { menu: 'qna', detail: 're_regist' }, data.root_id, {
                isReQuestion: true,
                isTeacher,
                isPopup: false,
                isModify: false,
                depths,
                root_id: data.root_id,
            }));
        };
        return (React.createElement(Button, { className: "btn_default xmd", text: "supportRoom.requestion", onClick: () => handlerRequestion() }));
    }, [isTablet, isMobile, data]);
    const renderDelBtn = useMemo(() => {
        const handleDelete = (type) => {
            if (isTeacher && type === 'question') {
                APP.eventManager.publish(TOGGLE_POPUP, {
                    component: DeletePopUpLayout,
                    componentProps: {
                        deleteContent: t('supportRoom.questionAndAnswerDelete', { count: 1 }),
                        onDelete: async () => {
                            // 전체 질문 삭제
                            try {
                                APP.eventManager.publish('SHOW_LOADING', true);
                                await deleteQnaMutation.mutateAsync(questionData.id);
                                previousPage();
                                APP.eventManager.publish('REFETCH_QNA_LIST');
                            }
                            catch (error) {
                                alert(error);
                            }
                            finally {
                                APP.eventManager.publish('SHOW_LOADING', false);
                            }
                        },
                    },
                    width: 639,
                    title: t(`supportRoom.${type}Delete`),
                    isFit: true,
                });
            }
            else {
                APP.eventManager.publish(TOGGLE_POPUP, {
                    component: DeletePopUpLayout,
                    componentProps: {
                        deleteContent: t('assignment.delteInto', { count: 1 }),
                        onDelete: async () => {
                            try {
                                APP.eventManager.publish('SHOW_LOADING', true);
                                await deleteQnaMutation.mutateAsync(id);
                                previousPage();
                                APP.eventManager.publish('REFETCH_QNA_LIST');
                            }
                            catch (error) {
                                alert(error);
                            }
                            finally {
                                APP.eventManager.publish('SHOW_LOADING', false);
                            }
                        },
                    },
                    width: 639,
                    title: t(`supportRoom.${type}Delete`),
                    isFit: true,
                });
            }
        };
        return (React.createElement(Button, { className: "btn_line_pink xmd", text: "common.delete", onClick: () => handleDelete(type) }));
    }, [type, id, isTablet, questionData, t]);
    const renderModifyBtn = useMemo(() => {
        //질문, 답변 수정
        const handleEditPage = async (edit_id) => {
            dispatch(updateMenu(undefined, { menu: 'qna', detail: !isQuestion ? 'answer_modify' : 'regist' }, isTeacher ? root_id : edit_id, {
                answer_number: Math.max(0, number - 1),
                isReQuestion: root_id !== id,
                depths,
                isModify: true,
                root_id: data.root_id,
            }));
        };
        return (React.createElement(Button, { className: "btn_white xmd", text: "common.modify", onClick: () => handleEditPage(id) }));
    }, [id, depths, isTablet, isMobile, isQuestion, number, root_id, status]);
    const renderLock = useMemo(() => {
        return React.createElement(Icon, { icon: "locked", fill: Colors.light_grey });
    }, []);
    const renderImportanceCheckBox = useMemo(() => {
        const handleImportance = async (id, checked) => {
            if (data) {
                getQnaList(objectToQueryString({ importance: true, root_only: true })).then(async (res) => {
                    try {
                        const { total_count, items } = res.result;
                        const count = checkImportance ? total_count - 1 : total_count + 1;
                        if (count > 5 && !checkImportance) {
                            alert('필독은 5개까지만 선택 가능합니다.');
                            return;
                        }
                        await modifyQnaMutation.mutateAsync({
                            id: data.id,
                            props: {
                                importance: checkImportance ? false : true,
                            },
                        });
                        setCheckImportance(!checkImportance);
                    }
                    catch (error) {
                        alert(error);
                    }
                    finally {
                        // 간혹 필독 해제시, 리스트가 갱신되지 않는 경우가 있어서 setTimeout 추가
                        setTimeout(() => APP.eventManager.publish('REFETCH_QNA_LIST'), 500);
                    }
                });
            }
        };
        return (React.createElement(CheckBoxElement, { id: "importance", title: "\uD544\uB3C5", checked: checkImportance, handlerCheck: handleImportance, className: "must-read", iconSize: 18 }));
    }, [checkImportance, data?.id]);
    const getButtonVisibility = useCallback((type) => {
        const isLastQuestion = number + 2 === totalCount;
        if (isTeacher) {
            if (type === 'requestion')
                return false;
            if (isQuestion) {
                if (type === 'modify')
                    return false;
                return isLastQuestion;
            }
            else {
                return isLastQuestion;
            }
        }
        else {
            if (type === 'requestion')
                return isMyAuthor && isQuestion && isCloseAnswer && isLastQuestion;
            if (isQuestion) {
                return !isCloseAnswer && isMyAuthor;
            }
            else {
                return false;
            }
        }
    }, [isTeacher, isQuestion, isCloseAnswer, isMyAuthor, totalCount, number]);
    const showRequestionBtn = useMemo(() => getButtonVisibility('requestion'), [getButtonVisibility]);
    const showModifyBtn = useMemo(() => getButtonVisibility('modify'), [getButtonVisibility]);
    const showDelBtn = useMemo(() => getButtonVisibility('delete'), [getButtonVisibility]);
    return (React.createElement(QnAContentsStyled, { className: "question-contents", "$isTablet": isTablet, "$isMobile": isMobile, "$isTeacher": isTeacher, "$isHistory": isHistory, "$isQuestion": isQuestion },
        isMobile ? (React.createElement(React.Fragment, null,
            React.createElement(QnAContentsHead, { data: data, isQuestion: isQuestion, isTeacher: isTeacher },
                React.createElement("div", { className: "test" }, isQuestion && data.private && renderLock)),
            React.createElement("div", { className: "content ql-editor", dangerouslySetInnerHTML: { __html: data.content } }))) : (React.createElement("div", { className: "content-wrap" },
            React.createElement(QnAContentsHead, { data: data, isQuestion: isQuestion, isTeacher: isTeacher },
                React.createElement("div", { className: "test" }, isQuestion && data.private && renderLock)),
            React.createElement("div", { className: "content ql-editor", dangerouslySetInnerHTML: { __html: data.content } }))),
        !isHistory &&
            ((!isQuestion && isTeacher) ||
                showRequestionBtn ||
                showModifyBtn ||
                showDelBtn ||
                data.private) && (React.createElement("div", { className: "edit-bar2" }, (showRequestionBtn || showModifyBtn || showDelBtn) && (React.createElement("div", { className: "btn-container2" },
            !isQuestion &&
                isTeacher &&
                !data.private &&
                renderImportanceCheckBox,
            showRequestionBtn && renderRequestionBtn,
            showModifyBtn && renderModifyBtn,
            showDelBtn && renderDelBtn))))));
});
