import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../../Colors';
import { Button } from '../../../../../common';
import { TEST_STATUS } from '../../constant';
import { DeleteListBtn } from './DeleteListBtn';
const getStatusColor = status => {
    if (status === 'closed')
        return Colors.black;
    if (status === 'open')
        return Colors.tur;
    return Colors.pink;
};
const getStatusColorClass = status => {
    if (status === 'closed')
        return 'btn_line_light_grey';
    if (status === 'open')
        return 'btn_pink';
    return 'btn_line_blue';
};
// 날짜 포맷을 변수로 선언
const dateFormat = 'YYYY-MM-DD';
const dateTimeFormat = 'YYYY-MM-DD, HH:mm';
export const AssignmentItem = React.memo(({ headItem, item, isHome, isTest, isMobile, isTablet, isTeacher, isBackOffice, styles, handlerEdit, }) => {
    const { t } = useTranslation();
    // 공통적으로 사용될 스타일 선언
    const commonTextStyle = styles?.bodyText;
    switch (headItem.title) {
        case 'mobile':
            return (React.createElement("div", { className: "mobile-contents" },
                !isMobile && (React.createElement("div", { className: "mobile-assignment" },
                    React.createElement(Button, { className: "btn_tur mobile-xxs", text: t(`assignment.${item.status}`) }),
                    React.createElement(Button, { className: "btn_default mobile-xxs", text: item.type }))),
                React.createElement("div", { className: "contents" }, item.title),
                React.createElement("div", { className: "divier" }),
                React.createElement("div", { className: "bottom" },
                    isTest && (React.createElement("div", { className: "class-name" },
                        "\uC218\uC5C5\uBA85 - ",
                        item.conference_name)),
                    !isTest ? (React.createElement("div", { className: "date" },
                        React.createElement("p", null,
                            "\uC2DC\uC791\uC77C : ",
                            moment(item.startline).format(dateTimeFormat)),
                        React.createElement("p", null,
                            "\uB9C8\uAC10\uC77C : ",
                            moment(item.deadline).format(dateTimeFormat)))) : (React.createElement("div", { className: "date" },
                        React.createElement("p", null,
                            "\uC2DC\uC791\uC77C : ",
                            moment(item.startline).format(dateFormat),
                            ","),
                        React.createElement("p", null, t('assignment.minutes', { minute: item.duration })))))));
        case 'etc':
            return (React.createElement("div", { className: "flex row", style: {
                    gap: 10,
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                } },
                isMobile && (React.createElement("div", { className: "flex row mobile-assignment", style: { gap: 10 } },
                    React.createElement(Button, { className: "btn_tur mobile-xxs", text: t(`assignment.${item.status}`) }),
                    React.createElement(Button, { className: "btn_default mobile-xxs", text: item.type }))),
                isTeacher && (React.createElement("div", { className: "flex row", style: { gap: 10 } },
                    (isBackOffice || item.status == TEST_STATUS.CREATED) && (React.createElement(Button, { option: {
                            buttonAfterIcon: {
                                show: true,
                                name: 'edit',
                                color: Colors.tur,
                                size: 20,
                            },
                        }, onClick: () => handlerEdit(item.id) })),
                    React.createElement(DeleteListBtn, { isTest: isTest, isOneDel: true, deleteList: [item.id], isBackOffice: isBackOffice })))));
        case 'statusAndTitle':
            return (React.createElement("div", { className: "contents" },
                React.createElement(Button, { className: `${getStatusColorClass(item.status)} status-display`, text: t(`assignment.${item.status}`) }),
                React.createElement("p", { className: "name" }, item.title)));
        case 'type_and_subject_name':
        case 'type_and_test_name':
            return (React.createElement(React.Fragment, null,
                React.createElement("p", { className: "overText2", style: commonTextStyle }, item.type),
                React.createElement("p", { className: "overText2", style: commonTextStyle }, item.title)));
        case 'submitDate':
            const diffTime = isHome ? moment(item.deadline).diff(moment(), 'days') : 0;
            return (React.createElement("p", { className: "overText2", style: {
                    color: diffTime > 0
                        ? Colors.black
                        : diffTime === 0
                            ? Colors.tur
                            : Colors.pink,
                } }, diffTime > 0
                ? `D-${diffTime}`
                : diffTime === 0
                    ? 'D-Day'
                    : t('assignment.end')));
        case 'status':
            return (React.createElement("p", { className: "overText2", style: {
                    color: isBackOffice ? 'black' : getStatusColor(item[headItem.title]),
                } }, isBackOffice
                ? item.status === 'hide'
                    ? '비공개'
                    : '공개'
                : t(`assignment.${item[headItem.title]}${isHome ? 'Home' : ''}`)));
        case 'startline':
            return (!isMobile && (React.createElement("p", { className: "overText2", style: commonTextStyle }, moment(item[headItem.title]).format(dateTimeFormat))));
        case 'deadline':
            if (isMobile) {
                //모바일 화면에서 사용할 시작일, 상태 값
                const startline = moment(item.startline).format(dateTimeFormat);
                if (isTeacher) {
                    return (React.createElement("div", { className: "date-wrap-mobile" },
                        React.createElement("p", null,
                            startline,
                            " ~"),
                        React.createElement("p", null, moment(item[headItem.title]).format(dateTimeFormat))));
                }
                else {
                    return (React.createElement("div", { className: "date-wrap-mobile" },
                        React.createElement("p", null,
                            t('assignment.startDate'),
                            " : ",
                            startline),
                        React.createElement("p", null,
                            t('assignment.endDate'),
                            " :",
                            moment(item[headItem.title]).format(dateTimeFormat))));
                }
            }
            else {
                return (React.createElement("p", { className: "overText2", style: commonTextStyle }, moment(item[headItem.title]).format(dateTimeFormat)));
            }
        case 'duration':
            return isMobile ? (React.createElement("div", { className: "status-wrap-mobile" },
                React.createElement("div", { className: "status-text" },
                    t('assignment.startDate'),
                    " :",
                    moment(item.startline).format(dateFormat),
                    ","),
                React.createElement("div", { className: "status-text" }, t('assignment.minutes', { minute: item[headItem.title] })))) : (React.createElement("p", { className: "overText2", style: commonTextStyle }, t('assignment.minutes', { minute: item[headItem.title] })));
        case 'author_name':
            return isMobile ? (isTeacher ? (React.createElement("div", { className: "status-wrap-mobile" },
                React.createElement("p", { className: "status-text", style: commonTextStyle }, moment(item['headItem.title']).format(dateFormat)),
                React.createElement("p", { className: "status-text" }, item[headItem.title]))) : (React.createElement("div", { className: "status-wrap-mobile" },
                React.createElement("p", { className: "overText2", style: commonTextStyle }, t(`assignment.${item.status}`)),
                React.createElement("p", { className: "status-text" }, item[headItem.title])))) : (React.createElement("p", { className: "overText2", style: commonTextStyle }, item[headItem.title]));
        case 'title':
            return isMobile ? (React.createElement("div", { className: "status-wrap-mobile" },
                !isHome && (React.createElement(Button, { className: "btn_tur mobile-xxs", text: t(`assignment.${item.status}`) })),
                React.createElement("p", { className: "title-text-mobile overText2", style: commonTextStyle }, item[headItem.title]))) : (React.createElement("p", { className: "overText2", style: commonTextStyle }, item[headItem.title]));
        case 'type':
            return !isHome && isTablet ? (React.createElement(React.Fragment, null,
                React.createElement("p", { className: "typeText", style: commonTextStyle }, item[headItem.title]),
                React.createElement("p", { className: "overText2", style: commonTextStyle }, item['title']))) : !isHome && !isTeacher && isMobile ? (React.createElement("div", { className: "status-wrap-mobile" },
                React.createElement("p", { className: "typeText", style: commonTextStyle }, item[headItem.title]))) : (React.createElement("p", { className: "overText2", style: commonTextStyle }, item[headItem.title]));
        default:
            const content = headItem.title === 'creation_time'
                ? moment(item[headItem.title]).format(dateFormat)
                : headItem.title === 'progressdate'
                    ? moment(item.startline).format(dateFormat)
                    : headItem.title === 'duration'
                        ? t('assignment.minutes', { minute: item[headItem.title] })
                        : item[headItem.title];
            return (React.createElement("p", { className: "overText2", style: commonTextStyle }, content));
    }
});
