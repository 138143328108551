import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputElement } from '../../../../../common';
import { AttachFile } from '../DSTP001_Regist_Child';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { useSelector } from 'react-redux';
import { getIsTeacher } from '../../../../base/account';
export const TaskInfoTeacherContainer = ({ isTest, item, component, }) => {
    const { t } = useTranslation();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const assignmentRef = useRef(null);
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [assignmentName, setAssignmentName] = useState(''); // 과제명
    const [className, setClassName] = useState(''); // 수업명
    const [startDate, setStartDate] = useState(''); // 시작일
    const [endDate, setEndDate] = useState(''); // 마감일
    const [duration, setDuration] = useState(0); // 시험 시간
    const [questionType, setQuestionType] = useState(''); // 문제 유형
    const [attachFileList, setAttachFileList] = useState([]); // 첨부파일
    useEffect(() => {
        if (!item)
            return;
        const questionType = item.type === 'OMR (기본형)' || item.type === 'OMR (자유형)'
            ? item.type +
                `${item.subject !== '' ? ' - ' + t(`assignment.${item.subject}`) : ''}`
            : item.type;
        setClassName(item.conference_name);
        setAssignmentName(item.title);
        setStartDate(moment(item.startline).format(isTest ? 'YYYY-MM-DD' : 'YYYY-MM-DD, HH:mm'));
        setDuration(item.duration);
        setEndDate(moment(item.deadline).format('YYYY-MM-DD, HH:mm'));
        setQuestionType(questionType);
        setAttachFileList(item.attachments);
    }, [item]);
    useEffect(() => {
        if (assignmentRef.current)
            assignmentRef.current.value = item.title;
    }, [item.title]);
    //수업명, 테스트명
    const renderNameContainer = useMemo(() => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "task-label" }, t('assignment.class_name')),
            React.createElement("div", { className: "fext-field" },
                React.createElement("div", { className: "placeholder_wrap" },
                    React.createElement("p", null, className ? className : t('assignment.noClass'))))),
        React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "task-label" }, t('assignment.testname')),
            React.createElement("div", { className: "fext-field" },
                React.createElement("div", { className: "placeholder_wrap" },
                    React.createElement("p", null, assignmentName)))))), [className, assignmentName]);
    const renderDateInfo = useMemo(() => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "task-label" }, t('assignment.startDate')),
            React.createElement("div", { className: "fext-field", style: { minWidth: isTablet ? '130px' : undefined } },
                React.createElement(InputElement, { type: "text", isDisable: true, value: startDate }))),
        React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "task-label" }, t('assignment.endDate')),
            React.createElement("div", { className: "fext-field", style: { minWidth: isTablet ? '130px' : undefined } },
                React.createElement(InputElement, { type: "text", isDisable: true, value: endDate }))))), [startDate, endDate]);
    const renderTaskInfo = useMemo(() => (React.createElement(React.Fragment, null, isTablet ? (React.createElement("div", { className: "task-info-wrap assignment-info" },
        React.createElement("div", { className: "field-short", style: { gap: '12px' } }, renderDateInfo),
        React.createElement("div", { className: "field-short", style: { gap: '12px' } },
            React.createElement("div", { className: "field-global-001", style: { flex: '1' } },
                React.createElement("div", { className: "task-label" }, t('assignment.questionType')),
                React.createElement("div", { className: "fext-field", style: { minWidth: isTablet ? '130px' : undefined } },
                    React.createElement(InputElement, { type: "text", isDisable: true, value: questionType }))),
            component && (React.createElement("div", { className: "field-global-001", style: { flex: '1' } },
                React.createElement("div", { className: "task-label", style: { minWidth: !isTablet && '37px' } }, t('assignment.subject')),
                component))))) : (renderDateInfo))), [questionType]);
    const renderDateContainer = useMemo(() => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "task-label" }, t('assignment.progress_date')),
            React.createElement("div", { className: "fext-field" },
                React.createElement(InputElement, { type: "text", isDisable: true, value: startDate }))),
        React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "task-label" }, t('assignment.duration')),
            React.createElement("div", { className: "text-line" },
                React.createElement(InputElement, { type: "text", isDisable: true, value: duration }),
                React.createElement("div", null, t('assignment.minute')))))), [startDate, duration]);
    return (React.createElement(React.Fragment, null,
        isTest ? (React.createElement(React.Fragment, null,
            renderNameContainer,
            renderDateContainer)) : (React.createElement(React.Fragment, null,
            isDesktop ? null : (React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, t('assignment.name')),
                React.createElement("div", { className: "fext-field" },
                    React.createElement("div", { className: "placeholder_wrap" },
                        React.createElement("p", null, assignmentName))))),
            renderTaskInfo)),
        !isTablet && (React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "task-label" }, t('assignment.questionType')),
            React.createElement("div", { className: "fext-field" },
                React.createElement(InputElement, { type: "text", isDisable: true, value: questionType })))),
        React.createElement(AttachFile, { attachFileList: attachFileList, isRegist: false, isTeacher: isTeacher, showRegist: false, download: item.status === 'open' ? true : false })));
};
