import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from '../../../../Colors';
import { Button, MenuBar } from '../../../../common';
import { updateMenu } from '../../../menu/action';
import { getFilterMenuList, menuItems } from '../../../menu/function';
import { MyPageDropDown } from '../../account';
import { getHiddenMenus } from '../../classes';
import { useGlobalState } from '../../../../GlobalStateProvider';
const MobileGNB = React.memo(({ isWhite, isTeacher, selectedMenu, }) => {
    const { isTablet } = useGlobalState();
    const dispatch = useDispatch();
    const [isOpened, setIsOpened] = React.useState(false);
    const hiddenMenus = useSelector((state) => getHiddenMenus(state));
    const sidebarRef = useRef(null);
    const updateNewMenu = (depths, menu, id) => {
        dispatch(updateMenu(depths, menu, id));
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsOpened(false);
            }
        };
        if (isOpened) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpened]);
    const renderMenuBar = useCallback((depths, list, isParent = false, hasChild = false) => (React.createElement(MenuBar, { className: `gnb-menu ${isParent ? 'parent-menu' : ''} ${isParent && !hasChild ? 'no-child' : ''}`, isRow: false, isClickDisabled: false, list: isParent ? [depths] : list, value: selectedMenu, onItemClick: (changeMenu) => {
            updateNewMenu(depths, {
                menu: changeMenu === 'learningSupportRoom'
                    ? 'notice'
                    : changeMenu === 'learningManagement' ||
                        changeMenu === 'myRoom'
                        ? 'test'
                        : changeMenu === 'liveRoom'
                            ? 'reservation'
                            : changeMenu,
                detail: 'list', // Matches IMenuDetailProps
            } // menu object
            );
            setIsOpened(false);
        } })), [selectedMenu]);
    const menuSections = useMemo(() => {
        const filteredMenus = getFilterMenuList(isTeacher, hiddenMenus);
        return filteredMenus.map(menuKey => {
            let subMenuList = [];
            switch (menuKey) {
                case 'learningManagement':
                    subMenuList = isTeacher ? menuItems.learningManagement : [];
                    break;
                case 'myRoom':
                    subMenuList = isTeacher ? menuItems.myRoom : menuItems.myRoomStudent;
                    break;
                case 'liveRoom':
                    subMenuList = isTeacher ? menuItems.liveRoom : [];
                    break;
                case 'learningSupportRoom':
                    subMenuList = isTeacher
                        ? menuItems.learningSupportRoomTeacher
                        : menuItems.learningSupportRoomStudent;
                    break;
                default:
                    subMenuList = [];
            }
            return {
                depths: menuKey,
                list: subMenuList.filter(menu => !hiddenMenus.includes(menu)),
            };
        });
    }, [isTeacher, hiddenMenus]);
    return (React.createElement("div", { className: "mobile-navbar" },
        React.createElement(Button, { className: "btn-logo gray", option: {
                buttonBeforeIcon: {
                    show: true,
                    size: 18,
                    color: isWhite ? Colors.white : Colors.black,
                    name: 'menu_open',
                },
            }, onClick: () => setIsOpened(true) }),
        React.createElement("div", { className: `mobile-gnb ${isOpened ? 'active' : ''}` },
            React.createElement("div", { className: `mobile-gnb-sidebar ${isTablet && 'tablet-sidebar'}`, ref: sidebarRef },
                React.createElement("div", { className: "mobile-gnb-header" },
                    React.createElement(Button, { className: `btn-logo`, option: {
                            buttonBeforeIcon: {
                                show: true,
                                width: 107,
                                height: 30,
                                name: 'logo',
                            },
                        } }),
                    React.createElement(Button, { className: `btn-logo gray`, option: {
                            buttonBeforeIcon: {
                                show: true,
                                size: 18,
                                name: 'menu_close',
                            },
                        }, onClick: () => setIsOpened(false) })),
                React.createElement("div", { className: "mobile-gnb-menu" }, menuSections.map(({ depths, list }) => (React.createElement("div", { className: "gnb-section", key: depths },
                    renderMenuBar(depths, [], true, list.length > 0),
                    list.length > 0 && renderMenuBar(depths, list))))),
                React.createElement("div", { className: "mobile-gnb-footer" },
                    React.createElement(MyPageDropDown, { isSidebar: true }))))));
});
export default MobileGNB;
