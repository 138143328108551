import React, { Fragment, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Button, Table } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { AttandancesTitle } from './AttandancesTitle';
import { Colors } from '../../../../Colors';
import { useConferenceAttendance } from '../../../../server';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
const AttandancesTableStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: flex-end;
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.divider {
		background: #d9d9d9;
		flex-shrink: 0;
		width: 1px;
		height: 16px;
		position: relative;
	}

	${({ $isDesktop, $isTablet, $isMobile }) => $isMobile
    ? `
			.status-panel {
				background: #feffff;
				border-radius: 8px;
				border-style: solid;
				border-color: #bbd1d3;
				border-width: 1px;
				padding: 12px 20px 12px 20px;
				display: flex;
				flex-direction: row;
				gap: 16px;
				align-items: center;
				justify-content: center;
				align-self: stretch;
				flex-shrink: 0;
				position: relative;
				margin: 0 20px;

				.entire {
					display: flex;
					flex-direction: column;
					gap: 0px;
					align-items: center;
					justify-content: center;
					flex: 1;
					position: relative;
				}

				

				.value {
					color: var(--tur-d, #479da6);
					text-align: center;
					font-family: var(--b3-font-family, "NotoSansKr-Bold", sans-serif);
					font-size: var(--b3-font-size, 16px);
					line-height: var(--b3-line-height, 24px);
					letter-spacing: var(--b3-letter-spacing, -0.02em);
					font-weight: var(--b3-font-weight, 700);
					position: relative;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}

				.label {
					color: var(--color-grey, #666666);
					text-align: center;
					font-family: var(--b5-font-family, "NotoSansKr-Regular", sans-serif);
					font-size: var(--b5-font-size, 12px);
					line-height: var(--b5-line-height, 22px);
					letter-spacing: var(--b5-letter-spacing, -0.02em);
					font-weight: var(--b5-font-weight, 400);
					position: relative;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			`
    : `
	
			.status-panel {
				background: #feffff;
				border-radius: 8px;
				border-style: solid;
				border-color: #bbd1d3;
				border-width: 1px;
				padding: 20px 100px;
				display: flex;
				flex-direction: row;
				gap: 20px;
				align-items: center;
				justify-content: center;
				align-self: stretch;
				flex-shrink: 0;
				position: relative;

				.entire {
					display: flex;
					flex-direction: row;
					gap: 20px;
					align-items: center;
					justify-content: center;
					flex: 1;
					position: relative;
				}

				
				.value {
					color: var(--tur-d, #479da6);
					text-align: center;
					font-family: var(--b2-font-family, 'Noto Sans KR', sans-serif);

					${$isTablet
        ? `
							font-size: var(--b4-font-size, 14px);
							line-height: var(--b4-line-height, 22px);
					`
        : `
						font-size: var(--h4-font-size, 20px);
						line-height: var(--h4-line-height, 32px);
					`}
					
					letter-spacing: var(--h4-letter-spacing, -0.02em);
					font-weight: var(--h4-font-weight, 700);
					position: relative;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}

				.label {
					color: var(--color-grey, #666666);
					text-align: center;
					font-family: var(--b2-font-family, 'Noto Sans KR', sans-serif);

					${$isTablet
        ? `
						font-size: var(--b5-font-size, 12px);
						line-height: var(--b5-line-height, 22px);
						
					`
        : `
						font-size: var(--b2-font-size, 18px);
						line-height: var(--b2-line-height, 36px);
					`}
				
					letter-spacing: var(--b2-letter-spacing, -0.02em);
					font-weight: var(--b2-font-weight, 400);
					position: relative;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}
	`}

	.attend-list-003 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		overflow: ${props => (props.$isMobile ? 'auto' : 'hidden')};
		width: 100%;
		padding: ${props => (props.$isMobile ? '0px 20px' : '0px')};

		.table-item-wrap {
			height: 100%;
		}

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 12px 20px 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
				${props => props.$isDesktop
    ? `font-size: var(--b3-font-size, 16px);
                    line-height: var(--b3-line-height, 32px);
                    letter-spacing: var(--b3-letter-spacing, -0.02em);`
    : props.$isTablet
        ? `font-size: 14px;
                        line-height: 150%;
                        letter-spacing: -0.02em;`
        : `font-size: 12px;
                        line-height: 150%;
                        letter-spacing: -0.02em;`};

				font-weight: var(--b3-font-weight, 400);
				position: relative;
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 20px 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			span {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
				${props => props.$isDesktop
    ? `font-size: var(--b3-font-size, 16px);
                    line-height: var(--b3-line-height, 32px);
                    letter-spacing: var(--b3-letter-spacing, -0.02em);`
    : `font-size: 14px;
                        line-height: 150%;
                        letter-spacing: -0.02em;`};
				font-weight: var(--b3-font-weight, 400);
			}

			.green {
				color: var(--tur-l, #458c94);
			}

			.grey {
				color: var(--color-grey-l, #83868a);
			}

			.big {
				span {
					text-align: left;
					font-family: 'Noto Sans KR', sans-serif;
					font-size: 14px;
				}
			}

			.bold {
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 600;
			}
		}

		.mobile_item {
			cursor: pointer;
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.one_line {
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: center;
				justify-content: flex-start;
				flex-shrink: 0;
				position: relative;
			}
		}
	}
`;
const renderTextContent = (headItem, item, totalCount) => {
    switch (headItem.title) {
        case 'nameAndStatus':
            return (React.createElement("div", { className: "one_line big overText2" },
                React.createElement("span", { className: "green" }, item.done ? (item.late ? '지각' : '출석') : '미출석'),
                React.createElement("span", { className: "bold" },
                    item.name,
                    "(",
                    item.id,
                    ")")));
        case 'enterAndLeave':
            return (React.createElement("div", { className: "one_line overText2" },
                React.createElement("span", null,
                    "\uC785\uC7A5 \uC2DC\uAC04:",
                    ' ',
                    item.enter_time ? moment(item.enter_time).format('HH:mm:ss') : '-'),
                React.createElement("span", { className: "one_line overText2" },
                    "\uD1F4\uC7A5 \uC2DC\uAC04:",
                    ' ',
                    item.leave_time ? moment(item.leave_time).format('HH:mm:ss') : '-')));
        case 'timeAndEtc':
            return (React.createElement("div", { className: "one_line overText2" },
                React.createElement("span", null,
                    "\uCC38\uC5EC \uC2DC\uAC04 : ",
                    item.attend_time,
                    "\uBD84")));
        case 'attend_time':
            return React.createElement("span", { className: "overText2" },
                item.attend_time,
                "\uBD84");
        case 'done':
            return (React.createElement("span", { className: "overText2" }, item.done ? (item.late ? '지각' : '출석') : '미출석'));
        case 'name':
            return React.createElement("span", { className: "overText2" }, item.name);
        case 'enter_time':
            return (React.createElement("span", { className: "overText2" }, item.enter_time ? moment(item.enter_time).format('HH:mm:ss') : '-'));
        case 'leave_time':
            return (React.createElement("span", { className: "overText2" }, item.leave_time ? moment(item.leave_time).format('HH:mm:ss') : '-'));
    }
    return React.createElement("span", { className: "overText2" }, item[headItem.title]);
};
const ATTEND_PANEL_LIST = ['all', 'attend', 'late', 'nonAttend'];
export const AttandancesTable = React.memo(({ id }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [statisticData, setStatisticData] = React.useState({
        all: 0,
        attend: 0,
        late: 0,
        nonAttend: 0,
    });
    const [testData, setTestData] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [totalCount, setTotalCount] = React.useState(100);
    const [count, setCount] = React.useState(10);
    const [searchOption, setSearchOption] = React.useState({});
    const [status, setStatus] = React.useState('all');
    const { data, refetch } = useConferenceAttendance({
        id,
        ...searchOption,
        ...(['attendance', 'nonAttendance'].includes(status) && { done: status === 'attendance' }),
        ...(status === 'late' && { late: true }),
        offset: page === 0 ? 0 : page * count,
        limit: count,
    });
    useEffect(() => {
        return () => {
            setPage(0);
            setSearchOption({});
            setStatus('all');
        };
    }, [id]);
    useEffect(() => {
        if (searchOption || id)
            refetch();
    }, [id, page, searchOption, status]);
    useEffect(() => {
        if (data) {
            const { total_count, found_count, items, done_count, late_count } = data;
            if (total_count > 0) {
                if (total_count > 0 && found_count === 0) {
                    const maxPage = Math.max(0, Math.ceil(total_count / 10) - 1);
                    setPage(maxPage);
                    return;
                }
                setTestData(items);
                setTotalCount(total_count);
                setStatisticData({
                    all: total_count,
                    attend: done_count,
                    late: late_count,
                    nonAttend: total_count - done_count - late_count,
                });
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
        setPage(0);
    }, [data]);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        const headTitleList = isMobile
            ? []
            : [
                { title: 'name', space: 1, sort: false },
                { title: 'id', space: 2, sort: false },
                { title: 'participantTime', space: 1, sort: false },
                { title: 'attendanceStatus', space: 1, sort: false },
                { title: 'eneter_time', space: 1, sort: false },
                { title: 'exit_time', space: 1, sort: false },
                // { title: 'etc', space: 1, sort: false },
            ];
        const tableReadDataKeys = isMobile
            ? [
                { title: 'nameAndStatus', space: 3 },
                { title: 'enterAndLeave', space: 3 },
                { title: 'timeAndEtc', space: 3 },
            ]
            : [
                { title: 'name', space: 1 },
                { title: 'id', space: 2, sort: false },
                { title: 'attend_time', space: 1 },
                { title: 'done', space: 1 },
                { title: 'enter_time', space: 1, sort: false },
                { title: 'leave_time', space: 1, sort: false },
                // { title: 'etc', space: 1 },
            ];
        return { headTitleList, tableReadDataKeys };
    }, [isMobile]);
    const handlerMoreData = (page) => {
        setPage(page);
    };
    // 상단 레이아웃 검색 조건을 위한 action
    const handlerFilterList = (filter) => {
        const key = Object.keys(filter);
        if (filter[key[0]] === null && filter[key[1]] === null)
            setSearchOption({});
        else if (searchOption[key[0]] !== filter[key[0]] || searchOption[key[1]] !== filter[key[1]])
            setSearchOption({ ...searchOption, ...filter });
    };
    const renderTitle = useMemo(() => (React.createElement(React.Fragment, null,
        React.createElement(AttandancesTitle, { test_id: id, defaultStatus: status, setStatus: setStatus, handlerFilterList: handlerFilterList }),
        React.createElement("div", { className: "status-panel" }, ATTEND_PANEL_LIST.map((item, index) => (React.createElement(Fragment, { key: item },
            React.createElement("div", { className: "entire" },
                React.createElement("div", { className: "label" }, t(`assignment.${item}`)),
                React.createElement("div", { className: "value" },
                    statisticData[item],
                    "\uBA85")),
            ATTEND_PANEL_LIST.length - 1 !== index && (React.createElement("div", { className: "divider" })))))))), [id, status, handlerFilterList]);
    const renderEmpty = useMemo(() => {
        if (testData.length === 0) {
            return (React.createElement(Button, { className: "empty_data", option: {
                    buttonBeforeIcon: {
                        show: true,
                        name: 'none',
                        size: 36,
                        color: Colors.grey,
                    },
                }, text: "\uC120\uD0DD\uB41C \uC218\uC5C5 \uBC0F \uCC38\uC5EC\uC790 \uC815\uBCF4\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4" }));
        }
    }, [testData]);
    const renderItem = (item, styles, index, isMobile) => {
        return tableReadDataKeys.map((headItem, index) => (React.createElement("div", { key: headItem.title + index, style: {
                flex: headItem.space,
                ...styles?.bodyItem,
                overflow: 'hidden',
            } }, renderTextContent(headItem, item, totalCount))));
    };
    return (React.createElement(AttandancesTableStyled, { "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        React.createElement(Table, { className: "attend-list-003", isInifinite: false, data: testData, totalCount: totalCount, page: page, pagingCount: isTablet ? 3 : 5, headTitleList: headTitleList, renderTitle: renderTitle, renderEmpty: renderEmpty, renderItem: renderItem, handlerMoreData: handlerMoreData, forceShowheader: testData.length === 0 || isMobile ? false : true })));
});
