import html2canvas from 'html2canvas';
import i18next from 'i18next';
import jsPDF from 'jspdf';
export const downloadPdf = async (name) => {
    APP.eventManager.publish('SHOW_LOADING', true);
    const pdfContent = document.querySelector('.pdf-download');
    if (!pdfContent) {
        console.error('The element was not found');
        return;
    }
    // pdfContent 복제
    const clonedContent = pdfContent.cloneNode(true);
    // 이름 추가 (옵션)
    if (name) {
        const nameElement = document.createElement('div');
        const translatedTitle = i18next.t(`assignment.${name?.title}Title`); // 번역 처리
        nameElement.innerHTML = `<strong>${translatedTitle}</strong>`;
        nameElement.style.marginBottom = '10px';
        nameElement.style.paddingTop = '10px';
        clonedContent.prepend(nameElement);
    }
    // 복제된 요소를 임시로 DOM에 추가
    document.body.appendChild(clonedContent);
    clonedContent.style.position = 'relative';
    clonedContent.style.left = '-9999px'; // 화면 밖으로 이동
    clonedContent.style.padding = '20px'; // 패딩 추가
    clonedContent.style.boxSizing = 'border-box'; // 패딩 포함 크기 계산
    clonedContent.style.display = 'flex';
    clonedContent.style.flexDirection = 'column';
    clonedContent.style.gap = '24px';
    try {
        const canvas = await html2canvas(clonedContent, {
            scale: 2,
            useCORS: true,
            onclone: clonedDocument => {
                const clonedPdfContent = clonedDocument.querySelector('.pdf-download');
                if (clonedPdfContent) {
                    clonedPdfContent.style.width = '100%';
                    clonedPdfContent.style.boxSizing = 'border-box';
                    clonedPdfContent.style.padding = '20px'; // 패딩 추가
                    const emptyTableElements = clonedPdfContent.querySelectorAll('.empty_data');
                    emptyTableElements.forEach((element) => {
                        element.style.display = 'flex'; // Flexbox 적용
                        element.style.justifyContent = 'center'; // 수평 중앙 정렬
                        element.style.alignItems = 'center'; // 수직 중앙 정렬
                        element.style.textAlign = 'center'; // 텍스트 중앙 정렬
                        element.style.width = '500px';
                        element.style.height = '100%';
                    });
                }
            },
        });
        const contentWidth = canvas.width;
        const contentHeight = canvas.height;
        // PDF 설정
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const scale = pageWidth / contentWidth;
        const imgWidth = contentWidth * scale; // 페이지 너비에 맞춤
        const imgHeight = contentHeight * scale;
        // 여러 페이지로 나누기
        let position = 0;
        const imgData = canvas.toDataURL('image/png');
        while (position < imgHeight) {
            pdf.addImage(imgData, 'PNG', 0, position > 0 ? -position : 0, imgWidth, imgHeight);
            position += pageHeight; // 다음 페이지로 이동
            if (position < imgHeight)
                pdf.addPage();
        }
        pdf.save('download.pdf');
    }
    finally {
        // 복제된 요소 제거
        document.body.removeChild(clonedContent);
        APP.eventManager.publish('SHOW_LOADING', false);
    }
};
