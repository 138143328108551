import styled, { css } from 'styled-components';
export const DSTP001Styled = styled.div `
	.survey_checkbox_item {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: space-between;

		.del_btn {
			color: var(--color-pink, #f46790);
			text-align: center;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 14px;
			line-height: 28px;
			letter-spacing: -0.02em;
			font-weight: 400;
			text-decoration: underline;
			position: relative;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	}

	.popup-list-002-wrapper {
		gap: 12px;

		.scroll-content {
			flex: 1;
		}
	}

	.field-short {
		flex-wrap: wrap;
	}

	.task-info {
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: row;
		gap: 24px;
		overflow: auto;
	}
	.test-item {
		display: flex;
		flex-direction: column;
		flex: 1;
		width: 100%;
		height: 100%;
	}

	.test-item-notice {
		display: flex;
		flex-direction: column;
		flex: 1;
		width: 100%;
		align-items: center;
		justify-content: center;
		gap: 12px;
		p {
			align-content: center;
			color: #83868a;
			font-size: 18px;
		}
	}
	.test-item-attachment {
		display: flex;
		flex-direction: column;
		flex: 1;
		width: 100%;
		align-items: center;
	}

	.subject-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 24px;
		width: 100%;
	}

	.start-now {
		justify-content: end;
	}
	
	.assignment-checkbox-txt {
		flex-wrap: wrap;
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
	${props => props.$isPopup && popupStyles}
`;
const mobileStyles = css `
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.mobile-file-wrap {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 5px;
	}

	.checkbox-item {
		&.scoreBlank {
			span {
				font-size: 12px;
			}
		}
	}

	.box {
		min-height: 92px;
	}

	.button {
		z-index: 1;
	}

	.task-info-container-wrapper {
		overflow: auto;
		background: var(--color-white, #ffffff);
		border-radius: 0px 0px 8px 8px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		flex: 1;
		height: 100%;
	}

	.eval-table-wrap {
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 0px 0px 1px 0px;
		padding: 10px 0px 24px 0px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		width: 100%;
		position: relative;

		.popup-list-002-wrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: flex-end;
			justify-content: flex-start;
			gap: 12px;
		}

		.popup-list-002,
		.popup-list-002 * {
			box-sizing: border-box;
		}
		.popup-list-002 {
			width: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-end;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.scroll-content {
				padding: 0;
			}

			.table-header {
				width: 100%;
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: flex-start;
				justify-content: flex-start;
				flex-shrink: 0;
				position: relative;
			}

			.table-header-item {
				background: var(--color-silver, #f3f4f8);
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px 0px 0px 0px;
				padding: 10px 16px 10px 16px;
				display: flex;
				flex-direction: row;
				gap: 20px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				height: 100%;

				p {
					color: var(--color-black, #101010);
					text-align: center;
					font-family: 'Noto Sans KR', sans-serif;
					font-size: 12px;
					line-height: 150%;
					letter-spacing: -0.02em;
					font-weight: 400;
					position: relative;
				}
			}

			.scroll-content {
				display: flex;
				flex-direction: column;
				gap: 0px;
				align-items: center;
				justify-content: flex-start;
				flex-shrink: 0;
				width: 100%;
				position: relative;

				.item {
					display: flex;
					flex-direction: row;
					gap: 4px;
					align-items: flex-start;
					justify-content: flex-start;
					flex-shrink: 0;
					position: relative;
					width: 100%;

					.body-item {
						padding: 6px 4px 6px 4px;
						display: flex;
						flex-direction: row;
						gap: 20px;
						align-items: center;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
					}

					input {
						background: #ffffff;
						border-radius: 4px;
						border-style: solid;
						border-color: var(--color-grey-w, #b6bac0);
						border-width: 1px;
						padding: 4px 10px 4px 10px;
						display: flex;
						flex-direction: row;
						gap: 6px;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
						position: relative;
						color: var(--color-grey-l, #83868a);
						text-align: left;
						font-family: var(--b5-font-family, 'Noto Sans KR', sans-serif);
						font-size: var(--b5-font-size, 10px);
						line-height: var(--b5-line-height, 22px);
						font-weight: var(--b5-font-weight, 400);
						position: relative;
					}
				}
			}
		}
	}

	.field-global-001 {
		.mobile-date-wrap {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.attach-file-wrap {
		width: 100%;
		gap: 6px;
		display: flex;
		flex-direction: column;

		button {
			.overText {
				font-size: 12px;
			}
		}
	}

	.assignment-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
	}

	.subject-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
	}

	.eval-info-wrap {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 16px;
	}

	.border-btm {
		display: flex;
		width: 100%;
		height: 100%;
	}

	.border-btm {
		border: none;
	}
`;
const tabletStyles = css `
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: ${props => (props.$isLive ? '0px' : '20px')};

	.scoreBlank,
	.assignment-checkbox-txt {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 12px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		position: relative;
		display: flex;
		align-items: center;

		span {
			color: var(--color-black, #101010);
			text-align: center;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.box {
		min-height: 92px;
	}

	.task-info-container-wrapper {
		overflow: auto;
		background: var(--color-white, #ffffff);
		border-radius: 0px 0px 8px 8px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		flex: 1;
		height: 100%;
	}

	.task-info-container {
		padding: 0px 0px 0px 0px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		width: 50%;
		position: relative;
		padding: 24px;
		border: 1px solid #e6e8ed;
		border-radius: 8px;
		overflow: auto;
		// min-height: 370px;

		.popup-list-002-wrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: flex-end;
			justify-content: flex-start;
		}

		.popup-list-002,
		.popup-list-002 * {
			box-sizing: border-box;
		}
		.popup-list-002 {
			width: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-end;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.table-header {
				width: 100%;
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: flex-start;
				justify-content: flex-start;
				flex-shrink: 0;
				position: relative;
			}

			.table-header-item {
				background: var(--color-silver, #f3f4f8);
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px 0px 0px 0px;
				padding: 10px 16px 10px 16px;
				display: flex;
				flex-direction: row;
				gap: 20px;
				align-items: flex-start;
				justify-content: center;
				flex-shrink: 0;
				position: relative;

				p {
					color: var(--color-black, #101010);
					text-align: center;
					font-family: 'Noto Sans KR', sans-serif;
					font-size: 10px;
					line-height: 150%;
					letter-spacing: -0.02em;
					font-weight: 400;
					position: relative;
				}
			}

			.scroll-content {
				display: flex;
				flex-direction: column;
				gap: 0px;
				align-items: center;
				justify-content: flex-start;
				flex-shrink: 0;
				width: 100%;
				position: relative;

				.sel_eval_item {
					color: var(--color-grey-l, #83868a);
					text-align: center;
					font-family: 'Noto Sans KR', sans-serif;
					font-size: 10px;
					line-height: 150%;
					letter-spacing: -0.02em;
					font-weight: 400;
				}

				.item {
					display: flex;
					flex-direction: row;
					gap: 4px;
					align-items: flex-start;
					justify-content: flex-start;
					flex-shrink: 0;
					position: relative;
					width: 100%;

					.body-item {
						padding: 8px 16px 8px 16px;
						display: flex;
						flex-direction: row;
						gap: 20px;
						align-items: center;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
					}

					input {
						background: #ffffff;
						border-radius: 4px;
						border-style: solid;
						border-color: var(--color-grey-w, #b6bac0);
						border-width: 1px;
						padding: 4px 10px 4px 10px;
						display: flex;
						flex-direction: row;
						gap: 6px;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
						position: relative;
						color: var(--color-grey-l, #83868a);
						text-align: left;
						font-family: var(--b5-font-family, 'Noto Sans KR', sans-serif);
						font-size: var(--b5-font-size, 12px);
						line-height: var(--b5-line-height, 22px);
						font-weight: var(--b5-font-weight, 400);
						position: relative;
					}
				}
			}
		}
	}

	.assignment-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
	}

	.test-item-notice {
		p {
			font-size: 16px;
		}
	}

	.btn-container {
		.button {
			width: 116px;
		}
	}

	.dst_attach_file {
		align-self: end;
	}

	.border-btm {
		display: flex;
		width: 100%;
		min-height: calc(100% - 80px);
		height: min-content;
		overflow: auto;
	}
`;
const desktopStyles = css `
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.border-btm {
		display: flex;
		width: 100%;
		height: 100%;
		overflow: auto;
		border-bottom: 1px solid #e6e8ed;
		padding-bottom: 24px;
	}

	.box {
		min-height: 92px;
	}

	.task-info-container-wrapper {
		background: var(--color-white, #ffffff);
		border-radius: 0px 0px 8px 8px;
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		flex: 1;
		height: 100%;
	}

	.task-info-container {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-start;
		width: 35%;
		position: relative;
		padding: 24px;
		border: 1px solid #e6e8ed;
		border-radius: 8px;

		.popup-list-002-wrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: flex-end;
			justify-content: flex-start;
		}

		.popup-list-002,
		.popup-list-002 * {
			box-sizing: border-box;
		}
		.popup-list-002 {
			width: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-end;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.table-header {
				width: 100%;
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: flex-start;
				justify-content: flex-start;
				flex-shrink: 0;
				position: relative;
				border-bottom: none;
				padding: 0;
			}

			.table-header-item {
				background: var(--color-silver, #f3f4f8);
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px 0px 0px 0px;
				padding: 10px 16px 10px 16px;
				display: flex;
				flex-direction: row;
				gap: 20px;
				align-items: flex-start;
				justify-content: center;
				flex-shrink: 0;
				position: relative;

				p {
					color: var(--color-black, #101010);
					text-align: center;
					font-family: var(--b5-font-family, 'Noto Sans KR', sans-serif);
					font-size: var(--b5-font-size, 12px);
					line-height: var(--b5-line-height, 22px);
					font-weight: var(--b5-font-weight, 400);
					position: relative;
				}
			}

			.scroll-content {
				display: flex;
				flex-direction: column;
				gap: 0px;
				align-items: center;
				justify-content: flex-start;
				flex-shrink: 0;
				width: 100%;
				position: relative;

				.item {
					display: flex;
					flex-direction: row;
					gap: 4px;
					align-items: flex-start;
					justify-content: flex-start;
					flex-shrink: 0;
					position: relative;
					width: 100%;
					padding: 0;
					border-bottom: none;

					.body-item {
						padding: 8px 16px 8px 16px;
						display: flex;
						flex-direction: row;
						gap: 20px;
						align-items: center;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
					}

					input {
						background: #ffffff;
						border-radius: 4px;
						border-style: solid;
						border-color: var(--color-grey-w, #b6bac0);
						border-width: 1px;
						padding: 4px 10px 4px 10px;
						display: flex;
						flex-direction: row;
						gap: 6px;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
						position: relative;
						color: var(--color-grey-l, #83868a);
						text-align: left;
						font-family: var(--b5-font-family, 'Noto Sans KR', sans-serif);
						font-size: var(--b5-font-size, 12px);
						line-height: var(--b5-line-height, 22px);
						font-weight: var(--b5-font-weight, 400);
						position: relative;
					}
				}
			}
		}
	}

	.subject-info-wrap {
		border-top: 1px solid #e6e8ed;
		padding-top: 24px;
	}

	.assignment-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
	}

	.survey_wrapper {
		overflow: auto;
	}
`;
const popupStyles = css `
	.task-info-container-wrapper {
		overflow: auto;
	}

	.task-info-container {
		padding: 0px;
		width: 100%;
		border: none;
		${props => props.$isTablet && `overflow: unset;`}
	}

	.border-btm {
		overflow: unset;
	}
`;
