import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from '../../../Colors';
import { Button } from '../../../common';
import { EmptyTable } from '../../../common/option';
import { useGlobalState } from '../../../GlobalStateProvider';
import { useQnaList } from '../../../server';
import { getIsTeacher } from '../../base/account';
import { DetailTitleInfo } from '../../base/layout';
import { changeMobileMenuName } from '../../menu/action';
import { QNA_TYPE } from './constant';
import { DSTL004ViewStyled } from './detailStyled';
import { QnAContents } from './DSTS004_Detailed_Child';
import { QnAHistoryTable } from './DSTS004_Detailed_Child/QnAHistoryTable';
export const DSTS004_Detail = ({ root_id, depths, isHidePaging = false, isContent = false, isSearchAnswer = false, isHistory = false, isPopup = false, }) => {
    const dispatch = useDispatch();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [number, setNumber] = useState(depths);
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const { data, refetch } = useQnaList({
        root_id,
        limit: 2,
        offset: isContent && number !== 0 && number % 2 !== 0 ? number - 1 : number,
        orderby: ['creation_time'],
        with_content: true,
    });
    const refetchList = throttle(() => refetch(), 1000, { leading: true, trailing: false });
    useEffect(() => {
        setNumber(depths);
    }, [depths]);
    useEffect(() => {
        root_id && refetchList();
    }, [root_id, number]);
    // React 컴포넌트 방식
    const MobileMenuName = ({ title, depths }) => (React.createElement("div", { className: "flex mobile_menu", style: { gap: 8 } },
        depths >= 2 && React.createElement(Button, { className: "btn_tur", text: "supportRoom.req" }),
        React.createElement("span", { className: "overText" }, title)));
    useEffect(() => {
        if (testData && testData.length > 0)
            dispatch(changeMobileMenuName(
            //@ts-ignore
            React.createElement(MobileMenuName, { title: testData[0]?.title || 'Default Title', depths: depths })));
        else
            dispatch(changeMobileMenuName('Q&A'));
    }, [testData, depths]);
    useEffect(() => {
        if (data) {
            const { total_count, items, found_count } = data;
            if (found_count > 0) {
                setTestData(items);
                setTotalCount(total_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    useEffect(() => {
        APP.eventManager.subscribe('REFETCH_QNA_LIST', refetchList);
        return () => {
            APP.eventManager.unsubscribe('REFETCH_QNA_LIST', refetchList);
        };
    }, []);
    /**
     * (onClick) 이전 페이지로 이동
     */
    const previousPage = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'list',
            id: undefined,
        });
    };
    /**
     * Q&A 답변 상단 타이틀
     */
    const renderInfoTitle = useMemo(() => (React.createElement(DetailTitleInfo, { title: testData && testData.length > 0 ? (React.createElement("div", { className: "flex", style: { gap: 8 } },
            depths >= 2 && React.createElement(Button, { className: "btn_tur", text: 'supportRoom.req' }),
            React.createElement("span", null, testData[0].title))) : null, previousPage: previousPage })), [testData, depths]);
    if (testData && testData.length > 0) {
        return (React.createElement(DSTL004ViewStyled, { className: `dsts004-detail qna-detail-list ${isHistory ? 'history' : ''}`, "$isTablet": isTablet, "$isMobile": isMobile, "$isDesktop": isDesktop, "$isPopup": isPopup },
            !isHistory && isDesktop && renderInfoTitle,
            testData.map((item) => (React.createElement("div", { className: "qna-container", key: item.id },
                React.createElement(QnAContents, { key: item.id, number: number, totalCount: totalCount, data: item, questionData: {
                        id: testData[0].id,
                        status: testData[0].status,
                    }, depths: depths, isCloseAnswer: !isTeacher ? testData.length === 2 : false, isQuestion: item.type === QNA_TYPE.QUESTION, isHistory: isHistory })))),
            !isHistory && totalCount > 2 && (React.createElement(QnAHistoryTable, { isTeacher: isTeacher, filterItems: testData.map(t => t.id), root_id: root_id })),
            isHistory && (React.createElement(React.Fragment, null, totalCount > 2 && !isHidePaging && (React.createElement("div", { className: "btn_groups" },
                number !== 0 && (React.createElement(Button, { className: "btn btn_default xmd", text: "supportRoom.previousQuestion", option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: 'arrow',
                            size: 22,
                            color: Colors.w_grey,
                        },
                    }, onClick: () => setNumber(number - 2) })),
                totalCount > number * 2 && (React.createElement(Button, { className: "btn btn_default xmd", text: "supportRoom.nextQuestion", option: {
                        buttonAfterIcon: {
                            show: true,
                            className: 'arrow-right',
                            name: 'arrow',
                            size: 22,
                            color: Colors.w_grey,
                        },
                    }, onClick: () => setNumber(number + 2) }))))))));
    }
    return (React.createElement(DSTL004ViewStyled, { className: "dsts004-detail qna-detail-list no-data", "$isTablet": isTablet, "$isMobile": isMobile, "$isDesktop": isDesktop, "$isPopup": isPopup },
        React.createElement(EmptyTable, { title: "\uB370\uC774\uD130\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4.", iconProps: {
                icon: 'detail-delete',
                size: isMobile ? 28 : 32,
                color: Colors.w_grey,
            } })));
};
{
    /*

                {totalCount > 2 && !isHidePaging && (
                    <div className="btn_groups">
                        {number !== 0 && (
                            <Button
                                className="btn btn_default xmd"
                                text="supportRoom.previousQuestion"
                                option={{
                                    buttonBeforeIcon: {
                                        show: true,
                                        name: 'arrow',
                                        size: 22,
                                        color: Colors.w_grey,
                                    },
                                }}
                                onClick={() => setNumber(number - 2)}
                            />
                        )}

                        {totalCount > number * 2 && (
                            <Button
                                className="btn btn_default xmd"
                                text="supportRoom.nextQuestion"
                                option={{
                                    buttonAfterIcon: {
                                        show: true,
                                        className: 'arrow-right',
                                        name: 'arrow',
                                        size: 22,
                                        color: Colors.w_grey,
                                    },
                                }}
                                onClick={() => setNumber(number + 2)}
                            />
                        )}
                    </div>
                )} */
}
