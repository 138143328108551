import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, SearchBar } from '../../../../common';
import { ToggleSwitch } from '../../../../common/element/ToggleSwitch';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { getAccessToken, getIsTeacher } from '../../../base/account';
import DateRangeSelector from './DateRangeSelector';
import { downloadQnAList } from './functions';
import { QnAListHeaderStyled } from './InfoStyled';
import { QnAAnswerStateDropDown } from './QnAAnswerStateDropDown';
import { QnACategoryDropDown } from './QnACategoryDropDown';
export const QnAListInfo = React.memo(({ searchOption, waiting, answerState, category, startDate, endDate, downloadFilter, setAnswerState, setCategroy, setStartDate, setEndDate, setSearchOption, setWaiting, }) => {
    const { isTablet, isMobile, isDesktop } = useGlobalState();
    const { t } = useTranslation();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const access_token = useSelector((state) => getAccessToken(state));
    //다운로드 버튼 클릭 시 동작
    const handlerDownload = async () => await downloadQnAList(downloadFilter, access_token);
    return (React.createElement(QnAListHeaderStyled, { className: "qna-list-info", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        React.createElement("div", { className: "field-global-001 info-wrap" },
            !isMobile && (React.createElement(ToggleSwitch, { text: isTeacher ? '답변대기 보기' : '내 Q&A 보기', isOn: waiting, setIsOn: setWaiting })),
            React.createElement("div", { className: "dropdown-section" },
                React.createElement(QnACategoryDropDown, { category: category, setCategroy: setCategroy }),
                React.createElement(QnAAnswerStateDropDown, { answerState: answerState, setAnswerState: setAnswerState })),
            React.createElement("div", { className: "date-field" },
                React.createElement(DateRangeSelector, { defaultStartDate: startDate, defaultEndDate: endDate, setStartDate: setStartDate, setEndDate: setEndDate })),
            React.createElement(SearchBar, { className: `${!isMobile ? 'search-bar-qna' : 'search-bar-mobile'}`, filterData: "keyword", 
                // @ts-ignore
                defaultSearchContent: searchOption?.keyword, handlerFilterList: value => {
                    setSearchOption(value);
                } }),
            React.createElement("div", { className: "date-field", style: { justifyContent: 'space-between' } },
                isMobile && (React.createElement(ToggleSwitch, { text: isTeacher ? '답변대기 보기' : '내 Q&A 보기', isOn: waiting, setIsOn: setWaiting })),
                isTeacher && (React.createElement(Button, { className: "btn_light_pur xmd", text: t('common.download'), onClick: handlerDownload }))))));
});
