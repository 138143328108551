import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../../Colors';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { Button, SearchBar, Table } from '../../../../../common';
import { useSubmitStatus } from '../../../../../server';
import { TOGGLE_POPUP } from '../../../../base/popup';
import { DSTL001_Submit_Detail } from '../../DSTL001_Submit_Detail';
import { DSTL001_Submit_View } from '../../DSTL001_Submit_View';
import { DSTL001DetailTableStyled } from './DSTL001DetailStyled';
// 제출 현황 테이블
export const SubmissionStatusTable = React.memo(({ isTest, type, test_id, subjectId, isDetail = false, isPopUp = false, handlerUpdateMember, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(10);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [questionCount, setQuestioncount] = useState(0);
    const [submissionStatus, setSubmissionStatus] = useState();
    const [orderOption, setOrderOption] = useState();
    const [searchOption, setSearchOption] = useState();
    const [totalSubmitted, setTotalSubmitted] = useState(0);
    const [selected, setSelected] = useState();
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            ...(isMobile
                ? [
                    { title: 'live_status', space: 2, sort: false },
                    { title: 'nameId', space: 1, sort: false },
                ]
                : [
                    { title: 'nameId', space: 3, sort: false },
                    { title: 'live_status', space: 1, sort: false },
                ]),
            ...(type === '설문형' || type === '파일첨부형'
                ? [{ title: 'question', space: 2, sort: false }]
                : [
                    { title: 'numberOfQuestion', space: 2, sort: false },
                    { title: 'grades', space: 1, sort: false },
                ]),
            { title: 'etc', space: 1, sort: false },
        ];
        const tableReadDataKeys = [
            ...(isMobile
                ? [
                    { title: 'status', space: 2, sort: false },
                    { title: 'member_name', space: 1, sort: false },
                ]
                : [
                    { title: 'member_name', space: 3, sort: false },
                    { title: 'status', space: 1, sort: false },
                ]),
            ...(type === '설문형' || type === '파일첨부형'
                ? [{ title: 'question', space: 2, sort: false }]
                : [
                    { title: 'answer', space: 2, sort: false },
                    { title: 'score', space: 1, sort: false },
                ]),
            { title: 'etc', space: 1 },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, [type, isMobile]);
    const { data, refetch } = useSubmitStatus({
        id: test_id,
        subject_item: subjectId,
        offset: page === 0 ? 0 : page * count,
        limit: count,
        // ...(orderOption ? { orderby: orderOption } : {}),
        ...(searchOption ? { ...searchOption } : {}),
    });
    useEffect(() => {
        test_id && refetch();
    }, [test_id, subjectId, searchOption, orderOption]);
    useEffect(() => {
        if (!data)
            return;
        const { not_submitted, submitted, question_count, items, total_count } = data;
        setTestData(items);
        setTotalCount(total_count);
        setQuestioncount(question_count);
        setTotalSubmitted(submitted);
        setSubmissionStatus(`${submitted}/${total_count}`);
    }, [data]);
    const handlerMoreData = (page) => {
        setPage(page);
    };
    // (리스트 갱신
    const refetchList = throttle(() => {
        refetch();
    }, 1000, { leading: true, trailing: false });
    useEffect(() => {
        setCount(10);
        refetchList();
    }, [isTest, count, page, searchOption, refetch]);
    const openAssignment = (member) => {
        if (isMobile) {
            localStorage.setItem('rememberTabIndex', JSON.stringify(1));
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'submit_view',
                id: test_id,
                props: member,
                subjectId: subjectId,
            });
        }
        else {
            APP.eventManager.publish(TOGGLE_POPUP, {
                component: DSTL001_Submit_View,
                componentProps: {
                    test_id,
                    member,
                    subjectId,
                    handlerClose: () => {
                        APP.eventManager.publish('RESET_SUBJECT');
                    },
                },
                width: 757,
                title: isTest ? '테스트 보기' : '과제 보기',
            });
        }
    };
    const openAssignmentDetail = () => {
        if (isMobile) {
            localStorage.setItem('rememberTabIndex', JSON.stringify(1));
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'submit_detail_view',
                id: test_id,
                props: {
                    subject_name: subjectId,
                    isDetail: false,
                    test_id,
                    member_id: '',
                    isTeacher: true,
                    isPreview: true,
                },
                subjectId: subjectId,
            });
        }
        else {
            APP.eventManager.publish(TOGGLE_POPUP, {
                component: DSTL001_Submit_Detail,
                componentProps: { test_id, isTest, isPreview: false },
                width: 1377,
                title: t(`assignment.${isTest ? 'showTestDetail' : 'showDetail'}`),
            });
        }
    };
    const handlerFilterList = (filter) => {
        setSearchOption(filter);
    };
    const handlerUpdateList = (sort, desc) => {
        const newSort = sort === 'nameId'
            ? 'member_name'
            : sort === 'live_status'
                ? 'status'
                : sort === 'numberOfQuestion'
                    ? 'number'
                    : sort === 'grades'
                        ? 'score'
                        : sort;
        setOrderOption([`${newSort} ${desc ? 'DESC' : 'ASC'}`]);
    };
    const handlerShow = (id) => {
        setSelected(selected === id ? undefined : id);
    };
    //TABLET body-item
    const renderTabletItem = (item, styles, index) => {
        return tableReadDataKeys.map(headItem => {
            if (headItem.title === 'member_name' ||
                headItem.title === 'score' ||
                headItem.title === 'answer') {
                return null;
            }
            const commonProps = {
                key: headItem.title,
                className: 'body-item',
                style: {
                    ...styles?.bodyItem,
                    overflow: 'hidden',
                },
            };
            if (headItem.title === 'etc') {
                return (React.createElement("div", { ...commonProps },
                    React.createElement("p", { 
                        // className={isDetail ? 'detail_more_txt' : 'more_txt'}
                        className: isDetail
                            ? selected === item.member_id
                                ? 'detail_more_txt_bold'
                                : 'detail_more_txt'
                            : 'more_txt', onClick: () => {
                            if (isDetail) {
                                if (handlerUpdateMember) {
                                    handlerUpdateMember({
                                        id: item.member_id,
                                        name: item.member_name,
                                    });
                                }
                                handlerShow(item.member_id);
                            }
                            else {
                                openAssignment({
                                    id: item.member_id,
                                    name: item.member_name,
                                });
                            }
                        } }, isDetail ? '보기' : '자세히')));
            }
            if (headItem.title === 'status') {
                return (React.createElement("div", { ...commonProps },
                    React.createElement("div", { className: "student-info-wrap" },
                        React.createElement("p", { className: "tabe-index" }, index + 1),
                        React.createElement("p", { className: "overText" }, item.not_submitted
                            ? t('assignment.notSubmitted')
                            : t('assignment.submitted')),
                        React.createElement("div", { className: `overText ${item.corrects === -1 ? '' : 'tur'}` }, `${item.member_name}(${item.member_idf})`)),
                    React.createElement("div", { className: "score-info-wrap" },
                        type === '설문형' || type === '파일첨부형' ? null : (React.createElement("div", { style: { display: 'flex', flexDirection: 'row' } },
                            React.createElement("div", { className: "score-bold" }, item.corrects === -1 ? '-' : item.corrects),
                            React.createElement("div", { style: { color: Colors.grey } },
                                "/",
                                questionCount))),
                        React.createElement("p", { className: "score-bold" },
                            item.score === -1 ? '-' : item.score,
                            "\uC810"))));
            }
            return (React.createElement("div", { ...commonProps },
                React.createElement("p", { className: "overText", style: styles?.bodyText }, item[headItem.title])));
        });
    };
    //데스트탑, 모바일 bodyItem
    const renderItem = (item, styles) => {
        return tableReadDataKeys.map(headItem => isMobile && headItem.title === 'score' ? null : (React.createElement("div", { key: headItem.title, className: "body-item", style: {
                flex: headItem.space,
                ...styles?.bodyItem,
                overflow: 'hidden',
            } }, headItem.title === 'etc' ? (React.createElement("p", { className: isDetail
                ? selected === item.member_id
                    ? 'detail_more_txt_bold'
                    : 'detail_more_txt'
                : 'more_txt', onClick: () => {
                if (isDetail) {
                    if (handlerUpdateMember) {
                        handlerUpdateMember({
                            id: item.member_id,
                            name: item.member_name,
                        });
                    }
                    handlerShow(item.member_id);
                }
                else {
                    openAssignment({
                        id: item.member_id,
                        name: item.member_name,
                    });
                }
            } }, isDetail ? '보기' : '자세히')) : headItem.title === 'answer' ? (isMobile ? (React.createElement("div", { className: "score-wrap", style: { color: Colors.grey } },
            React.createElement("div", { className: "overText", style: styles?.bodyText },
                "\uC815\uB2F5/\uBB38\uD56D\uC218 : ",
                item.corrects === -1 ? '-' : item.corrects,
                "/",
                questionCount),
            React.createElement("div", { className: "divider" }),
            React.createElement("div", { className: "overText", style: styles?.bodyText },
                "\uC810\uC218 : ",
                item.corrects === -1 ? '-' : item['score']))) : (React.createElement("p", { className: "overText", style: styles?.bodyText },
            item.corrects === -1 ? '-' : item.corrects,
            "/",
            questionCount))) : headItem.title === 'member_name' ? (React.createElement("p", { className: "overText2 submission-name", style: styles?.bodyText }, `${item[headItem.title]}(${item.member_idf})`)) : headItem.title === 'question' ? (React.createElement("p", { className: "overText", style: styles?.bodyText }, questionCount)) : (React.createElement("p", { className: `overText ${isMobile && headItem.title === 'member_name' ? 'bold' : ''} ${headItem.title === 'member_name' && selected === item.member_id
                ? 'underline'
                : ''}`, style: styles?.bodyText }, headItem.title === 'status'
            ? item.not_submitted
                ? t('assignment.notSubmitted')
                : t('assignment.submitted')
            : headItem.title === 'score'
                ? item.corrects === -1
                    ? '-'
                    : item[headItem.title]
                : headItem.title === 'answer'
                    ? `${item.corrects === -1 ? '-' : item.corrects}/${questionCount}`
                    : item[headItem.title])))));
    };
    const renderTitle = useMemo(() => (React.createElement("div", { className: "task-label", style: {
            width: '100%',
            alignItems: 'space-between',
            fontSize: isMobile ? '14px' : '16px',
            gap: isMobile && '6px',
        } },
        React.createElement("p", null, `${t('assignment.submissionStatus', {
            submissionStatus: submissionStatus || '',
        })} ${subjectId ? `- ${subjectId}` : ''}`),
        isDetail ? (React.createElement(SearchBar, { className: `${!isMobile ? 'search-bar-qna' : 'search-bar-mobile'}`, filterData: "member_name", handlerFilterList: handlerFilterList })) : (React.createElement("p", { className: "detail_txt", onClick: openAssignmentDetail }, t('common.detail'))))), [subjectId, handlerFilterList, submissionStatus]);
    const renderEmpty = useMemo(() => (React.createElement("div", { className: "empty_data", style: { color: Colors.light_grey } }, t(`assignment.noSubmit`))), []);
    const previousPage = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'detail',
            id: test_id,
        });
    };
    return (React.createElement(DSTL001DetailTableStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isDetail": isDetail, className: `field-global-001 ${isPopUp ? 'popup-table' : ''}`, style: { flexDirection: 'column', overflow: isDesktop && 'auto' } },
        React.createElement(Table, { className: "task-list-002", showIndex: (isTablet && isDetail) || isMobile ? false : true, isInifinite: false, page: page, data: testData, sortDefault: "member_id", showCount: count, pagingCount: 5, totalCount: totalCount, headTitleList: headTitleList, renderTitle: renderTitle, renderItem: (isTablet || isMobile) && isDetail ? renderTabletItem : renderItem, handlerMoreData: handlerMoreData, handlerUpdateList: handlerUpdateList, forceShowheader: (isTablet && isDetail) || isMobile ? false : true, renderEmpty: testData.length === 0 && isPopUp && renderEmpty }),
        isMobile && isDetail && (React.createElement(Button, { className: "btn_default xxs", styles: {
                buttonWrap: {
                    width: '100%',
                },
            }, text: t('common.complete'), onClick: previousPage }))));
});
