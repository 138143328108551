import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Button, FileInput, Table } from '../../../../../common';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../../Colors';
import { postChunk, postModifyReplay, postRegistReplay, } from '../../../../../server';
import { formatFileSize } from '../../functions';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { useMutation } from 'react-query';
import { getClassesId } from '../../../../base/classes';
import { useSelector } from 'react-redux';
import { getIsManager } from '../../../../base/account';
import { DownloadQueue } from '../../../DSTS006/DSTS006_List_Child/DownloadQueue';
const RegistFileTableStyled = styled.div `
	height: ${props => (props.$isMobile ? 'unset' : '100%')};
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;
	overflow: hidden;
	${props => !props.$onlyShowList
    ? props.$isMobile
        ? `width:100%;`
        : `
			flex: 1; 	
			width: 100%; `
    : `width: 100%;`}

	.fext-field {
		width: 100%;
		height: 100%;
	}

	.live-list-010 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		overflow: hidden;

		.scroll-content {
			flex: 1;
		}

		.body-item {
			overflow: hidden;
		}

		.item {
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			flex-shrink: 0;
			position: relative;
			${props => !props.$onlyShowList
    ? `
					`
    : `border-style: solid;
					border-color: var(--color-silver, #f3f4f8);
					border-width: 0px 0px 1px 0px;
					
					&.selected {
						p {
							color: var(--color-tur-p, #2d39aa);
							font-family: 'Noto Sans KR', sans-serif;
							font-weight: 700;
						}
					}`}

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);

				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);
			}

			.button {
				p {
					min-width: fit-content;
				}
			}

			.underline_blue {
				font-size: ${props => (props.$isDesktop ? '16px' : '14px')};
				line-height: 32px;
				color: var(--color-tur-p, #2d39aa);
				text-decoration: underline;
				font-family: 'Noto Sans KR', sans-serif;
				letter-spacing: -0.02em;
				font-weight: 400;
				position: relative;

				&.bold {
					font-family: 'Noto Sans KR', sans-serif;
					font-weight: 700 !important;
					font-size: ${props => (props.$isDesktop ? '17px' : '15px')};
				}
			}

			.underline_pink {
				p {
					color: var(--color-accent, #e7424c);
					text-decoration: underline;
				}
			}
		}
	}

	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.live-list-010 {
		${props => !props.$onlyShowList
    ? `
				min-height: 100px;
				`
    : `height: 100%;`}

		.item {
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px 0px 0px 0px;
			justify-content: space-between;

			p {
				font-size: var(--b4-font-size, 12px);
				line-height: 150%;
			}
		}

		.file-wrap {
			.bold {
				font-family: 'Noto Sans KR', sans-serif;
				font-weight: 700 !important;
				font-size: 14px;
			}

			.grey {
				color: var(--color-grey-l, #83868a);
			}
		}

		.empty_data {
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px 0px 0px 0px;
			padding-top: 16px;
		}
	}
	/* .mobile-full {
		padding: 6px 12px;
	} */
`;
const desktopStyles = css `
	.live-list-010 {
		${props => !props.$onlyShowList
    ? `
				min-height: 284px;
				`
    : `height: 100%;`}

		.item {
			justify-content: flex-start;

			p {
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
			}
		}
		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}
`;
const downloadQueue = new DownloadQueue();
export const RegistFileTable = React.memo(({ id, isRegist = false, onlyShowList = false, medias, previewId, setPreviewId, setRegistFile, setKeepMediaList, setResultFileId, isModify, keepMediaList, }) => {
    const { t } = useTranslation();
    const fileRef = React.useRef(null);
    const { isDesktop, isMobile } = useGlobalState();
    const isManager = useSelector((state) => getIsManager(state));
    const [fileData, setFileData] = React.useState([]);
    //파일 분할 업로드
    const chunkMutation = useMutation(postChunk);
    const replaysMutaiton = useMutation(postRegistReplay);
    const replaysUpdateMutaiton = useMutation(postModifyReplay);
    const [mediaIds, setMediaIds] = React.useState([]);
    const [resultId, setResultId] = React.useState();
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        const headTitleList = [
            { title: 'fileName', space: 2, sort: false },
            { title: 'size', space: onlyShowList ? 1 : 2, sort: false },
            ...(onlyShowList ? [] : [{ title: 'etc', space: 3, sort: false }]),
        ];
        const tableReadDataKeys = [
            { title: 'name', space: 2 },
            { title: 'size', space: onlyShowList ? 1 : 2 },
            ...(onlyShowList ? [] : [{ title: 'status', space: 3, sort: false }]),
        ];
        return { headTitleList, tableReadDataKeys };
    }, [onlyShowList, isRegist]);
    useEffect(() => {
        if (isModify)
            setResultId(undefined);
    }, [isModify]);
    useEffect(() => {
        if (previewId) {
            const previewMedia = medias.find(media => media.id === previewId);
            if (previewMedia &&
                (previewMedia.status === 'failed' || previewMedia.status === 'uploaded')) {
                alert('영상 업로드중인 파일입니다.');
                setPreviewId(undefined);
            }
        }
    }, [medias, previewId]);
    useEffect(() => {
        if (medias) {
            const newMedias = medias.filter(media => media.status !== 'failed' &&
                // media.status !== "uploaded" &&
                media.size !== 0);
            if (onlyShowList) {
                setFileData(newMedias);
                newMedias && newMedias.length > 0 && setPreviewId(newMedias[0]);
                return;
            }
            if ((isModify && !resultId) || !isModify)
                setFileData([]);
            setTimeout(() => {
                if (newMedias) {
                    if (keepMediaList.length > 0) {
                        setFileData(newMedias.filter(media => keepMediaList.includes(media.id) ? media : null));
                    }
                    else {
                        setFileData(newMedias);
                    }
                }
            }, 200);
        }
    }, [medias, isModify, resultId]);
    const onClick = async (item) => {
        if (isRegist) {
            setFileData(fileData.filter(data => data.fileName !== item));
            return;
        }
        if (item.id && item.name) {
            //await downloadContent(item.id);
            downloadQueue.enqueue(item);
        }
    };
    const deleteItem = (id, name) => {
        setFileData(fileData.filter(data => data.id !== id));
        // @ts-ignore
        setRegistFile(prevFile => prevFile.filter(file => file.name !== name));
        // @ts-ignore
        setKeepMediaList(prevKeepMediaList => prevKeepMediaList?.filter(media => {
            if (isModify) {
                return media !== id;
            }
            else {
                return media !== id[0];
            }
        }));
        // @ts-ignore
        setMediaIds(prevMediaId => prevMediaId?.filter(media => media !== id[0]));
    };
    const renderEmpty = useMemo(() => {
        if (fileData.length === 0) {
            return (React.createElement("div", { className: "empty_data" },
                React.createElement("p", null, "\uB4F1\uB85D\uB41C \uD30C\uC77C\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.")));
        }
    }, [fileData]);
    // useEffect(() => {
    // 	setKeepMedia(mediaIds);
    // }, [mediaIds]);
    // Item 가져오기
    const renderItem = (item, styles) => {
        return tableReadDataKeys.map((headItem, index) => isMobile && headItem.title === 'name' ? null : (React.createElement("div", { key: index, className: 'body-item', style: {
                flex: isMobile ? 'unset' : headItem.space,
                cursor: 'pointer',
            } }, headItem.title === 'status' ? (React.createElement("div", { className: "flex row", style: {
                gap: isManager ? 12 : 0,
                justifyContent: isMobile ? 'flex-end' : 'unset',
            } }, item[headItem.title] === 'converted' ||
            item[headItem.title] === '' ? (React.createElement(React.Fragment, null,
            !isRegist && (React.createElement("p", { className: previewId === item.id
                    ? 'underline_blue bold'
                    : 'underline_blue ', onClick: e => {
                    e.stopPropagation();
                    setPreviewId(previewId === item.id ? undefined : item.id);
                } }, "\uBBF8\uB9AC\uBCF4\uAE30")),
            React.createElement(Button, { className: isRegist ? 'underline_pink' : '', onClick: isRegist
                    ? () => deleteItem(item.id, item.name)
                    : () => onClick(item), option: isRegist
                    ? null
                    : isManager
                        ? {
                            buttonBeforeIcon: {
                                show: true,
                                name: 'file-down',
                                size: 18,
                                color: Colors.dark_blue,
                            },
                        }
                        : null, text: isRegist ? t('common.delete') : undefined }))) : (React.createElement("p", null, "\uC5C5\uB85C\uB4DC\uC911")))) : headItem.title === 'size' ? (isMobile ? (React.createElement("div", { className: "file-wrap" },
            React.createElement("p", { className: "overText2 bold" }, item['name']),
            React.createElement("p", { className: "grey" }, formatFileSize(item.size)))) : (React.createElement("p", null, formatFileSize(item.size)))) : (React.createElement("p", { className: "overText2" }, item[headItem.title])))));
    };
    const uploadVideoChunks = async (file, chunkSizeMB, new_media_id, newFileData, filesArray) => {
        const chunkSize = chunkSizeMB * 1024 * 1024;
        const fileSize = file.size;
        let offset = 0;
        const uploadChunk = async (chunk, index) => {
            const formData = new FormData();
            // formData.append('type', 'video');
            // formData.append('id', new_media_id);
            formData.append('file', chunk);
            try {
                const response = await chunkMutation.mutateAsync({
                    id: new_media_id,
                    data: formData,
                });
            }
            catch (error) {
                console.error(`Error uploading chunk ${index}:`, error);
            }
        };
        const readNextChunk = () => {
            const fileReader = new FileReader();
            const slice = file.slice(offset, offset + chunkSize);
            fileReader.onload = async (event) => {
                const chunk = event.target?.result;
                if (chunk) {
                    await uploadChunk(new Blob([chunk]), offset / chunkSize);
                    offset += chunkSize;
                    if (offset < fileSize) {
                        readNextChunk();
                    }
                    else {
                        // 빈 파일로 최종 요청
                        await uploadChunk(new Blob([]), offset / chunkSize);
                        // @ts-ignore
                        setRegistFile(prevFiles => [...prevFiles, ...filesArray]);
                        setFileData(prevFileData => [...prevFileData, ...newFileData]);
                        APP.eventManager.publish('SHOW_LOADING', false);
                    }
                }
            };
            fileReader.readAsArrayBuffer(slice);
        };
        readNextChunk();
    };
    useEffect(() => {
        APP.eventManager.subscribe('CONTENT_STATUS_ACCEPT', acceptStatus);
        return () => {
            APP.eventManager.unsubscribe('CONTENT_STATUS_ACCEPT', acceptStatus);
        };
    }, [fileData]);
    const acceptStatus = async (data) => {
        const { status, contents_id, class_id, contents_type } = data;
        if (status === 'converted' && contents_type === 'replay') {
            setFileData(prevData => prevData.map(data => {
                return data.id[0] === contents_id ? { ...data, status: 'converted' } : data;
            }));
            APP.eventManager.publish('REPLAY_DETAIL_REFRESH');
        }
    };
    const onChangeFile = async (e) => {
        const files = e.target.files;
        let response;
        if (files) {
            const filesArray = Array.from(files); // Convert FileList to Array
            APP.eventManager.publish('SHOW_LOADING', true);
            try {
                const selectedFile = e.target.files?.[0];
                if (!selectedFile) {
                    throw new Error('No file selected');
                }
                if (fileData.length === 0 && !isModify) {
                    response = await replaysMutaiton.mutateAsync({
                        class_id: getClassesId(),
                        open_type: 'deleted',
                        files: [
                            {
                                name: selectedFile.name,
                                size: selectedFile.size,
                            },
                        ],
                    });
                    setResultId(response.result.id);
                    setResultFileId(response.result.id);
                    setMediaIds([response.result.new_media_id[0]]);
                }
                else {
                    response = await replaysUpdateMutaiton.mutateAsync({
                        id: isModify ? id : resultId,
                        data: {
                            open_type: 'deleted',
                            media_id: isModify && medias
                                ? medias.map(data => data.id)
                                : keepMediaList,
                            files: [
                                {
                                    name: selectedFile.name,
                                    size: selectedFile.size,
                                },
                            ],
                        },
                    });
                    setMediaIds(prevMediaId => [
                        ...prevMediaId,
                        response.result.new_media_id[0],
                    ]);
                    if (isModify)
                        setResultId(response.result.new_media_id);
                }
                // @ts-ignore
                setKeepMediaList(prevKeepMediaList => prevKeepMediaList
                    ? [...prevKeepMediaList, response.result.new_media_id[0]]
                    : [response.result.new_media_id[0]]);
                if (response.result.new_media_id) {
                    const newFileData = filesArray.map(file => ({
                        id: response.result.new_media_id,
                        name: file.name,
                        size: file.size,
                        status: 'uploading',
                    }));
                    await uploadVideoChunks(selectedFile, 10, response.result.new_media_id[0], newFileData, filesArray); // 10MB씩 나눔
                }
                else {
                    console.error('Medi error');
                }
            }
            catch (error) {
                alert(error);
                console.error('Failed to post :', error);
                APP.eventManager.publish('SHOW_LOADING', false);
            }
            finally {
                if (fileRef.current) {
                    fileRef.current.value = '';
                }
            }
        }
    };
    const handlerSelectItem = (item) => {
        setPreviewId(item);
    };
    return (React.createElement(RegistFileTableStyled, { className: onlyShowList ? 'show_popup_registFile' : '', "$isDesktop": isDesktop, "$isMobile": isMobile, "$onlyShowList": onlyShowList },
        React.createElement("div", { className: "field-global-001 file-wrap" },
            !onlyShowList && (React.createElement("div", { className: "task-label task-label-btn", style: { flexDirection: 'row' } },
                React.createElement("p", null, t('conference.fileRegist')),
                isRegist && (React.createElement(FileInput, { ref: fileRef, accept: "video/*", id: "registFileTable", onChange: onChangeFile },
                    React.createElement(Button, { className: `btn_white ${isMobile ? 'mobile-full' : 'xmd'}`, text: "\uD30C\uC77C \uB4F1\uB85D" }))))),
            React.createElement("div", { className: "fext-field" },
                React.createElement(Table, { className: "live-list-010", showIndex: isMobile ? false : true, showCheckBox: false, isInifinite: true, headTitleList: headTitleList, selectedId: onlyShowList ? previewId : undefined, data: fileData, renderEmpty: renderEmpty, page: 0, totalCount: fileData.length, renderItem: renderItem, forceShowheader: onlyShowList ? false : isMobile ? false : true, handlerSelectItem: onlyShowList ? handlerSelectItem : undefined })))));
});
