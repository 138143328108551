import React from 'react';
import { styled } from 'styled-components';
import { useGlobalState } from '../../../../GlobalStateProvider';
const QnAStatisticStyled = styled.div `
	background: #fdfdff;
	border-radius: 8px;
	border: 1px solid var(--pur-l, #c9bbf6);
	padding: ${({ $isMobile, $isTablet }) => $isMobile ? '12px 20px' : $isTablet ? '12px 80px' : '12px 140px'};
	display: flex;
	flex-direction: row;
	gap: ${({ $isMobile, $isTablet }) => ($isMobile ? '10px' : $isTablet ? '15px' : '20px')};
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;

	.entire,
	.waiting,
	.completion {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: center;
		flex: 1;
		min-width: 52px;
		position: relative;
	}

	.label {
		color: var(--color-grey, #666666);
		text-align: center;
		font-family: var(--b5-font-family, 'Noto Sans KR', sans-serif);
		font-size: ${({ $isMobile }) => ($isMobile ? '12px' : '12px')};
		line-height: ${({ $isMobile }) => ($isMobile ? '20px' : '22px')};
		letter-spacing: -0.02em;
		font-weight: 400;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.value,
	.value2 {
		text-align: center;
		font-family: var(--h4-font-family, 'Noto Sans KR', sans-serif);
		font-size: ${({ $isMobile }) => ($isMobile ? '16px' : '20px')};
		line-height: ${({ $isMobile }) => ($isMobile ? '28px' : '32px')};
		letter-spacing: -0.02em;
		font-weight: 700;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.value {
		color: var(--pur, #a08ede);
	}

	.value2 {
		color: var(--color-pink, #f46790);
	}

	.divider {
		background: #d9d9d9;
		flex-shrink: 0;
		width: 1px;
		height: 24px;
		position: relative;
	}
`;
export const QnAStatistic = React.memo(({ totalCount, openCount, closedCount, }) => {
    const { isMobile, isTablet } = useGlobalState();
    return (React.createElement(QnAStatisticStyled, { className: "status-panel", "$isMobile": isMobile, "$isTablet": isTablet },
        React.createElement("div", { className: "entire" },
            React.createElement("div", { className: "label" }, "\uC804\uCCB4"),
            React.createElement("div", { className: "value" }, totalCount)),
        React.createElement("div", { className: "divider" }),
        React.createElement("div", { className: "entire" },
            React.createElement("div", { className: "label" }, "\uB2F5\uBCC0\uB300\uAE30"),
            React.createElement("div", { className: "value2" }, openCount)),
        React.createElement("div", { className: "divider" }),
        React.createElement("div", { className: "entire" },
            React.createElement("div", { className: "label" }, "\uB2F5\uBCC0\uC644\uB8CC"),
            React.createElement("div", { className: "value" }, closedCount))));
});
