import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../../../common';
const MobilePageTabStyled = styled.div `
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.mobile-btn-wrap {
		padding: 0 20px 20px 20px;

		.btn-container {
			margin: 0;
		}
	}

	.cancel-wrap {
		display: flex;
		gap: 4px;
		padding-bottom: 12px;

		.exit-tab {
			gap: 4px;
			.overText {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: 'Noto Sans KR', sans-serif;
				font-size: 16px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 700;
				position: relative;
			}
		}
	}

	.hr {
		margin-bottom: 14px;
	}

	.page-tab {
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}

	.tab-item {
		height: unset;
	}

	.page-tab-button {
		padding-bottom: 4px;
	}

	&.tab-nav-wrap {
		.contents {
			width: 100%;
			overflow: auto;
			flex: 1;

			.contens-wrap {
				display: flex;
				padding: 20px;
				width: 100%;
				flex: 1;
				min-height: 100%;
			}
			.tab-item {
				width: 100%;
				flex: 1;
				min-height: 100%;
				height: unset !important;
			}
		}
	}

	.tab-nav {
		background: #ffffff;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		display: flex;
		flex-direction: row;
		// gap: 32px;
		gap: 18px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.label {
			&.on {
				border-color: var(--tur-d, #479da6);
				border-style: solid;
				border-width: 0px 0px 2px 0px;

				.label2 {
					color: var(--tur-d, #479da6);
					font-weight: var(--b4-font-weight, 700);
					font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
				}
			}

			flex: 1;
			cursor: pointer;
			padding: 0px 12px 12px 12px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
		}

		.label2 {
			text-align: center;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 22px);
			letter-spacing: var(--b4-letter-spacing, -0.02em);
			font-weight: var(--b4-font-weight, 400);

			position: relative;
			white-space: pre;
		}
	}
`;
export const MobilePageTab = React.memo(({ isTabMode, activeTabIndex, tabList, content, button, setActiveTabIndex, }) => {
    React.useEffect(() => {
        const index = localStorage.getItem('rememberTabIndex');
        if (index) {
            const tabIndex = JSON.parse(index);
            setActiveTabIndex(Number(tabIndex));
            localStorage.removeItem('rememberTabIndex');
        }
    }, []);
    if (isTabMode) {
        return (React.createElement(MobilePageTabStyled, { className: "tab-nav-wrap" },
            React.createElement("div", { className: "tab-nav" }, Array.from({ length: tabList.length }, (_, index) => (React.createElement("div", { key: index, className: `label ${activeTabIndex === index ? 'on' : 'off'}`, onClick: setActiveTabIndex
                    ? () => {
                        setActiveTabIndex(index);
                    }
                    : undefined },
                React.createElement("div", { className: "label2" }, tabList[index].title))))),
            React.createElement("div", { className: "contents" },
                React.createElement("div", { className: "contens-wrap" }, content)),
            button));
    }
    return (React.createElement(MobilePageTabStyled, null,
        React.createElement("div", { className: "scroll-content" },
            content,
            React.createElement("div", { className: "page-tab-button" },
                React.createElement("div", { className: "hr", style: { marginTop: '14px' } }),
                React.createElement("div", { className: "page-tab" }, Array.from({ length: tabList.length }, (_, index) => (React.createElement(Icon, { key: index, icon: `page_${activeTabIndex === index ? 'on' : 'off'}`, width: 40 })))),
                button))));
});
