import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../../Colors';
import { Icon } from '../../../../../common';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { EvaluationTable } from './EvaluationTable';
const ScoreStyled = styled.div `
	&.score-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		width: 100%;
		height: 100%;

		.task-label {
			display: flex;
			flex-direction: row;
			gap: 8px;
			width: 100%;
		}

		&.empty {
			flex: 1;
			align-items: center;
			justify-content: center;
		}
	}

	.class-container {
		width: 100%;
		height: fit-content;
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.class-container {
		padding: 12px 0;
	}

	.table-title-wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.notice-txt {
		color: var(--color-accent, #e7424c);
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 10px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		position: relative;
		// width: 184px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;
const tabletStyles = css `
	&.score-container {
		&.empty {
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
		}
	}

	.class-container {
		padding: 0px 0px 6px 0px;
		gap: 0px;
	}

	.table-title-wrap {
		padding: 0px 0px 6px 0px;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.task-label {
			width: min-content;
			display: flex;
			gap: 8px;
		}
	}

	.notice-txt {
		color: var(--color-accent, #e7424c);
		text-align: right;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 10px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		position: relative;
		// width: 184px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;
const desktopStyles = css `
	&.score-container {
		&.empty {
			padding: 24px 0px;
		}
	}

	.class-container {
		padding: 12px 0;
	}
`;
// 평가 항목을 선택한 경우의 컴포넌트
export const ScoreContainer = React.memo(({ isDefault, subject, initEvalData, evalData, setEvalData, isModify = false, isPopup = false, }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile, isDesktop } = useGlobalState();
    const [totalScore, setTotalScore] = React.useState([]);
    const handlerUpdateScore = (data) => {
        setTotalScore(prevTotalScore => {
            const prevIndex = prevTotalScore.findIndex(i => i.id === data.id);
            if (prevIndex !== -1) {
                const updatedScores = [...prevTotalScore];
                updatedScores[prevIndex] = data;
                return updatedScores;
            }
            else {
                return [...prevTotalScore, data];
            }
        });
    };
    const [saveIsDefault, setSaveIsDefault] = React.useState(isDefault);
    useEffect(() => {
        if (isDefault !== saveIsDefault) {
            setEvalData([]);
        }
        setSaveIsDefault(isDefault);
    }, [isModify, isDefault]);
    if (!evalData || (evalData && evalData.length === 0)) {
        return isMobile ? (React.createElement(ScoreStyled, { "$isTablet": isTablet, "$isMobile": isMobile, className: "score-container empty", style: { color: Colors.light_grey } }, t('assignment.selectEvalItem'))) : (isPopup ? (React.createElement(React.Fragment, null)) : (React.createElement("div", { className: "test-item-notice" },
            React.createElement(Icon, { icon: "arrow_in_circle", size: "42" }),
            React.createElement("p", null, "\uAC01 \uD56D\uBAA9\uBCC4\uB85C \uD544\uC694\uD55C \uC815\uBCF4\uB97C \uC791\uC131\uD574\uC8FC\uC138\uC694"))));
    }
    const renderTableTitle = (item, score) => {
        return (React.createElement("div", { className: "task-label", style: { width: isMobile && '100%' } },
            React.createElement("p", { className: "overText" }, `${subject === 'assignment.select' || !isDefault
                ? `${item.key}`
                : subject === 'assignment.history' ||
                    subject === 'assignment.character'
                    ? `${t(`${subject}`)}`
                    : `${t(`${subject}`)} (${item.key})`}`),
            React.createElement("p", null, `- ${score}점`)));
    };
    return (React.createElement(ScoreStyled, { "$isTablet": isTablet, "$isMobile": isMobile, className: "score-container" }, evalData.length > 0 &&
        evalData.map((item, index) => {
            const initData = initEvalData.find(i => i.key === item.key);
            const score = totalScore.find(i => i.id === item.key)?.total_score || 0;
            const cloneData = _.cloneDeep(item, initData?.data);
            return (React.createElement("div", { className: "field-global-001 class-container", key: index, style: { flexDirection: 'column', alignItems: 'flex-start' } },
                isDesktop ? (renderTableTitle(item, score)) : (React.createElement("div", { className: "table-title-wrap" },
                    renderTableTitle(item, score),
                    React.createElement("p", { className: "notice-txt" }, t('assignment.noticeText')))),
                React.createElement("div", { className: "fext-field" },
                    React.createElement(EvaluationTable, { subject: subject, isDefault: isDefault, item: cloneData?.key, defaultEvalData: cloneData?.data, setEvalData: setEvalData, setTotalScore: handlerUpdateScore }))));
        })));
});
