import React, { useEffect, useMemo, useState } from 'react';
import { useSubmitStatus } from '../../../../../server';
import { Button, Table } from '../../../../../common';
import { Colors } from '../../../../../Colors';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { TOGGLE_POPUP } from '../../../../base/popup';
import { useGlobalState } from '../../../../../GlobalStateProvider';
const UpperMemberListStyled = styled.div `
	${props => (props.$isTablet ? tabletStyles : desktopStyles)}
`;
const tabletStyles = css `
	display: flex;
	flex-direction: column;
	gap: 0px;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;
	overflow: hidden;

	.popup-list-009 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		overflow: auto;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 12px 10px 12px 10px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 10px 12px 10px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;

			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	&.upper-member-list {
		gap: 24px;
		.btn-container {
			width: 100%;
			justify-content: center;
		}
	}
`;
const desktopStyles = css `
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	justify-content: center;
	align-items: center;

	.popup-list-009 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: auto;
		flex: 1;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 16px 12px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}
`;
export const UpperMemberList = React.memo(({ test_id, subject_item, precedence, }) => {
    const { t } = useTranslation();
    const { isTablet } = useGlobalState();
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            { title: 'rankings', space: 1, sort: false },
            { title: 'nameId', space: 2, sort: false },
            { title: 'grades', space: 1, sort: false },
        ];
        const tableReadDataKeys = [
            { title: 'number', space: 1 },
            { title: 'member_name', space: 2 },
            { title: 'score', space: 1 },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, []);
    const { data, refetch } = useSubmitStatus({
        precedence,
        id: test_id,
        subject_item,
    });
    useEffect(() => {
        refetch();
    }, [precedence, subject_item, test_id]);
    useEffect(() => {
        if (data) {
            setTestData(data.items);
            setTotalCount(data.total_count);
            return;
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    const renderEmpty = useMemo(() => {
        return (React.createElement("div", { className: "flex center body3 sel_eval_item", style: { color: Colors.light_grey, width: '100%', height: '100%' } }, t('assignment.noSubmitPersonGuid')));
    }, []);
    const renderItem = (item, styles) => {
        return tableReadDataKeys.map(headItem => (React.createElement("div", { key: headItem.title, className: "body-item", style: { flex: headItem.space, ...styles?.bodyItem, overflow: 'hidden' } },
            React.createElement("p", { className: "overText" }, item[headItem.title] === -1 ? '미제출' : item[headItem.title]))));
    };
    const onCancel = () => APP.eventManager.publish(TOGGLE_POPUP);
    return (React.createElement(UpperMemberListStyled, { "$isTablet": isTablet, className: "upper-member-list" },
        React.createElement(Table, { className: "popup-list-009", showIndex: false, isInifinite: true, page: 0, data: testData, renderEmpty: testData && testData.length < 0 && renderEmpty, totalCount: 0, headTitleList: headTitleList, renderItem: renderItem }),
        React.createElement("div", { className: "btn-container" },
            React.createElement(Button, { className: "btn_default sm", text: t('common.okay'), onClick: onCancel }))));
});
