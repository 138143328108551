import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../../common';
import { FileDown } from '../../../../common/option/FileDown';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { hideID, hideName } from '../DTST004_List_Child/QnAItem';
export const QnAContentsHead = React.memo(({ data, isQuestion, children, isTeacher, }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    const show_list = useMemo(() => isQuestion
        ? [
            ['register', 'creation_time'],
            ['category', 'attachFile'],
        ]
        : [['register', 'creation_time'], ['attachFile']], [isQuestion]);
    const getData = (type) => {
        switch (type) {
            case 'register':
                return isTeacher ? `${data.author_name} (${data.author_idf})` : `${hideName(data.author_name)} (${hideID(data.author_idf)})`;
            case 'creation_time':
                return moment(data.creation_time).format('YYYY-MM-DD, HH:mm');
            case 'category':
                return t(`supportRoom.${data.category}`);
            case 'attachFile':
                return React.createElement(FileDown, { files: data.files, isOpen: true });
            default:
                return '-';
        }
    };
    return (React.createElement("div", { className: "sub-info" },
        React.createElement("div", { className: isMobile ? 'icon-question-mobile' : 'icon-question' },
            React.createElement("div", { className: "ellipse-2126" },
                React.createElement(Icon, { icon: isQuestion ? 'question-icon' : 'answer-icon', width: 18, height: 14 }),
                React.createElement("div", { className: "div" }, isQuestion ? '질문' : '답변완료'))),
        React.createElement("div", { className: "frame-987343-wrap" }, show_list.map((list, index) => (React.createElement("div", { className: `frame-987343 ${index === 0 ? 'one' : 'two'}`, key: index }, list.map((item, idx) => (React.createElement("div", { className: `sub-item ${item}`, key: idx },
            React.createElement("div", { className: "label" }, t(`supportRoom.${item}`)),
            React.createElement("div", { className: "divider" }),
            React.createElement("div", { className: "value overText" }, getData(item))))))))),
        children));
});
