import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styled-components';
import { FileDown } from '../../../../../common/option/FileDown';
import { useGlobalState } from '../../../../../GlobalStateProvider';
const TaskInfoContainerStyled = styled.div `
	.frame-987343 {
		display: flex;
		flex-direction: column;
		gap: 8px;
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
    	white-space: nowrap;
    	text-overflow: ellipsis;
		${({ $isMobile }) => $isMobile
    ? css ``
    : css `
						width: 400px;
				  `};
	}
	.date {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}
	.label {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: var(--h5-font-family, 'Noto Sans KR', sans-serif);
		letter-spacing: var(--h5-letter-spacing, -0.02em);
		font-weight: var(--h5-font-weight, 700);
		position: relative;
		text-align: left;

		${({ $isMobile, $isTablet }) => $isMobile
    ? css `
						font-size: var(--h5-font-size, 16px);
						line-height: var(--h5-line-height, 24px);
						width: 65px;
				  `
    : $isTablet
        ? css `
						font-size: var(--h5-font-size, 16px);
						line-height: var(--h5-line-height, 24px);
						width: fit-content;
				  `
        : css `
						font-size: var(--h5-font-size, 16px);
						line-height: var(--h5-line-height, 24px);
						width: fit-content;
				  `}
	}
	.divider {
		background: var(--color-grey-w, #b6bac0);
		flex-shrink: 0;
		width: 1px;
		height: 14px;
		position: relative;
	}
	.value {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
		letter-spacing: var(--b3-letter-spacing, -0.02em);
		font-weight: var(--b3-font-weight, 400);
		position: relative;
		overflow: hidden;
	    white-space: nowrap;
    	text-overflow: ellipsis;
		width: 300px;

		${({ $isMobile, $isTablet }) => $isMobile
    ? css `
						font-size: var(--b5-font-size, 14px);
						line-height: var(--b5-line-height, 24px);
				  `
    : $isTablet
        ? css `
						font-size: var(--b3-font-size, 16px);
						line-height: var(--b3-line-height, 32px);
				  `
        : css `
						font-size: var(--b3-font-size, 16px);
						line-height: var(--b3-line-height, 32px);
				  `}
	}

	.file_values {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;

		${({ $isMobile }) => $isMobile
    ? `
			flex-direction: column;
		`
    : ``}

		button {
			p {
				text-decoration: underline;
			}
		}
	}
	.view {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}
	.frame-987344 {
		display: flex;
		flex-direction: column;
		gap: 8px;
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		${({ $isMobile }) => $isMobile
    ? css ``
    : css `
						flex: 1;
				  `};
	}
`;
export const TaskInfoContainer = ({ isTest, item, }) => {
    const { t } = useTranslation();
    const assignmentRef = useRef(null);
    const { isMobile, isTablet } = useGlobalState();
    const [className, setClassName] = useState(''); // 수업명
    const [startDate, setStartDate] = useState(''); // 시작일
    const [endDate, setEndDate] = useState(''); // 마감일
    const [duration, setDuration] = useState(0); // 시험 시간
    const [questionType, setQuestionType] = useState(''); // 문제 유형
    const [attachFileList, setAttachFileList] = useState([]); // 첨부파일
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (!item)
            return;
        const questionType = item.type === 'OMR (기본형)' || item.type === 'OMR (자유형)'
            ? item.type +
                `${item.subject !== '' ? ' - ' + t(`assignment.${item.subject}`) : ''}`
            : item.type;
        setClassName(item.conference_name);
        setStartDate(moment(item.startline).format(isTest ? 'YYYY-MM-DD' : 'YYYY-MM-DD, HH:mm'));
        setDuration(item.duration);
        setEndDate(moment(item.deadline).format('YYYY-MM-DD, HH:mm'));
        setQuestionType(questionType);
        setAttachFileList(item.attachments);
        item.status === 'open' ? setIsOpen(true) : setIsOpen(false);
    }, [item]);
    useEffect(() => {
        if (assignmentRef.current)
            assignmentRef.current.value = item.title;
    }, [item.title]);
    if (isTest) {
        return (React.createElement(TaskInfoContainerStyled, { className: "sub-info", "$isMobile": isMobile, "$isTablet": isTablet },
            React.createElement("div", { className: "frame-987343" },
                React.createElement("div", { className: "date" },
                    React.createElement("div", { className: "label" }, t('assignment.class_name')),
                    React.createElement("div", { className: "divider" }),
                    React.createElement("div", { className: "value" }, className || '-')),
                React.createElement("div", { className: "view" },
                    React.createElement("div", { className: "label" }, "\uC9C4\uD589\uC77C"),
                    React.createElement("div", { className: "divider" }),
                    React.createElement("div", { className: "value" }, startDate))),
            React.createElement("div", { className: "frame-987344" },
                React.createElement("div", { className: "date" },
                    React.createElement("div", { className: "label" }, "\uD480\uC774\uC2DC\uAC04"),
                    React.createElement("div", { className: "divider" }),
                    React.createElement("div", { className: "value" },
                        duration,
                        " \uBD84")),
                React.createElement("div", { className: "attachedfile" },
                    React.createElement("div", { className: "date" },
                        React.createElement("div", { className: "label" }, "\uCCA8\uBD80\uD30C\uC77C"),
                        React.createElement("div", { className: "file_values" },
                            React.createElement(FileDown, { isOpen: isOpen, files: attachFileList })))))));
    }
    else {
        return (React.createElement(TaskInfoContainerStyled, { className: "sub-info", "$isMobile": isMobile, "$isTablet": isTablet },
            React.createElement("div", { className: "frame-987343" },
                React.createElement("div", { className: "view" },
                    React.createElement("div", { className: "label" }, "\uC2DC\uC791\uC77C"),
                    React.createElement("div", { className: "divider" }),
                    React.createElement("div", { className: "value" }, startDate)),
                React.createElement("div", { className: "view" },
                    React.createElement("div", { className: "label" }, "\uB9C8\uAC10\uC77C"),
                    React.createElement("div", { className: "divider" }),
                    React.createElement("div", { className: "value" }, endDate))),
            React.createElement("div", { className: "frame-987344" },
                React.createElement("div", { className: "date" },
                    React.createElement("div", { className: "label" }, "\uCD9C\uC81C \uC720\uD615"),
                    React.createElement("div", { className: "divider" }),
                    React.createElement("div", { className: "value" }, questionType)),
                React.createElement("div", { className: "attachedfile" },
                    React.createElement("div", { className: "date" },
                        React.createElement("div", { className: "label" }, "\uCCA8\uBD80\uD30C\uC77C"),
                        React.createElement("div", { className: "file_values" },
                            React.createElement(FileDown, { isOpen: isOpen, files: attachFileList })))))));
    }
};
