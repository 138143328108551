import { t } from 'i18next';
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../Colors';
import { Button, Icon, InputElement } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { TOGGLE_POPUP } from '../../../base/popup';
import { ChoiceMemberList } from './ChoiceMemberTable';
import { SendMembersTable } from './SendMembersTable';
import { UploadPopUp } from './UploadPopUp';
const OpenMemeberListStyled = styled.div `
	height: 100%;
	.btn-container {
		flex-direction: column;
		justify-content: center;

		.btn_line_light_tur {
			color: var(--color-tur, #3ab2bf);
		}
	}

	.disable {
		background-color: var(--color-grey-c, #e6e8ed);
	}

	${props => (!props.$isMobile ? desktopStyles : mobileStyles)}
`;
const mobileStyles = css `
	width: 100%;
	gap: 16px;
	display: flex;
	flex-direction: column;

	.frame-987338 {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.open-header {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		padding: 0px 0px 12px 0px;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: space-between;
		flex-shrink: 0;
		position: relative;
	}
	.title-name {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h5-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 24px);
		letter-spacing: var(--h5-letter-spacing, -0.02em);
		font-weight: var(--h5-font-weight, 700);
		position: relative;
		width: 248px;
	}

	.member-table-wrap {
		display: flex;
		flex-direction: column;
		gap: 12px;
		flex: 1;
		overflow: hidden;

		.paging {
			padding-top: 0 !important;
		}
	}
	.header {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.user-list {
		width: 100%;
		flex: 1;
		overflow: hidden;
	}

	.select-members-wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		row-gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}
	.select-members {
		background: #ecf7f8;
		border-radius: 12px;
		padding: 20px 0px 20px 0px;
		display: flex;
		flex-direction: column;
		gap: 4px;
		row-gap: 0px;
		align-items: center;
		justify-content: center;
		flex: 1;
		position: relative;
	}

	.button {
		p {
			font-size: 12px;
		}
	}

	.btn_line_light_tur {
		p {
			color: var(--color-black, #101010);
		}
	}

	.mobile-full {
		border-radius: 6px;
		justify-content: center;

		p {
			justify-content: center;
			width: unset;
		}

		&.text-tur {
			p {
				color: var(--tur, #3ab2bf);
			}
		}

		&.text-pink {
			p {
				color: var(--color-pink, #f46790);
			}
		}
	}
`;
const desktopStyles = css `
	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		gap: 40px;
	}

	width: 80%;
	background: var(--color-white, #ffffff);
	border-radius: 10px;
	border-style: solid;
	border-color: var(--color-grey-c, #e6e8ed);
	border-width: 1px;
	padding: 26px;
	height: 80%;
	z-index: 1;
	display: flex;
	flex-direction: column;
	gap: ${props => (props.$isTablet ? '16px' : '32px')};
	margin: 20px;

	overflow: hidden;

	.user-list {
		width: 100%;
		min-height: 500px;
	}

	.btn-container {
		margin: auto 0;
		.md_round {
			padding: 4px 18px;
			p {
				min-width: unset;
				line-height: var(--b4-line-height, 28px);
			}
		}
	}

	.member-wrap {
		display: flex;

		gap: 20px;
	}
`;
export const OpenMemeberList = React.memo(({ receiverMemberList, setReceiverMemberList, setSendMemberSetting, sendMemberSetting, setOpenMemberList, }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    // 참여자 목록
    const [memberData, setMemberData] = useState([]);
    // 삭제 참여자 목록
    const [deleteMemberList, setDeleteMemberList] = useState([]);
    const [sendNumberValue, setSendNumberValue] = React.useState(''); //발신번호 checkBox
    const validatePhoneNumber = phoneNumber => {
        // 한국 전화번호 형식 검증: 010-1234-5678, 01012345678, 011-123-4567, 016-123-4567 등
        const phoneRegex = /^(01[016789]-?\d{3,4}-?\d{4})$/;
        return phoneRegex.test(phoneNumber);
    };
    const handleChange = value => {
        if (/^[\d]*$/.test(value)) {
            setSendNumberValue(value);
        }
        else if (/^[\d-]*$/.test(value)) {
            alert(t('sendSMS.excludeBar'));
        }
        else {
            alert(t('sendSMS.onlyNumber'));
        }
    };
    //전화번호 추가
    const handlerAdd = () => {
        if (!validatePhoneNumber(sendNumberValue)) {
            return alert(t('sendSMS.retypeNumber'));
        }
        if (receiverMemberList.some(item => item.phone_number === sendNumberValue)) {
            return alert(t('sendSMS.invalidPhoneNumber'));
        }
        // @ts-ignore
        setReceiverMemberList(prevReceiverMemberList => {
            const list = [
                ...(prevReceiverMemberList ? [...prevReceiverMemberList] : []),
                {
                    id: sendNumberValue,
                    creation_time: null,
                    status: 'inactive',
                    tenant_id: '',
                    level: '',
                    level_name: '',
                    identifier: null,
                    teacher_code: '',
                    phone_number: sendNumberValue,
                    nickname: sendNumberValue,
                },
            ];
            return list;
        });
        setSendNumberValue('');
    };
    //엑셀 업로드 팝업창 이동
    const hadlerUpload = () => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            className: 'send-popup',
            component: UploadPopUp,
            componentProps: {
                setReceiverMemberList: setReceiverMemberList,
            },
            width: 580,
            height: 410,
            title: '엑셀 업로드',
            isFit: true,
        });
    };
    const mergeReceiverMemberList = (receiverMemberList, memberData) => {
        // Create a map of existing members by their 'id'
        const memberMap = new Map(receiverMemberList?.map(member => [member.id, member]));
        // Update the map with new members, merging if 'id' already exists
        memberData.forEach(newMember => {
            if (memberMap.has(newMember.id)) {
                // Merge existing and new member data (new member data overwrites existing)
                // @ts-ignore
                memberMap.set(newMember.id, { ...memberMap.get(newMember.id), ...newMember });
            }
            else {
                // Add new member to the map
                memberMap.set(newMember.id, newMember);
            }
        });
        // Convert the map back to an array
        return Array.from(memberMap.values());
    };
    // 참여자 추가
    const handlerAddMember = () => {
        const has_phone_number = memberData.filter(item => item.phone_number || item.phone_number !== '-');
        // @ts-ignore
        setReceiverMemberList(prevReceiverMemberList => mergeReceiverMemberList(prevReceiverMemberList, has_phone_number));
        setMemberData([]);
        // ch
        APP.eventManager.publish('UPDATE_TABLE_ITEM', { className: 'sms-member-list' });
        APP.eventManager.publish('UPDATE_TABLE_ITEM', { className: 'sms-choice-list' });
        APP.eventManager.publish('CLEAR_CHECK_LIST');
    };
    const handlerDeleteMember = () => {
        setReceiverMemberList(receiverMemberList.filter(item => !deleteMemberList.includes(item)));
        setDeleteMemberList([]);
        APP.eventManager.publish('UPDATE_TABLE_ITEM', { className: 'sms-choice-list' });
    };
    const handlerAddMemberPage = () => {
        setSendMemberSetting('add');
    };
    const handlerDeleteMemberPage = () => {
        setSendMemberSetting('delete');
    };
    const renderExelUpload = useMemo(() => {
        return (React.createElement(Button, { className: `btn_white ${isMobile ? 'mobile-full' : 'xmd'}`, text: "\uC5D1\uC140 \uC5C5\uB85C\uB4DC", onClick: hadlerUpload }));
    }, [isMobile]);
    const renderCloseButton = useMemo(() => {
        return (React.createElement(Button, { option: {
                buttonBeforeIcon: {
                    show: true,
                    name: 'close',
                    size: 24,
                },
            }, onClick: setOpenMemberList ? () => setOpenMemberList(false) : undefined }));
    }, []);
    const renderMembersTableHeader = useMemo(() => {
        return (React.createElement("div", { className: "header" },
            React.createElement("div", { className: "field-global-001 sms-wrap" },
                React.createElement("div", { className: "fext-field" },
                    React.createElement(InputElement, { className: "phone-input-element", type: "text", placeholder: "\uC804\uD654\uBC88\uD638\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694", value: sendNumberValue, onChange: handleChange })),
                React.createElement(Button, { className: "btn_default xmd", text: "\uCD94\uAC00", onClick: handlerAdd, styles: {
                        buttonWrap: {
                            maxWidth: isMobile && '75px',
                        },
                    } }),
                !isMobile && renderExelUpload),
            !isMobile && renderCloseButton));
    }, [sendNumberValue, isMobile]);
    const renderMobileMembersTable = useMemo(() => {
        const isReceiver = sendMemberSetting === 'add' ? false : true;
        return (React.createElement(React.Fragment, null,
            isReceiver ? (React.createElement(ChoiceMemberList, { memberList: receiverMemberList, setDeleteMemberList: setDeleteMemberList })) : (React.createElement(SendMembersTable, { receiverMemberList: receiverMemberList, isReceiver: isReceiver, setMemberData: setMemberData })),
            React.createElement("div", { className: "frame-987338" },
                React.createElement(Button, { className: 'btn_default mobile-full ', text: "\uB4A4\uB85C\uAC00\uAE30", onClick: () => setSendMemberSetting('detail') }),
                React.createElement(Button, { className: isReceiver
                        ? 'btn_line_pink mobile-full text-pink'
                        : 'btn_line_light_tur mobile-full text-tur', option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: isReceiver ? 'circle_delete' : 'circle_plus',
                            size: 14,
                        },
                    }, text: isReceiver ? t('common.delete') : t('common.add'), onClick: isReceiver ? handlerDeleteMember : handlerAddMember }))));
    }, [sendMemberSetting, receiverMemberList, memberData, deleteMemberList]);
    if (isMobile) {
        let content = null;
        switch (sendMemberSetting) {
            case 'add':
            case 'delete':
                content = React.createElement("div", { className: "member-table-wrap" }, renderMobileMembersTable);
                break;
            case 'detail':
                content = (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "select-members-wrap" },
                        React.createElement("div", { className: "select-members" },
                            React.createElement(Icon, { icon: "add_users", width: 35, height: 40 }),
                            React.createElement(Button, { className: "btn_line_light_tur md_round", text: t('sendSMS.addMember'), onClick: handlerAddMemberPage })),
                        React.createElement("div", { className: "select-members" },
                            React.createElement(Icon, { icon: "delete_users", width: 35, height: 40 }),
                            React.createElement(Button, { className: "btn_line_light_tur md_round", text: t('sendSMS.deleteMember'), onClick: handlerDeleteMemberPage }))),
                    renderMembersTableHeader,
                    React.createElement(ChoiceMemberList, { isNotChecked: true, memberList: receiverMemberList, setDeleteMemberList: setDeleteMemberList }),
                    React.createElement(Button, { className: "btn_default mobile-full", text: "\uD655\uC778", onClick: setOpenMemberList ? () => setOpenMemberList(false) : undefined })));
                break;
        }
        return (React.createElement(OpenMemeberListStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop, className: "open-member-list", key: sendMemberSetting },
            React.createElement("div", { className: "open-header" },
                React.createElement("div", { className: "title-name" }, "\uC218\uC2E0\uC790"),
                React.createElement("div", { className: "ic-close", onClick: () => {
                        setOpenMemberList(false);
                        // if (sendMemberSetting !== 'detail') {
                        // 	setSendMemberSetting('detail');
                        // } else {
                        // 	setOpenMemberList(false);
                        // }
                    } },
                    React.createElement(Icon, { icon: "close", size: 24 }))),
            content));
    }
    return (React.createElement(OpenMemeberListStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop, className: `open-member-list not_sticky` },
        renderMembersTableHeader,
        React.createElement("div", { className: "member-wrap" },
            React.createElement(SendMembersTable, { receiverMemberList: receiverMemberList, setMemberData: setMemberData }),
            React.createElement("div", { className: "btn-container" },
                React.createElement(Button, { className: "btn_line_light_tur md_round", text: "\uCD94\uAC00", option: {
                        buttonAfterIcon: {
                            show: true,
                            className: 'arrow-right',
                            name: 'twoarrow',
                            size: isDesktop ? 22 : 16,
                            color: Colors.tur,
                        },
                    }, onClick: handlerAddMember }),
                React.createElement(Button, { className: "btn_line_pink md_round", text: "\uC0AD\uC81C", option: {
                        buttonBeforeIcon: {
                            show: true,
                            className: 'arrow-left',
                            name: 'twoarrow',
                            size: isDesktop ? 22 : 16,
                            color: Colors.pink,
                        },
                    }, onClick: handlerDeleteMember })),
            React.createElement(ChoiceMemberList, { memberList: receiverMemberList, setDeleteMemberList: setDeleteMemberList })),
        React.createElement(Button, { className: "btn_default xmd", text: "\uD655\uC778", styles: {
                buttonWrap: {
                    margin: '0 0 0 auto',
                },
            }, onClick: setOpenMemberList ? () => setOpenMemberList(false) : undefined })));
});
