import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { CheckBoxElement, InputElement } from '../../../../common';
import { getQnaList } from '../../../../server';
import moment from 'moment';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { objectToQueryString } from '../../../../server/functions';
const QnAInfoStyled = styled.div `
	display: flex;
	flex-direction: column;

	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;
	width: 100%;

	.title-div {
		background-color: rgb(255, 255, 255);
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0) !important;
		border-width: 1px;
		padding: 9px;
		flex: 1;
		width: 100%;
		box-sizing: border-box;
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, "Noto Sans KR", sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 22px);
		letter-spacing: var(--b4-letter-spacing, -0.02em);
		font-weight: var(--b4-font-weight, 400);
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	gap: 12px;
`;
const tabletStyles = css `
	gap: 12px;
	padding: 0px 0px 28px 0px;
	border-style: solid;
	border-color: var(--color-grey-c, #e6e8ed);
	border-width: 0px 0px 1px 0px;
`;
const desktopStyles = css `
	gap: 24px;
	padding: 0px 0px 28px 0px;
	border-style: solid;
	border-color: var(--color-grey-c, #e6e8ed);
	border-width: 0px 0px 1px 0px;
`;
export const QnAInfo = React.memo(({ item, isTeacher, handlerTitle, setIsImportance, isImportance, importance, }) => {
    const { t } = useTranslation();
    const titleRef = useRef(null);
    const { isMobile, isTablet } = useGlobalState();
    const [title, setTitle] = useState();
    const handlerChecked = (id, checked) => {
        if (item.private) {
            alert('비공개 문의는 필독으로 설정할 수 없습니다.');
            return;
        }
        getQnaList(objectToQueryString({ importance: true, root_only: true })).then(async (res) => {
            try {
                const { total_count, items } = res.result;
                const count = checked ? total_count + 1 : total_count - 1;
                if (count > 5 && checked) {
                    alert('필독은 5개까지만 선택 가능합니다.');
                    setIsImportance(false);
                    return;
                }
                setIsImportance(checked);
            }
            catch (error) { }
        });
    };
    const renderQnAInfo = () => {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, t('supportRoom.register')),
                React.createElement("div", { className: "fext-field" },
                    React.createElement(InputElement, { className: "showText", type: "text", isDisable: true, value: item?.author_name, style: { backgroundColor: '#ffffff' } }))),
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, t('supportRoom.creation_time')),
                React.createElement("div", { className: "fext-field" },
                    React.createElement(InputElement, { className: "showText", type: "text", isDisable: true, value: moment(item?.creation_time).format('YYYY-MM-DD HH:mm'), style: { backgroundColor: '#ffffff', minWidth: '120px' } }))),
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, t('supportRoom.category')),
                React.createElement("div", { className: "fext-field" },
                    React.createElement(InputElement, { className: "showText", type: "text", isDisable: true, value: t(`supportRoom.${item?.category}`), style: { backgroundColor: '#ffffff' } }))),
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, t('supportRoom.answer_state')),
                React.createElement("div", { className: "fext-field" },
                    React.createElement(InputElement, { className: "showText", type: "text", isDisable: true, value: t(`supportRoom.${item?.status}`), style: { backgroundColor: '#ffffff' } })))));
    };
    useEffect(() => {
        // handlerTitle(title);
        if (titleRef && titleRef.current)
            titleRef.current.value = item?.title;
        setTitle(item?.title);
    }, [item?.title]);
    return (React.createElement(QnAInfoStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
        isMobile ? renderQnAInfo() : React.createElement("div", { className: "field-short" }, renderQnAInfo()),
        React.createElement("div", { className: "field-global-001" },
            !isMobile && React.createElement("div", { className: "task-label" }, t('supportRoom.title')),
            React.createElement("div", { className: "fext-field" },
                React.createElement("div", { className: "title-div" }, title),
                !item.private && isMobile && (React.createElement(CheckBoxElement, { id: "importance", title: "\uD544\uB3C5", checked: isImportance, handlerCheck: handlerChecked, className: "must-read" }))))));
});
