import React, { useEffect, useCallback, useMemo } from 'react';
import { Button, MenuBar } from '../../../../common';
import { useSelector, useDispatch } from 'react-redux';
// @ts-ignore
import Back from '../../../../assest/icon/backhome.png';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { getFilterMenuList, menuItems } from '../../../menu/function';
import { updateMenu } from '../../../menu/action';
import { getHiddenMenus } from '../../classes';
const DSLayoutHeader = React.memo(({ isTeacher, title, isHome, children, isScrolled = false, isHovered = false, setIsHovered, selectedMenu, setMenu, }) => {
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const dispatch = useDispatch();
    const hiddenMenus = useSelector((state) => getHiddenMenus(state));
    useEffect(() => {
        window.addEventListener('DOMContentLoaded', function () {
            var element = document.getElementById('element');
            element.classList.add('animate'); // 페이지 로드시 클래스 추가
        });
    }, []);
    const option = {
        titleIcon: {
            show: isMobile || isTablet ? true : false,
            size: 24,
        },
    };
    const updateNewMenu = (depths, menu, id) => {
        dispatch(updateMenu(depths, menu, id));
    };
    const renderMenuBar = useCallback((depths, list) => {
        return (React.createElement(MenuBar, { className: "lnb-menu", isRow: false, isClickDisabled: false, list: list, value: selectedMenu, onItemClick: (changeMenu) => {
                updateNewMenu(depths, // depths
                {
                    menu: changeMenu === 'learningSupportRoom'
                        ? 'notice'
                        : changeMenu === 'learningManagement' ||
                            changeMenu === 'myRoom'
                            ? 'assignment'
                            : changeMenu === 'liveRoom'
                                ? 'reservation'
                                : changeMenu,
                    detail: 'list', // Matches IMenuDetailProps
                } // menu object
                );
                setIsHovered(false);
            }, setIsHovered: setIsHovered, option: option }));
    }, [selectedMenu, option]);
    const list = useMemo(() => {
        const filteredList = getFilterMenuList(isTeacher, hiddenMenus);
        return filteredList;
    }, [isTeacher, hiddenMenus]);
    const menuSections = [
        {
            depths: isTeacher ? 'learningManagement' : 'myRoom',
            list: (isTeacher ? menuItems.learningManagement : menuItems.myRoomStudent).filter(menu => !hiddenMenus.includes(menu)),
        },
        {
            depths: 'learningSupportRoom',
            list: (isTeacher
                ? menuItems.learningSupportRoom
                : menuItems.learningSupportRoomStudent).filter(menu => !hiddenMenus.includes(menu)),
        },
        {
            depths: 'liveRoom',
            list: (isTeacher ? menuItems.liveRoom : []).filter(menu => !hiddenMenus.includes(menu)),
        },
    ];
    const filteredMenuSections = useMemo(() => {
        return menuSections.filter(section => list.includes(section.depths));
    }, [list, menuSections]);
    return (React.createElement("div", { className: `navbar ${isHome ? (isScrolled || isHovered ? 'home scroll' : 'home') : ''}`, onMouseLeave: setIsHovered ? () => isDesktop && setIsHovered(false) : undefined },
        isHome && (React.createElement(React.Fragment, null,
            React.createElement("img", { className: "back_home", src: Back }),
            React.createElement("div", { id: "element", className: isScrolled || isHovered ? 'overlay-on' : 'overlay-off' }))),
        (isDesktop || isHome) && (React.createElement(Button, { className: `btn-logo ${isHome && !isScrolled && !isHovered ? 'white' : undefined}`, option: {
                buttonBeforeIcon: {
                    show: true,
                    width: 107,
                    height: 37,
                    name: isHome && !isScrolled && !isHovered ? 'logo_white' : 'logo',
                },
            }, onClick: () => setMenu('home') })),
        React.createElement("div", { className: "container", style: { flex: 2 } }, children),
        isDesktop && (React.createElement("div", { className: `lnb ${isHovered ? 'active' : 'hide'}` }, filteredMenuSections.map(({ depths, list }) => (React.createElement("div", { className: "lnb-section", key: depths }, renderMenuBar(depths, list))))))));
});
export default DSLayoutHeader;
