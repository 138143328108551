import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Colors } from '../../../Colors';
import { Button, CheckBoxElement } from '../../../common';
import { FileAttach } from '../../../common/option';
import { useGlobalState } from '../../../GlobalStateProvider';
import { deleteQna, getQnaList, modifyQna, postFile, registQna, useQnaList, } from '../../../server';
import { DetailTitleInfo, MobileTabLayout } from '../../base/layout';
import { DeletePopUpLayout } from '../../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../../base/popup';
import { changeMobileMenuName, goToPrevMenu, updateMenu } from '../../menu/action';
import { RegistContent } from '../DSTSL001/DSTSL001_Regist_Child';
import { QNA_TYPE } from './constant';
import { QnADropDown, QnAInfo } from './DSTS004_Answer_Child';
import { DSTS004AnswerStyled } from './DSTS004_Answer_Child/styled';
import { QnAListTable } from './DTST004_List_Child';
import { objectToQueryString } from '../../../server/functions';
// 답변하기 &
export const DSTS004_Answer = React.memo(({ id, depths = 0, answer_number = 0, isModify = false, isTeacher, isPopUp = false, isReQuestion = false, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isTablet, isMobile, isDesktop, classesId } = useGlobalState();
    const containerRef = useRef(null); //
    const [questionData, setQuestionDate] = useState(); // 질문 데이터
    const [title, setTitle] = useState(''); // 질문 제목
    const [testData, setTestData] = useState([]); //
    const [totalCount, setTotalCount] = useState(0);
    const [item, setItem] = useState();
    const [answer_id, setAnswerId] = useState(''); // 수정시 필요, 답변 ID
    const [answerFiles, setAnswerFiles] = useState([]); // 답변 첨부파일
    const [answerCotent, setAnswerContent] = useState(''); // 답변 내용
    const [isImportance, setIsImportance] = useState(false); // 이 Q&A 가 필독인지 아닌지 여부
    // 모바일에서 질문하기 펼친 경우 (UI)
    const [isQuestionOpen, setIsQuestionOpen] = useState(false);
    // 모바일에서 답변하기 펼친 경우 (UI)
    const [isAnswerOpen, setIsAnswerOpen] = useState(false);
    const fileMutation = useMutation(postFile); // 파일 업로드 Mutation
    const registQnaMutation = useMutation(registQna); // 질문 등록 Mutation
    const modifyQnaMutation = useMutation(modifyQna); // 질문 수정 Mutation
    const deleteQnaMutation = useMutation(deleteQna); // 질문 삭제 Mutation
    // 질문 리스트 조회 => root_id로 조회
    const { data, refetch } = useQnaList({
        root_id: id,
        orderby: ['creation_time'],
        with_content: true,
    });
    // 여러번 호출 방지를 위함
    const refetchList = throttle(() => refetch(), 1000, { leading: true, trailing: false });
    useEffect(() => {
        if (depths > 0) {
            if (isModify) {
                dispatch(changeMobileMenuName(t('menu.requestionModify')));
            }
            else {
                dispatch(changeMobileMenuName(t('menu.requestionAnswer')));
            }
        }
        else {
            if (isModify) {
                dispatch(changeMobileMenuName(t('menu.answerModify')));
            }
            else {
                dispatch(changeMobileMenuName(t('menu.answer')));
            }
        }
    }, [isModify, depths]);
    useEffect(() => {
        if ((answerFiles && answerFiles.length > 0) ||
            (answerCotent && answerCotent.trim() !== '')) {
            APP.eventManager.publish('MENU_MOVE_ACTIVE', false);
        }
        else {
            APP.eventManager.publish('MENU_MOVE_ACTIVE', true);
        }
    }, [answerFiles, answerCotent]);
    // id, answer_number 변경 시 API 재호출
    useEffect(() => {
        refetchList();
    }, [id, answer_number, isModify, isReQuestion]);
    // Q&A 데이터
    useEffect(() => {
        setItem(undefined);
        // 데이터에 변경이 생긴 경우, 답변&파일 초기화
        setAnswerContent('');
        setAnswerFiles([]);
        setAnswerId('');
        if (data) {
            const { total_count, items, found_count } = data;
            if (found_count > 0) {
                setTestData(items);
                setTotalCount(total_count);
                const question_item = isModify
                    ? items[total_count ? total_count - 2 : 0]
                    : items[total_count ? total_count - 1 : 0];
                setQuestionDate(question_item);
                setIsImportance(question_item?.importance);
                const answer_item = isModify ? items[total_count ? total_count - 1 : 1] : '';
                if (total_count > 1 && answer_item) {
                    setItem(answer_item);
                    setAnswerFiles(answer_item.files);
                    setAnswerContent(answer_item.content);
                    setAnswerId(answer_item.id);
                }
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
        setQuestionDate(undefined);
    }, [data]);
    const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
    const deleteQuestion = async () => {
        try {
            APP.eventManager.publish('MENU_MOVE_ACTIVE', true);
            APP.eventManager.publish('SHOW_LOADING', true);
            await deleteQnaMutation.mutateAsync(questionData.id);
            dispatch(updateMenu(undefined, { menu: 'qna', detail: 'list' }));
        }
        catch (error) {
            alert(error);
        }
        finally {
            APP.eventManager.publish('MENU_MOVE_ACTIVE', false);
            APP.eventManager.publish('SHOW_LOADING', false);
            closePopup();
        }
    };
    /**
     * 이전 페이지로 이동
     */
    const previousPage = () => {
        dispatch(goToPrevMenu());
        closePopup();
    };
    //모바일에서 질문보기, 답변하기 버튼 클릭 시 동작
    const handlerOnClick = (type) => {
        if (type === 'question')
            setIsQuestionOpen(!isQuestionOpen);
        else if (type === 'answer')
            setIsAnswerOpen(!isAnswerOpen);
    };
    const handleAnswerOK = async () => {
        try {
            if (answerCotent.replace(/<[^>]*>/g, '').trim().length === 0) {
                alert('Q&A 답변 내용을 입력해주세요.');
                return;
            }
            APP.eventManager.publish('MENU_MOVE_ACTIVE', true);
            APP.eventManager.publish('SHOW_LOADING', true);
            let convertAttachFileList = [];
            // 첨부 파일이 있는 경우
            if (answerFiles && answerFiles.length > 0) {
                try {
                    const alredyFile = answerFiles.filter(file => typeof file === 'string');
                    const filterFile = answerFiles.filter(file => file instanceof File);
                    // 첨부 파일 업로드를 비동기로 실행하고 완료될 때까지 기다립니다.
                    if (filterFile && filterFile.length > 0) {
                        // @ts-ignore
                        const data = await fileMutation.mutateAsync(filterFile);
                        convertAttachFileList = [...data.result.link, ...alredyFile];
                    }
                    else {
                        convertAttachFileList = alredyFile;
                    }
                }
                catch (error) {
                    console.error('Failed to upload files:', error);
                    return; // 에러가 발생하면 함수를 종료합니다.
                }
            }
            if (isModify && answer_id) {
                const data = {
                    attachments: convertAttachFileList,
                    content: answerCotent,
                };
                try {
                    const responseData = await modifyQnaMutation.mutateAsync({
                        id: answer_id,
                        props: data,
                    });
                    if (responseData) {
                        await modifyQnaMutation.mutateAsync({
                            id: answer_id,
                            props: { importance: isImportance },
                        });
                        // dispatch(
                        // 	updateMenu(undefined, { menu: 'qna', detail: 'detail' }, id, {
                        // 		depths,
                        // 		isContent: true,
                        // 		isHistory: false,
                        // 	})
                        // );
                        previousPage();
                    }
                }
                catch (error) {
                    console.error('Failed to post modifyPost:', error);
                    alert(error);
                }
            }
            else {
                const data = {
                    attachments: convertAttachFileList,
                    content: answerCotent,
                    root_id: id,
                    type: QNA_TYPE.ANSWER,
                    class_id: classesId,
                    title,
                    importance: isImportance,
                };
                await registQnaMutation.mutateAsync(data);
                previousPage();
                dispatch(updateMenu(undefined, { menu: 'qna', detail: 'detail' }, id, {
                    depths: depths,
                    isContent: true,
                    isHistory: false,
                }));
            }
        }
        catch (error) {
            alert('삭제된 질문입니다.');
            dispatch(updateMenu(undefined, { menu: 'qna', detail: 'list' }));
        }
        finally {
            APP.eventManager.publish('MENU_MOVE_ACTIVE', false);
            APP.eventManager.publish('SHOW_LOADING', false);
            closePopup();
        }
    };
    //const navigate = useNavigate();
    //삭제, 취소, 확인 버튼 클릭 시 팝업창 생성
    const handlerAnswerPopup = (type) => {
        if (type === 'regist' && answerCotent.replace(/<[^>]*>/g, '').trim().length === 0) {
            alert('Q&A 답변 내용을 입력해주세요.');
            return;
        }
        if (type === 'cancel' &&
            !((answerFiles && answerFiles.length > 0) ||
                answerCotent.replace(/<[^>]*>/g, '').trim().length !== 0)) {
            // APP.eventManager.publish('MENU_MOVE_ACTIVE', true);
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'list',
                id: undefined,
            });
            // APP.eventManager.publish('MENU_MOVE_ACTIVE', false);
            return;
        }
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DeletePopUpLayout,
            componentProps: {
                isQnA: true,
                deleteContent: t(`supportRoom.answerPopUp${type}`),
                onDelete: type === 'delete' && deleteQuestion,
                previosPage: type === 'cancel'
                    ? () => {
                        // APP.eventManager.publish('MENU_MOVE_ACTIVE', true);
                        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                            menuDetail: 'list',
                            id: undefined,
                        });
                        // APP.eventManager.publish('MENU_MOVE_ACTIVE', false);
                    }
                    : undefined,
                onRegist: type === 'regist' && handleAnswerOK,
            },
            width: 639,
            title: t(`supportRoom.${type}AnswerHeader`),
            isFit: true,
        });
    };
    /**
     * 현재
     */
    const renderTitleInfo = useMemo(() => (React.createElement(DetailTitleInfo, { key: "renderTitle", title: depths > 0
            ? isModify
                ? t('menu.requestionModify')
                : t('menu.requestionAnswer')
            : isModify
                ? t('menu.answerModify')
                : t('menu.answer'), previousPage: () => handlerAnswerPopup('cancel') })), [totalCount, isTeacher, isModify, previousPage]);
    /**
     * Q&A 정보 Render
     */
    const renderQnaInfo = useMemo(() => (React.createElement(QnAInfo, { key: "qnaInfo", item: questionData, isTeacher: isTeacher, handlerTitle: setTitle, setIsImportance: setIsImportance, isImportance: isImportance })), [questionData, isTeacher, setTitle, isImportance]);
    /**
     * 질문 내용 Render
     *
     * - 학생이 강사에게 질문한 내용 (표시되는 부분 - 내용, 사진, 첨부파일)
     */
    const renderQuestion = useMemo(() => questionData && (React.createElement("div", { key: "questionContent", className: "wrap_content" },
        React.createElement(RegistContent, { isDisbaled: true, defaultValue: questionData.content, contentName: "\uB0B4\uC6A9", isTeacher: isTeacher }),
        React.createElement(FileAttach, { id: "attach_show_file", className: "default_file", isOnlyFile: true, forceTitle: true, isRegist: false, fileList: questionData.files }))), [questionData?.content, questionData?.files, testData]);
    /**
     * 질문 내용 전체 감싸기
     *
     * 1. 모바일
     * 		- Q&A Dropdown
     * 		- 질문 내용 (renderQuestion)
     * 2. 태블릿 / 데스크탑
     * 		- 질문 내용 (renderQuestion)
     */
    const renderQuestionWrap = useMemo(() => isMobile ? (React.createElement("div", { key: "questionListWrap", style: { gap: '12px', display: 'flex', flexDirection: 'column' } },
        React.createElement(QnADropDown, { buttonTitle: t('supportRoom.showQuestionContent'), icon: "question", isOpen: isQuestionOpen, handlerOnClick: handlerOnClick }),
        isQuestionOpen && renderQuestion)) : (renderQuestion), [questionData, isQuestionOpen]);
    /**
     * 답변 내용 Render
     *
     * 1. 수정
     *
     * 2. 등록
     *
     */
    const renderAnswer = useMemo(() => (React.createElement("div", { key: "answerContent", className: "answer_wrap_content" },
        React.createElement(RegistContent, { contentName: "\uB2F5\uBCC0", defaultValue: item?.content, setValue: setAnswerContent, isAnswer: true, isTeacher: isTeacher, containerRef: containerRef }),
        React.createElement(FileAttach, { id: "attach_file", className: isMobile
                ? 'btn_tur mobile-xxs'
                : isTablet
                    ? 'btn_tur mobile-sm'
                    : 'btn_tur xmd', isRegist: true, fileList: answerFiles, setAttachFileList: setAnswerFiles, isQnA: true }))), [item, answerCotent, answerFiles, testData]);
    /**
     * 답변 내용 전체 감싸기
     * 1. 모바일
     * 		- Q&A 답변하기 Dropdown
     * 		- 질문 내용 (renderAnswer)
     * 2. 태블릿 / 데스크탑
     * 		- 질문 내용 (renderAnswer)
     */
    const renderAnswerWrap = useMemo(() => isMobile ? (React.createElement("div", { key: 'answerListWrap', style: { gap: '12px', display: 'flex', flexDirection: 'column' } },
        React.createElement(QnADropDown, { buttonTitle: "\uB2F5\uBCC0\uD558\uAE30", icon: "answer", isOpen: isAnswerOpen, handlerOnClick: handlerOnClick }),
        isAnswerOpen && renderAnswer)) : (renderAnswer), [isAnswerOpen, renderAnswer]);
    /**
     * (onClick) 질문이력 보기 버튼 클릭 시 동작
     *
     * 강사 / 조교 - DSTS004_Answer.tsx
     * 학생 - DSTS004_Regist.tsx
     */
    const openQuestionHistory = () => {
        dispatch(updateMenu(undefined, { menu: 'qna', detail: 'question_list' }, id, {
            isModify,
            isReQuestion: true,
        }));
    };
    /**
     * Button 컴포넌트
     * 	- 모바일일때 질문이력 보기 버튼 생성
     *
     * show:
     * 	1. 강사가 상세보기 페이지에서 수정 버튼 클릭 시 보임.
     *
     */
    const renderQuestionHistoryBtn = useMemo(() => totalCount >= 2 && (React.createElement(Button, { key: "renderQuestionList", className: "btn_line_tur mobile-xs", text: t('supportRoom.showQuestionList'), option: {
            buttonBeforeIcon: {
                show: true,
                name: 'question',
                size: 16,
                color: Colors.dark_tur,
            },
        }, styles: { buttonWrap: { width: '100%', padding: '8px 12px', gap: '4px' } }, onClick: openQuestionHistory })), [totalCount]);
    /**
     * 질문 이력 보기 (상세 페이지)
     */
    const renderQnAListTable = useMemo(() => (React.createElement(QnAListTable, { key: "table", isTeacher: true, id: id, isPopUp: isPopUp, isRetry: true, isHistory: true, hideDetail: true })), [id, isPopUp, testData]);
    if (!questionData)
        return null;
    if (isPopUp) {
        /**
         * 질문 이력 보기 (상세 페이지)
         */
        return (React.createElement(DSTS004AnswerStyled, { className: "dsts004-popup", "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        component: [renderQnAListTable],
                        styles: { gap: '12px', display: 'flex', flexDirection: 'column' },
                    },
                ], test_id: id, hideHr: true })));
    }
    const handlerChecked = (id, checked) => {
        if (questionData.private) {
            alert('비공개 문의는 필독으로 설정할 수 없습니다.');
            return;
        }
        getQnaList(objectToQueryString({ importance: true, root_only: true })).then(async (res) => {
            try {
                const { total_count, items } = res.result;
                const count = checked ? total_count + 1 : total_count - 1;
                if (count > 5 && checked) {
                    alert('필독은 5개까지만 선택 가능합니다.');
                    setIsImportance(false);
                    return;
                }
                setIsImportance(checked);
            }
            catch (error) { }
        });
    };
    if (isMobile) {
        return (React.createElement(DSTS004AnswerStyled, { className: "dsts004", "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        component: [
                            renderQuestionHistoryBtn,
                            renderQnaInfo,
                            renderQuestionWrap,
                            renderAnswerWrap,
                        ],
                        styles: { gap: '12px', display: 'flex', flexDirection: 'column' },
                    },
                ], showNav: false, showPageButton: true, submitAssignment: handleAnswerOK })));
    }
    return (React.createElement(DSTS004AnswerStyled, { className: "dsts004", "$isTablet": isTablet, "$isMobile": isMobile },
        isDesktop && renderTitleInfo,
        React.createElement("div", { className: "answer-info-wrap", ref: containerRef },
            totalCount > 2 && (React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, t('supportRoom.questionList')),
                renderQnAListTable)),
            renderQnaInfo,
            React.createElement("div", { className: "field-short answer_wrap" },
                renderQuestion,
                renderAnswer),
            React.createElement("div", { className: "btn-container", style: { width: '100%' } },
                !questionData.private && (React.createElement(CheckBoxElement, { id: "importance", title: "\uD544\uB3C5", checked: isImportance, handlerCheck: handlerChecked, className: "must-read" })),
                React.createElement(Button, { className: "btn_line_pink xmd", text: t('common.delete'), onClick: () => handlerAnswerPopup('delete') }),
                React.createElement(Button, { className: "btn_white xmd", text: t('common.cancel'), onClick: () => handlerAnswerPopup('cancel') }),
                React.createElement(Button, { className: "btn_default xmd", text: t('common.okay'), onClick: () => handlerAnswerPopup('regist') })))));
});
